<template>
    <v-row class="ma-0 pa-0" style="position:absolute; z-index: 4; width: 100%">
        <v-col sm="12" class="ma-0 pa-0">
            <transition name="fade">
                <div class="successAlertBox" v-if="success_val">
                    <v-icon class="alertIcon">mdi-check-circle-outline</v-icon>
                    <span class="alertTitle">Updated!</span>
                    <v-icon class="alertIcon" @click="success_val = false;">mdi-close</v-icon>
                </div>
            </transition>
            <transition name="fade">
                <div class="errorAlertBox" v-if="error_val">
                    <v-icon class="alertIcon">mdi-alert-circle-outline</v-icon>
                    <span class="alertTitle">Error!</span>
                    <v-icon class="alertIcon" @click="error_val = false;">mdi-close</v-icon>
                </div>
            </transition>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'SelectionStatus',
        props: [
            'success',
            'error'
        ],
        mounted() {
            this.error_val = this.error;
            this.success_val = this.success;
        },
        watch: {
            success: {
                handler: function(updated_val){
                    this.success_val = updated_val;
                }   
            },
            error: {
                handler: function(updated_val){
                    this.error_val = updated_val;
                }
            }
        },
        data(){
            return {
                error_val: false,
                success_val: false
            }
        }
    }
</script>

<style scoped>
    .successAlertBox{
        background-color: #00c851;
        height: 100%;
    }
    .errorAlertBox{
        background-color: #ff4444;
        height: 100%;
    }
    .alertTitle{
        font-family: 'Poppins' !important;
        font-weight: 400 !important;
        color: white;
        width: 60%;
        float: left;
        text-align: center;
    }
    .alertIcon {
        color: white;
        width: 20%;
        float: left;
    }
</style>