<template>
    <v-container style="width: 100%; height: 100%;" fluid class="pa-0 ma-0">
        <v-row class="ma-0 pa-0" style="width: 100%; height: 100%;">
            <!--
                Audience Management & Editing
            -->
            <v-col cols="4" style="height: 100%; width: 100%;">
                <v-card style="height: 100%; width: 100%;" class="ma-0 pa-3">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="8">
                            <v-text-field class="audience_title" v-model="aud_title" label="Audience Name" :disabled="!audience_title_available"></v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" v-for="title, idx in aud_target_titles" :key="idx">
                            <v-card :elevation="idx == config_idx ? 8 : 2">
                                <v-card-title>
                                    <v-row class="ma-0 pa-0">
                                        <v-col cols="11" class="ma-0 pa-0">
                                            <span class="audience_target_title">{{title}}</span>
                                        </v-col>
                                        <v-col cols="1" class="ma-0 pa-0" v-if="aud_info['audience_state'] == 'designing'">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <v-btn icon @click="set_config(idx)">
                                                            <v-icon color="black">mdi-pencil-outline</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </template>
                                                <TooltipDescription title="Edit Your Target"></TooltipDescription>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="1" class="ma-0 pa-0" v-else>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <v-btn icon @click="set_config(idx)">
                                                            <v-icon color="black">mdi-eye</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </template>
                                                <TooltipDescription title="View Your Target"></TooltipDescription>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon @click="create_new_target" :disabled="!create_new_available">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <TooltipDescription title="Create New Target"></TooltipDescription>
                            </v-tooltip>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-card>
            </v-col>
            <!--
                Target Editing
            -->
            <v-col cols="8" style="height: 100%; width: 100%;">
                <v-card style="height: 550px; width: 100%; overflow-y: scroll;" class="ma-0 pa-3">
                    <v-row class="ma-0 pa-0">
                        <v-row class="ma-0 pa-0" style="max-height: 20%;">
                            <v-col cols="12" style="max-height: 20%;" class="ma-0 pa-0">
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="6">
                                        <v-text-field v-model="configuration_name" @keyup="nameDeB(configuration_name)" class="selectors" color="primary" :disabled="!target_name_available"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row style="max-height: 80%;" class="ma-0 pa-0">
                            <v-col cols="4" class="my-0 py-0">
                                <v-row class="ma-0 pa-0" style=" width: 100%;">
                                    <v-col cols="12">
                                        <span class="selectorSectionTitle">Location</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-0 pa-0" style="width: 100%;">
                                    <v-col cols="12">
                                        <v-card class="ma-0 pa-0" style="max-height: 100%;">
                                            <SelectionStatus :success="location_success" :error="location_error" />
                                            <v-card-title class="ma-2 pa-0 selectorTitle">
                                                <v-icon color=primary class="mr-2" style="max-width:10%">mdi-map-marker</v-icon>
                                                Market
                                            </v-card-title>
                                            <v-row class="ma-2 pa-0">
                                                <v-col class="ma-0 pa-0" sm="12" md="12">
                                                    <v-select class="selectors" v-model="market_size" :items="available_market_sizes" :disabled="!location_available" color=secondary item-color=primary @change="verifyConfigurationChange($event,'location_size')"></v-select>
                                                </v-col>
                                                <v-col class="ma-0 pa-0" sm="12" md="12">
                                                    <v-select class="selectors" v-model="market" :items="available_market_map[market_size]" :disabled="!location_available" color=secondary item-color=primary @change="verifyConfigurationChange($event,'location')"></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-divider vertical color="black" inset></v-divider>
                            <v-col cols="4" class="my-0 py-0">
                                <v-row class="ma-0 pa-0" style=" width: 100%;">
                                    <v-col cols="12">
                                        <span class="selectorSectionTitle">Demographics</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-0 pa-0" style=" width: 100%;">
                                    <v-col cols="12">
                                        <v-card class="ma-0 pa-0" style="max-height: 100%; overflow-y: scroll; overflow-x: hidden;">
                                            <SelectionStatus :success="gender_success" :error="gender_error" />
                                            <v-card-title class="ma-2 pa-0 selectorTitle">
                                                <v-icon color=primary class="mr-2" style="max-width:10%">mdi-gender-male-female</v-icon>
                                                Gender
                                            </v-card-title>
                                            <v-row class="ma-2 pa-0">
                                                <v-col class="ma-0 pa-0" cols="12">
                                                    <v-checkbox class="selectors" v-model="gender" label="Male" value="Male" color=primary :disabled="!availableGenders.includes('Male') || !gender_available" @change="verifyConfigurationChange($event,'gender')"></v-checkbox>
                                                </v-col>
                                                <v-col class="ma-0 pa-0" cols="12">
                                                    <v-checkbox class="selectors" v-model="gender" label="Female" value="Female" color=primary :disabled="!availableGenders.includes('Female') || !gender_available" @change="verifyConfigurationChange($event,'gender')"></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-0 pa-0" style="width: 100%;">
                                    <v-col cols="12" style="max-height: 100%;">
                                        <v-card class="ma-0 pa-0" style="max-height: 100%; overflow-y: scroll; overflow-x: hidden;">
                                            <SelectionStatus :success="agerange_success" :error="agerange_error" />
                                            <v-card-title class="ma-2 pa-0 selectorTitle">
                                                <v-icon color=primary class="mr-2" style="max-width:10%">mdi-pac-man</v-icon>
                                                Age Range
                                            </v-card-title>
                                            <v-row class="ma-2 pa-0">
                                                <!-- <v-col class="ma-0 pa-0" sm="12" md="6">
                                                    <v-checkbox class="selectors" v-model="ageRange" label="5-17" value="5-14" color=primary :disabled="!availableAgeRanges.includes('5-14') || !agerange_available" @change="verifyConfigurationChange($event,'age_range')"></v-checkbox>
                                                </v-col> -->
                                                <v-col class="ma-0 pa-0" sm="12" md="6">
                                                    <v-checkbox class="selectors" v-model="ageRange" label="18-24" value="18-24" color=primary :disabled="!availableAgeRanges.includes('18-24') || !agerange_available" @change="verifyConfigurationChange($event,'age_range')"></v-checkbox>
                                                </v-col>
                                                <v-col class="ma-0 pa-0" sm="12" md="6">
                                                    <v-checkbox class="selectors" v-model="ageRange" label="25-34" value="25-34" color=primary :disabled="!availableAgeRanges.includes('25-34') || !agerange_available" @change="verifyConfigurationChange($event,'age_range')"></v-checkbox>
                                                </v-col>
                                                <v-col class="ma-0 pa-0" sm="12" md="6">
                                                    <v-checkbox class="selectors" v-model="ageRange" label="35-44" value="35-44" color=primary :disabled="!availableAgeRanges.includes('35-44') || !agerange_available" @change="verifyConfigurationChange($event,'age_range')"></v-checkbox>
                                                </v-col>
                                                <v-col class="ma-0 pa-0" sm="12" md="6">
                                                    <v-checkbox class="selectors" v-model="ageRange" label="45-54" value="45-54" color=primary :disabled="!availableAgeRanges.includes('45-54') || !agerange_available" @change="verifyConfigurationChange($event,'age_range')"></v-checkbox>
                                                </v-col>
                                                <v-col class="ma-0 pa-0" sm="12" md="6">
                                                    <v-checkbox class="selectors" v-model="ageRange" label="55+" value="55+" color=primary :disabled="!availableAgeRanges.includes('55+') || !agerange_available" @change="verifyConfigurationChange($event,'age_range')"></v-checkbox>
                                                </v-col>
                                                <!-- <v-col class="ma-0 pa-0" sm="12" md="6">
                                                    <v-checkbox class="selectors" v-model="ageRange" label="65-74" value="65-74" color=primary :disabled="!availableAgeRanges.includes('65-74') || !agerange_available"   @change="verifyConfigurationChange($event,'age_range')"></v-checkbox>
                                                </v-col>
                                                <v-col class="ma-0 pa-0" sm="12" md="6">
                                                    <v-checkbox class="selectors" v-model="ageRange" label="75-84" value="75-84" color=primary :disabled="!availableAgeRanges.includes('75-84') || !agerange_available"   @change="verifyConfigurationChange($event,'age_range')"></v-checkbox>
                                                </v-col> -->
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-divider vertical color="black" inset></v-divider>
                            <v-col cols="4" class="my-0 py-0" style="max-height: 100%;">
                                <v-row class="ma-0 pa-0" style=" width: 100%;">
                                    <v-col cols="12">
                                        <span class="selectorSectionTitle">Behavioral & Pyscographic</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-0 pa-0" style="width: 100%;">
                                    <v-col cols="12">
                                        <v-card class="ma-0 pa-0" style="max-height: 100%;">
                                            <SelectionStatus />
                                            <v-card-title class="ma-2 pa-0 selectorTitle">
                                                <v-icon color=primary class="mr-2" style="max-width:10%">mdi-account-group-outline</v-icon>
                                                Behaviour
                                            </v-card-title>
                                            <v-row class="ma-2 pa-0">
                                                <v-radio-group v-model="audienceType" @change="audienceCat=Object.keys(audienceMap)[0]; audienceDesc = audienceMap[audienceCat][0]; verifyConfigurationChange(audienceCat + '|' + audienceDesc, 'audience')">
                                                    <v-radio class="selectors" label="No Behaviour" value="None" :disabled="!behavior_available"></v-radio>
                                                    <v-radio class="selectors" label="Behavioural Segments" value="Behavior" :disabled="Object.keys(audienceMap).length == 0 || !behavior_available"></v-radio>
                                                    <v-radio class="selectors" label="Household Income" value="HHI" :disabled="hhiList.length == 0 || !behavior_available"></v-radio>
                                                </v-radio-group>
                                                <!-- old behavioral selction -->
                                                <v-select v-if="audienceType == 'Behavior'" class="selectors" v-model="audienceCat" :items="Object.keys(audienceMap)" color=secondary item-color=primary @change="audienceDesc = audienceMap[audienceCat][0]; verifyConfigurationChange($event + '|' + audienceDesc, 'audience')" :disabled="!behavior_available"></v-select>
                                                <v-select v-if="audienceType == 'Behavior'" class="selectors" v-model="audienceDesc" :items="audienceMap[audienceCat]" color="secondary" item-color="primary" @change="verifyConfigurationChange(audienceCat + '|' + $event, 'audience')" :disabled="!behavior_available"></v-select>
                                                <v-select v-if="audienceType == 'HHI'" class="selectors" v-model="audienceHHI" :items="hhiList" color="secondary" item-color="primary" multiple @change="audienceHHISelect($event)" :disabled="!behavior_available"></v-select>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import SelectionStatus from './SelectionStatus.vue'
    import TooltipDescription from '@/components/Utility/TooltipDescription.vue';
    export default {
        name: "AudienceSelectionPage",
        props: [
            'audience_info',
            'audience_configuration'
        ],
        components: {
            SelectionStatus,
            TooltipDescription
        },
        mounted(){
            this.aud_info = this.audience_info;
            this.aud_configuration = this.audience_configuration;
            this.aud_target_titles = [];
            for(let title of this.aud_configuration['targets']){
                this.aud_target_titles.push(title['target_name'])
            }
            this.aud_title = this.aud_info['audience_name'];
            this.set_config(0);
            this.set_configurtion_state(this.aud_info['audience_state']);
            this.set_configurtion_type(this.aud_info['configuration_type']);
        },
        watch: {
            audience_info: {
                handler: function() {
                    this.aud_info = this.audience_info;
                    this.aud_configuration = this.audience_configuration;
                    this.aud_target_titles = [];
                    for(let title of this.aud_configuration['targets']){
                        this.aud_target_titles.push(title['target_name'])
                    }
                    this.aud_title = this.aud_info['audience_name'];
                    this.set_config(0);
                    this.set_configurtion_state(this.aud_info['audience_state']);
                    this.set_configurtion_type(this.aud_info['configuration_type']);
                },
                deep: true
            }
        },
        data() {
            return {
                aud_info: null,
                aud_configuration: null,
                aud_target_titles: [],
                aud_title: '',
                config: null,
                config_idx: 0,
                //Selected configuration variables
                configuration_name: "Configuration Name",
                market: 'Toronto',
                market_size: 'CMA',
                gender: ['Male','Female'],
                ageRange: ['18-24','25-34','35-44','45-54','55+'],
                audienceCat: "Sports & Activity",
                audienceDesc: "Hockey Players",
                audienceHHI: [],
                audienceType: "Behavior",
                //Available configuration variables
                availableGenders: ['Male','Female'],
                availableAgeRanges: ['18-24','25-34','35-44','45-54','55+'],
                audienceMap: {'Sports & Activity': ['Hockey Fans','Golfers','American Football Fans', 'Baseball Fans', 'Fight and Wrestling Fans','Motor Sports Fans', 'Snow Sport Enthusiasts', 'Soccer Enthusiats','Street Sport Enthusiats','tennis and racquet enthusiats'],
                              'Shopping': ['Big Box and Superstores','Consumer Tech Shoppers','Deal and Value Shoppers','Department Store Shoppers','Luxury Shoppers'],
                              'Food': ['Cooking Enthusiats','Fast Foot Junkies','Casual Diners']},
                hhiList: [],
                available_markets: ['Toronto','Ontario'],
                available_market_sizes: ['National','Provincial','CMA'],
                available_market_map: {'National': ['Canada'], 
                                        'Provincial': ['Ontario','Quebec','British Columbia','Alberta','Nova Scotia','New Brunswick','Manitoba','Saskatchewan','Newfoundland and Labrador','Prince Edward Island'], 
                                        'CMA': ['Toronto','Montreal','Vancouver','Calgary','Edmonton']},
                //alert variables
                location_success: false,
                location_error: false, 
                gender_success: false,
                gender_error: false,
                agerange_success: false,
                agerange_error: false,
                audience_success: false,
                audience_error: false,
                //available
                audience_title_available: false,
                target_name_available: false,
                gender_available: false,
                agerange_available: false,
                location_available: false,
                behavior_available: false,
                create_new_available: false
            }
        },
        methods: {
            create_new_target: function(){
                let num_targets = this.aud_configuration['targets'].length;
                let target_name = ("Default " + (num_targets+1))
                this.aud_configuration['targets'].push(
                    {
                        "target_name": target_name,
                        "market_level": "Provincial",
                        "market": "Ontario",
                        "gender": ["Male","Female"],
                        "age_range": ["5-14","15-24","25-34","35-44"],
                        "behavior": []
                    });
                this.aud_target_titles.push(target_name);
                if(num_targets < 2){
                    this.create_new_available = true;
                }
                else{
                    this.create_new_available = false;
                }
            },
            set_config: function(idx){
                this.config_idx = idx;
                this.configuration_name = this.aud_configuration['targets'][idx]['target_name'];
                this.gender = this.aud_configuration['targets'][idx]['gender'];
                this.ageRange = this.aud_configuration['targets'][idx]['age_range'];
                this.market_size = this.aud_configuration['targets'][idx]['market_level'];
                this.market = this.aud_configuration['targets'][idx]['market'];
                if(this.aud_configuration['targets'][idx]['behavior'].length == 0){
                    this.audienceType = "None";
                }
                else{
                    this.audienceType = "Behavior";
                    this.audienceCat = this.aud_configuration['targets'][idx]['behavior'][0].split('|')[0];
                    this.audienceDesc = this.aud_configuration['targets'][idx]['behavior'][0].split('|')[1];
                }
            },
            verifyConfigurationChange: function(value, key){
                if(key == 'location_size'){
                    this.clear_alerts('location');
                    this.available_markets = this.available_market_map[value];
                    this.market = this.available_market_map[value][0];
                    this.location_success = true;
                    setTimeout( () => this.location_success = false, 5000);
                    this.aud_configuration['targets'][this.config_idx]['market_level'] = value;
                }
                else if(key == 'location'){
                    this.clear_alerts('location');
                    this.location_success = true;
                    setTimeout( () => this.location_success = false, 5000);
                    this.aud_configuration['targets'][this.config_idx]['market'] = value;
                }
                else if(key == 'age_range'){
                    this.clear_alerts('age_range');
                    if(this.has_elements(value)){
                        this.agerange_success = true;
                        setTimeout( () => this.agerange_success = false, 5000);
                        this.aud_configuration['targets'][this.config_idx]['age_range'] = value;
                    }
                    else{
                        this.agerange_error = true;
                        setTimeout( () => this.agerange_error = false, 5000);
                        this.ageRange = this.availableAgeRanges;
                    }
                }
                else if(key == 'gender'){
                    this.clear_alerts('gender');
                    if(this.has_elements(value)){
                        this.gender_success = true;
                        setTimeout( () => this.gender_success = false, 5000);
                        this.aud_configuration['targets'][this.config_idx]['gender'] = value;
                    }
                    else{
                        this.gender_error = true;
                        setTimeout( () => this.gender_error = false, 3000)
                        this.gender = this.availableGenders;
                    }
                }
                else if(key == 'audience'){
                    this.clear_alerts('audience');
                    this.audience_success = true;
                    setTimeout( () => this.audience_success = false, 5000);
                    this.aud_configuration['targets'][this.config_idx]['behavior'] = [value];
                }
            },
            clear_alerts: function(type){
                switch(type){
                    case type === 'location':
                        this.location_success = false;
                        this.location_error = false;
                        break;
                    case type === 'gender': 
                        this.gender_success = false;
                        this.gender_error = false;
                        break;
                    case type === 'age_range':
                        this.agerange_success = false;
                        this.agerange_error = false;
                        break;
                    case type === 'audience':
                        this.audience_success = false;
                        this.audience_error = false;
                        break;
                    default:
                        this.gender_success = false;
                        this.gender_error = false;
                        this.agerange_success = false;
                        this.agerange_error = false;
                        break;
                }
            },
            has_elements: function(list){
                if(list instanceof Array && list.length > 0){
                    return true; 
                }
                return false;
            },
            set_configurtion_type: function(type){
                switch(type){
                    case "example":
                        this.audience_title_available = false;
                        this.target_name_available = false;
                        this.gender_available = false;
                        this.agerange_available = false;
                        this.location_available = false;
                        this.behavior_available = false;
                        break;
                    case "demo":
                        this.audience_title_available = false;
                        this.target_name_available = false;
                        break;
                    default: 
                        break;
                }
            },
            set_configurtion_state: function(type){
                switch(type){
                    case "designing":
                        this.audience_title_available = true;
                        this.target_name_available = true;
                        this.gender_available = true;
                        this.agerange_available = true;
                        this.location_available = true;
                        this.behavior_available = true;
                        if(this.aud_configuration['targets'].length >= 3){
                            this.create_new_available = false;
                        }
                        else{
                            this.create_new_available = true;
                        }
                        break;
                    default: 
                        this.audience_title_available = false;
                        this.target_name_available = false;
                        this.gender_available = false;
                        this.agerange_available = false;
                        this.location_available = false;
                        this.behavior_available = false;
                        this.create_new_available = false;
                        break;
                }
            }
        }
    }
</script>

<style scoped>
    .selectorSectionTitle{
        font-family: Poppins !important;
        font-weight: 800 !important;
        font-size: 1em !important;
        text-decoration: underline;
    }
    .selectorTitle{
        font-family: Poppins !important;
        font-weight: 600 !important;
        font-size: 1em !important;
    }
    .selectors{
        font-family: Poppins !important;
        font-weight: 400 !important;
        font-size: 1em !important;
    }
    .audience_title{
        font-family: Poppins !important;
        font-weight: 600 !important;
        font-size: 1em !important;
    }
    .audience_target_title{
        font-family: Poppins !important;
        font-weight: 400 !important;
        font-size: 1em !important;
        max-width: 10px;
        overflow: hidden;
        white-space:nowrap;
        text-overflow: ellipsis;
    }
</style>