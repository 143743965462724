<template>
    <v-row class="ma-0 pa-0">
        <v-col cols="12">
            <GChart
                type="LineChart"
                :options="options"
                :data="data"></GChart>
        </v-col>
    </v-row>
</template>

<script>
    import { GChart } from "vue-google-charts/legacy";
    export default {
        name: 'ResponseCurve',
        props: [
            'statistic_title_name',
            'value',
            'link'
        ],
        components: {
            GChart
        },
        data: () => ({
            statistic_value: '-',
            statistic_name: 'Statistic',
            data: [
                ['Threat','Quantity'],
                [0,0],
                [1,4],
                [2,9],
                [3,14],
                [4,19],
                [5,24],
                [6,27],
                [7,28],
                [8,28.5],
                [9,28.75],
                [10,29]
            ],
            options: {
                "legend": { "position": "right" },
                "chartArea": {"margin":"0 auto", "width":"50%", "height":"100%", "left": 0, "right": 0, "top": 0, "bottom": 0},
                "colors": [
                    "#66B85A", 
                    "#FF6F61",  
                    "#6A5ACD", 
                    "#FFA07A",  
                    "#20B2AA",  
                    "#FFD700",  
                    "#4682B4",  
                    "#FF6347",  
                    "#8A2BE2",  
                    "#FF4500"  
                ],
                "fontName": "Gothic"
            }
        }),
        watch: {
            value: function(){
                if(this.value != null){
                    this.data = this.value;
                }
            }
        },
        mounted(){
            if(this.value != null){
                this.data = this.value;
            }
        },
        methods: {
            open_further_details: function(){
                if(this.link){
                    this.$router.replace(this.link);
                }
            }
        }
    }
</script>

<style scoped>
    .chart_title{
        font-family: "Poppins" !important;
        font-weight: 600;
        font-size: 12pt !important;
        line-height: 40px;

        display: block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>