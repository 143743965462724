import { render, staticRenderFns } from "./volume_vs_efficiency.vue?vue&type=template&id=a608fcf6&scoped=true"
import script from "./volume_vs_efficiency.vue?vue&type=script&lang=js"
export * from "./volume_vs_efficiency.vue?vue&type=script&lang=js"
import style0 from "./volume_vs_efficiency.vue?vue&type=style&index=0&id=a608fcf6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a608fcf6",
  null
  
)

export default component.exports