<template>
    <v-container class="px-6 pt-0 pb-3" style="max-width: 100%;">
      <v-row class="ma-0 pa-0" style="width: 100%; height: 100%" align="center" justify="center" v-if="data_loading">
        <v-col cols="auto">
          <v-progress-circular indeterminate color="primary" size="100"></v-progress-circular>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" v-if="!data_loading" justify="space-around">
        <v-col cols="2" v-for="(opt_overview, idx) in optimization_settings['display']" :key="idx">
          <PredictionPieChart :value="opt_overview_data[opt_overview]['data']" :statistic_title_name="opt_overview_data[opt_overview]['title']"></PredictionPieChart>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col cols="12">
          <!-- <v-row class="ma-0 pa-0">
            <v-spacer></v-spacer>
            
            <v-col cols="auto" class="ma-0 pa-0">
              <v-menu
                v-model="optimization_settings_menu"
                :close-on-content-click="false"
                :nudge-width="-200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="black" v-bind="attrs" v-on="on">mdi-cog-outline</v-icon>
                </template>
                <v-card>
                  <v-card-title>Optimization Settings</v-card-title>
                  <v-card-text class="ma-0 pa-3">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-checkbox class="ma-0 pa-0 optimization_calculator_action" v-model="optimization_settings['display']" label="Base" value="base"></v-checkbox>
                      </v-col>
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-checkbox class="ma-0 pa-0 optimization_calculator_action" v-model="optimization_settings['display']" label="Low" value="low"></v-checkbox>
                      </v-col>
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-checkbox class="ma-0 pa-0 optimization_calculator_action" v-model="optimization_settings['display']" label="Mid" value="mid"></v-checkbox>
                      </v-col>
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-checkbox class="ma-0 pa-0 optimization_calculator_action" v-model="optimization_settings['display']" label="High" value="high"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                   <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="optimization_settings_menu = false"> Cancel </v-btn>
                    <v-btn text @click="optimization_settings_menu = false"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
          </v-row> -->
          <DataLevelTable
              :tableHeader="optimization_header"
              :tableData="opt_data"
              :selectedLevels="['channel']"
              :tableRowClickFunction='empty_table_click'
              :numericColumns="numeric_cols"
              :currencyColumns='[]'
              :percentageColumns="change_cols"
              :short="true"
            />
        </v-col>
      </v-row>
      <!-- <v-row class="ma-0 pa-0" style="width: 100%; height: 100%" v-else>
        <v-col cols="8" style="height: 400px">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" style="max-height: 100px">
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0" cols="auto">
                  <v-btn icon class="toolButton" color="#323232" :disabled="selected_level_val == available_level_vals[0]" @click="updateLevelVal(-1)">
                    <v-icon large>mdi-arrow-left-circle</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="ma-0 pa-0 mx-3" cols="auto">
                  <v-select class="ma-0 pa-0" style="width: 400px" v-model="selected_level_val" :items="available_level_vals">
                    <template v-slot:selection="{item}"> 
                      <span class="graph_select_title">{{item.replaceAll('__', ' - ').replaceAll('_',' ') }}</span>
                    </template>
                    <template v-slot:item="{item}"> 
                      <span class="graph_select_options">{{item.replaceAll('__', ' - ').replaceAll('_',' ') }}</span>
                    </template>
                  </v-select>
                </v-col>
                <v-col class="ma-0 pa-0" cols="auto">
                  <v-btn icon class="toolButton" color="#323232" :disabled="selected_level_val == available_level_vals[available_level_vals.length-1]" @click="updateLevelVal(1)">
                    <v-icon large>mdi-arrow-right-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <GChart
                style="width: 100%; height: 274px;"
                type="LineChart"
                :options="prediction_chart_config"
                :data="data"
              ></GChart>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" style="max-height: 400px; overflow-y: scroll;">
          <v-row class="ma-0 pa-0">
              <v-col cols="6">
                <PredictionStatistic statistic_title_name="Attribution Total" :value="attribution" />
              </v-col>
              <v-col cols="6">
                <PredictionStatistic statistic_title_name="Attribution Change" :value="attribution_change" :change="attribution_change" />
              </v-col>
              <v-col cols="6">
                <PredictionStatistic statistic_title_name="Spend Change" :value="spend_change" :change="spend_change" />
              </v-col>
              <v-col cols="6">
                <PredictionStatistic statistic_title_name="Impressions Change" :value="impressions_change" :change="impressions_change" />
              </v-col>
          </v-row> 
        </v-col>
        <v-col cols="6" style="height: 200px">
          <PredictionComparison statistic_title_name="ROAS Comparsion" :data="roas_compare"></PredictionComparison>
        </v-col>
        <v-col cols="6" style="height: 200px">
          <PredictionComparison statistic_title_name="Contribution Comparsion" :data="contribution_compare"></PredictionComparison>
        </v-col>
      </v-row> -->
    </v-container>
</template>
  
<script>
  // import { GChart } from "vue-google-charts/legacy";
  
  import predictionConfig from "@/configs/Planning/prediction_line_chart.json";
  import PredictionPieChart from "./Charts/PredictionPieChart.vue"

  // import PredictionComparison from "./Charts/PredictionComparison.vue";
  import PredictionExample from "@/test_data/planning/planning_example_a.json";

  import DataLevelTable from "@/components/Utility/DataLevelTable.vue"

  import api_caller from "@/javascript/planning.js";

  export default {
    name: "PlanningPrediction",
    props: [
      'project_plan_id'
    ],
    components: {
      // GChart,
      PredictionPieChart,
      DataLevelTable
      // PredictionComparison
    },
    data: () => ({
      data_loading: true,

      optimization_settings_menu: false,
      optimization_settings: {
        'display': ['base','low','mid','high']
      },

      prediction_chart_config: predictionConfig,

      data_raw: null, 
      available_level_vals: ["Media","OOH","Programmatic","Social","Search"],
      selected_level_val: "Media",
      data: PredictionExample['overview']['Media']['daily'],
      attribution: PredictionExample['overview']['Media']['Attribution'],
      change_last: PredictionExample['overview']['Media']['Change_Last'],
      change_prev: PredictionExample['overview']['Media']['Change_Prev'],

      roas_compare: PredictionExample['roas'],
      contribution_compare: PredictionExample['contribution'],

      headers: [],
      opt_data: [],
      opt_overview_data: {},
      base_pie_data: null,
      low_pie_data: null,
      mid_pie_data: null,
      high_pie_data: null,

    }),
    mounted(){
      this.data_raw = PredictionExample;
      this.buildPrediction();
    },
    watch: {
      project_plan_id: function(){
        this.buildPrediction();
      }
      // selected_level_val: {
      //   immediate: true,
      //   handler: function () {
      //     this.data = this.generate_gchart_format(this.data_raw['overview'][this.selected_level_val]['daily']);
      //     this.roas_compare = this.data_raw['overview'][this.selected_level_val]['spend_impressions_contribution'];
      //     this.attribution = this.data_raw['overview'][this.selected_level_val]['Attribution'];
      //     this.attribution_change = this.data_raw['overview'][this.selected_level_val]['AttributionChange'];
      //     this.spend_change = this.data_raw['overview'][this.selected_level_val]['SpendChange'];
      //     this.impressions_change = this.data_raw['overview'][this.selected_level_val]['ImpressionsChange'];
      //   },
      //   deep: true
      // }
    },
    computed: {
        numeric_cols() {
          return this.optimization_settings['display'];
        },
        change_cols(){
          let change_cols = [];
          for(let display_col of this.optimization_settings['display']){
            change_cols.push((display_col + "_change"));
          }
          return change_cols
        },
        optimization_header: function(){
          let new_header = []
          for(let header of this.headers){
            if((header['value'] === 'channel') || (this.optimization_settings['display'].includes(header['value'])) || (this.optimization_settings['display'].includes(header['value'].replace("_change","")))){
              new_header.push(header)
            }
          }
          return new_header;
        }
    },
    methods: {
      generate_gchart_format: function(data){
          let rows = [];
          let row_tmp = [];
          for(let row of data){
            row_tmp = []
            for(let val of row){
                row_tmp.push({"f": null, "v": val});
            }
            rows.push({"c": row_tmp})
          }
          return {"cols": this.prediction_header, "rows": rows}
      },
      updateLevelVal: function(indexShift){
        let curIdx = this.available_level_vals.indexOf(this.selected_level_val) + indexShift;
        this.selected_level_val = this.available_level_vals[curIdx];
      },
      buildPrediction: async function(){
        this.data_loading = true;
        let token = await this.$auth.getTokenSilently();
        let payload = {
          'project_id': this.$project.get_val(),
          'project_plan_id': this.project_plan_id.toString()
        }
        let prediction_data = await api_caller.get_optimization_data(token, payload).then((resp)=>{
                              if(resp['success']) {
                                return resp['data'];
                              }
                              return false
                          }).catch((e)=>{
                              console.log('error', e);
                              return false;
                          });
        console.log('prediction', prediction_data);
        if(prediction_data){
          this.opt_data = prediction_data['optimization_table'];
          this.opt_overview_data = prediction_data['optimization_overview']
          this.optimization_settings = {
            'display': prediction_data['display']
          }
          this.headers = prediction_data['optimization_header']
          // this.opt_overview_data = {
          //   'base': {
          //     'data': prediction_data['optimization_overview']['base'],
          //     'title': 'Base'
          //   },
          //   'low': {
          //     'data': prediction_data['optimization_overview']['low'],
          //     'title': 'Low Risk'
          //   },
          //   'mid': {
          //     'data': prediction_data['optimization_overview']['mid'],
          //     'title': 'Mid Risk'
          //   },
          //   'high': {
          //     'data': prediction_data['optimization_overview']['high'],
          //     'title': 'High Risk'
          //   },
          // }
          this.data_loading = false;
        }
                        
        // if(prediction_data){
        //   this.opt_data = prediction_data['optimization_data'];
        // }
        // this.data_raw = prediction_data;
        // this.available_level_vals = prediction_data['data_levels'];
        // if(this.available_level_vals && this.available_level_vals.length > 0){
        //   this.selected_level_val = this.available_level_vals[0];
        // }
        // else{
        //   this.selected_level_val = null;
        // }
        // this.data_loading = false;
        // console.log(prediction_data);
      },
      empty_table_click: function(){
        
      }
    }
  };
  </script>
  
  <style>
  .graph_select_title{
    line-height: 1.5em;
    font-family: "Poppins";
    font-weight: 600 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: calc(80%);
    text-align: left;
    overflow: hidden;
  }
  .graph_select_options{
    line-height: 1em;
    font-family: "Poppins";
    font-weight: 400 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: calc(100%);
    text-align: left;
    overflow: hidden;
  }
  .optimization_calculator_action {
    font-family: 'Poppins' !important;
    font-weight: 400 ;
  }
  </style>
  