<template>
    <v-row class="ma-0 pa-0" style="height: 100%">
        <v-col cols="12" class="ma-0 pa-1" style="max-height: 15%;">
            <span class="graph_title">
                {{ statistic_title_name == null ? statistic_name : statistic_title_name }}
            </span>
        </v-col>
        <v-col cols="12" class="ma-0 pa-2" style="height: 85%;">
            <GChart 
                style="height: 100%; width: 100%;"
                type="ColumnChart"
                :options="prediction_compare_chart_config"
                :data="data"
            />
        </v-col>
    </v-row>
</template>

<script>
    import { GChart } from "vue-google-charts/legacy";
    import predictionCompareConfig from "@/configs/Planning/prediction_comparison_chart.json";
    export default {
        name: 'PredictionComparison',
        props: [
            'statistic_title_name',
            'data'
        ],
        components: {
            GChart
        },
        data: () => ({
            statistic_value: '-',
            statistic_name: 'Statistic',
            prediction_compare_chart_config: predictionCompareConfig,
            compare_data: [
                ['Channel', 'Comp', 'Copy', 'Pred'],
                ['Channel A', 10, 20, 15],
                ['Channel B', 10, 20, 15],
                ['Channel C', 10, 20, 15],
                ['Channel D', 10, 20, 15],
            ]
        })
    }
</script>

<style scoped>
    .graph_title{
        line-height: 1.5em;
        font-family: "Poppins";
        font-weight: 600 !important;
    }
</style>