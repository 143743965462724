<template>
    <v-container class="ma-0 pa-3" style="background-color: #1b1b1b; width: 100%; height: 100%; overflow-y: hidden; overflow-x: hidden;" fluid>
        <v-img id="hexa" :src=hex_img contain class="login_bottom_hex" ></v-img>
        <v-img id="hexb" :src=hexb_img contain class="login_bottom_hex" ></v-img>
        <v-img id="hexc" :src=hexc_img contain class="login_bottom_hex"></v-img>
        <v-row class="ma-0 pa-0" style="background-color: #1b1b1b; width: 100%; height: 100%;">
            <v-spacer></v-spacer>
            <v-col cols="auto" align-self="center">
                <v-card outlined class="pa-0 ma-0 login_card">
                   <v-alert class="loginFormAlerts" elevation="12" :value="signup_success" type="success" transition="scale-transition">
                        Thank You {{first_name}} {{last_name}}. You have successfully signed up for Docma's TalkTrend.
                    </v-alert>
                    <v-alert class="loginFormAlerts" elevation="12" :value="signup_error" type="warning" transition="scale-transition">
                        Oops, there was an error. Please Contact the Docma Team.
                    </v-alert>
                    <v-form class="ma-0 pa-4" v-if="submit_available" ref="form" v-model="valid_form" lazy-validation>
                        <v-row class="ma-0 pa-0 mb-4">
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-img :src=main_logo_inverterd contain max-width="150px"></v-img>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row class="ma-0 pa-3">
                            <v-col cols="12" class="ma-0 pa-1">
                                <span class="signUpExplination">Welcome to Docma! To Sign Up for access to TalkTrend please fill out the form below.</span>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-1">
                                <span class="signUpExplination">You will recieve an email when you have been granted access to the tool.</span>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-1">
                                <span class="signUpExplination">For more information or questions please reach out to info@docma.ca</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="first_name"
                                    outlined
                                    label="First Name"
                                    class="ma-0 pa-0 loginFormTextStyle"
                                    color="primary"
                                    :rules="[rules.required, rules.name]"
                                    ref="first_name"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="last_name"
                                    outlined :error="last_name_err"
                                    type="text"
                                    label="Last Name"
                                    class="ma-0 pa-0 loginFormTextStyle"
                                    color="primary"
                                    :rules="[rules.required, rules.name]"
                                    ref="last_name"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="title" outlined
                                    type="text"
                                    label="Title"
                                    class="ma-0 pa-0 loginFormTextStyle"
                                    color="primary"
                                    :rules="[rules.required]"
                                    ref="title"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="organization" 
                                    outlined type="text"
                                    label="Organization"
                                    class="ma-0 pa-0 loginFormTextStyle"
                                    color="primary"
                                    :rules="[rules.required]"
                                    ref="organization"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field 
                                    v-model="email"
                                    outlined
                                    label="Email"
                                    class="ma-0 pa-0 loginFormTextStyle"
                                    color="primary"
                                    :rules="[rules.required, rules.email]"
                                    ref="email"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="ma-0 pa-0">
                                    <v-spacer></v-spacer>
                                    <v-col cols="auto" class="ma-0 pa-0">
                                        <v-btn outlined x-large class="ma-4 loginFormTextStyle" color="primary" @click="submit_signup" :disabled="!submit_available">Sign Up</v-btn>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row v-if="!submit_available" class="ma-0 pa-0">
                        <v-col cols="12">
                            <v-row class="ma-0 pa-0 mb-4">
                                <v-spacer></v-spacer>
                                <v-col cols="auto">
                                    <v-img :src=main_logo_inverterd contain max-width="150px"></v-img>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                        </v-col>
                        <v-col cols="12" style="text-align:center">
                            <span class="signUpExplination">Thank You for Signing Up for the Talk Trend Demo!</span>
                        </v-col>
                        <v-col cols="12" style="text-align:center">
                            <span class="signUpExplination">We will reach out to you shortly with details for your login.</span>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-container>
</template>

<script>
import {getFirestore, collection, addDoc} from 'firebase/firestore'
import firebase_app from '@/plugins/firebase.js'
import docma_logo from "@/assets/docma-logo-dark.svg";
import hex from "@/assets/hex3.svg";
import hexb from "@/assets/hex3b.svg";
import hexc from "@/assets/hex2.svg";

export default {
    name: 'SignUpPage',
    mounted(){
        document.getElementById('hexa').style.top= ((Math.random() * 90) + 5) + 'vh';
        document.getElementById('hexa').style.left= ((Math.random() * 90) + 5) + 'vw';

        document.getElementById('hexb').style.top= ((Math.random() * 90) + 5) + 'vh';
        document.getElementById('hexb').style.left= ((Math.random() * 90) + 5) + 'vw';

        document.getElementById('hexc').style.top= ((Math.random() * 90) + 5) + 'vh';
        document.getElementById('hexc').style.left= ((Math.random() * 90) + 5) + 'vw';
    },
    data() {
        return {
            token_loading: false,
            main_logo_inverterd: docma_logo,
            hex_img: hex,
            hexb_img: hexb,
            hexc_img: hexc,

            signup_success: false,
            signup_error: false,

            submit_available: true,
            valid_form: true,
            
            first_name: "",
            last_name: "",
            email: "",
            title: "",
            organization: "",
            
            // Error states
            first_name_err: false,
            last_name_err: false,
            email_err: false,
            job_title_err: false,
            org_err: false,
            rules: {
                required: value => !!value || 'Required.',
                name: value => {
                    const pattern =  /^[a-zA-Z]+$/;
                    return pattern.test(value) || 'Invalid name.'
                },
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            }
        }
    },
    methods: {
        submit_signup: async function() {
            if(this.validate_form()){
                this.rating_success = true;
                setTimeout( () => this.rating_success = false, 2000)
                this.submit_available = false;
                this.cancel_available = false;
                setTimeout( () => this.close_feedback_card(), 2000)
                let db = getFirestore(firebase_app);
                // let users_info = doc(db, "docma_user_settings",user.uid);

                let created = new Date();
                created = created.toLocaleString('en-US',{
                    day:    'numeric',
                    month:  'numeric',
                    year:   'numeric',
                    hour:   'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                });

                await addDoc(collection(db, "user_registration"),{
                    "first_name": this.first_name,
                    "last_name": this.last_name,
                    "title": this.title,
                    "organization": this.organization,
                    "email": this.email,
                    "date": created
                }).then(()=> {
                    this.signup_success = true;
                    setTimeout( () => this.signup_success = false, 5000)
                    this.submit_available = false;
                    this.first_name = "";
                    this.last_name = "";
                    this.title = "";
                    this.organization = "";
                    this.email = "";
                }).catch(()=>{
                    this.signup_error = true;
                    setTimeout( () => this.signup_error = false, 5000)
                })
            }
        },
        validate_form() {
            return this.$refs.form.validate()
        }
    }
}
</script>

<style scoped>
    .signUpExplination{
        font-family: 'Poppins' !important;
        font-weight: 400;
    }
    .loginFormTextStyle{
        font-family: 'Poppins' !important;
        font-weight: 400;
    }
    .loginFormAlerts{
        font-family: 'Poppins' !important;
        position: absolute; 
        width: 100%;
        z-index: 4;
    }
    .login_card {
        min-width: 300px; 
        max-width: 40vw; 
        z-index: 3;
    }
    .login_bottom_hex {
        position: absolute;
        /* transform: translateY(-30%) translateX(30%) rotate(240deg); */
        z-index: 0;
        max-width: 150px;
    }
</style>