<template>
    <v-card style="height: 100%; max-height: 100%; overflow-y: scroll;" class="ma-0 pa-0" flat>
      <v-row class="ma-0 pa-0" style="width: 100%;">
        <v-col cols="auto">
          <span class="report-subtitle">Spend, Impressions & Contribution</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" class="ma-0 pa-0" style="height: 10px;">
          <v-divider class="mx-12"></v-divider>
        </v-col>
        <v-col cols="12" class="px-12" v-if="!graph_visible">
            <v-progress-linear
              size="100"
              indeterminate
              color="primary"
            ></v-progress-linear>
        </v-col>
        <v-col v-if="graph_visible" :cols="spend_impr_contribution_col_size" class="ma-0 pa-0" style="height: calc(100% - 10px);">
            <v-row class="ma-0 pa-0" style="height: 90%">
              <v-col cols="4" class="chartAlign">
                <!-- <v-col cols="12">
                    <span class="graph-title">Spend</span>
                </v-col> -->
                  <v-col cols="12">
                    <div class="centerLabel">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12" class="centerLabelDataStat pb-1">
                          <span>{{spend_total}}</span>
                        </v-col>
                        <v-col cols="12" class="centerLabelDataTitle pt-1">
                          <span>Spend</span>
                        </v-col>
                      </v-row>
                      <!-- <span class="centerLabelDataStat">{{spend_total}}</span> <br /> <span class="centerLabelDataTitle">Spend</span> -->
                    </div>
                    <div
                      :style="{
                        height: screenHeight + 'px'
                      }"
                    >
                      <GChart
                      type="PieChart"
                      :data="pie_chart_data['spend']"
                      :options="pie_chart_config"
                      :resizeDebounce="50"
                      class="performanceChart"
                      />
                    </div>
                  </v-col>
              </v-col>
              <v-col cols="4" class="chartAlign">
                <v-col cols="12">
                  <div class="centerLabelB">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" class="centerLabelDataStat pb-1">
                          <span>{{impressions_total}}</span>
                        </v-col>
                        <v-col cols="12" class="centerLabelDataTitle pt-1">
                          <span>Impressions</span>
                        </v-col>
                      </v-row>
                    <!-- <span class="centerLabelDataStat">{{impressions_total}}</span> <br /> <span class="centerLabelDataTitle">Impressions</span> -->
                  </div>
                  <div
                      :style="{
                        height: screenHeight + 'px'
                      }"
                    >
                      <GChart
                      type="PieChart"
                      :data="pie_chart_data['impressions']"
                      :options="pie_chart_config"
                      :resizeDebounce="50"
                      class="performanceChart"
                      />
                    </div>
                </v-col>
              </v-col>
              <v-col cols="4" class=" chartAlign">
                <v-col cols="12">
                  <div class="centerLabelC">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12" class="centerLabelDataStat pb-1">
                        <span>{{sales_attribution_total}}</span>
                      </v-col>
                      <v-col cols="12" class="centerLabelDataTitle pt-1">
                        <span>Attribution</span>
                      </v-col>
                    </v-row>
                    <!-- <span class="centerLabelDataStat">{{sales_attribution_total}}</span> <br /> <span class="centerLabelDataTitle">Attribution</span> -->
                  </div>
                  <div
                    :style="{
                      height: screenHeight + 'px'
                    }"
                  >
                    <GChart
                    type="PieChart"
                    :data="pie_chart_data['sales_attribution']"
                    :options="pie_chart_config"
                    :resizeDebounce="50"
                    class="performanceChart"
                    />
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
      </v-row>
          <v-spacer></v-spacer>
          <v-col cols="4" v-if="spend_impr_contribution_overview_comments">
            <CommentSection v-on:close_comments="close_comments" :comments_persistent="comments_persistent"></CommentSection>
          </v-col>
    </v-card>
  </template>
  
  <script>
  import { GChart } from "vue-google-charts/legacy";
  
  // Configs
  import pieChartConfig from "@/configs/Performance/performance_pie_chart_config.json";
  import cpalette from "@/configs/ChartColors/get_color_palette.js";
  import CommentSection from "./comment_section.vue"
  
  export default {
    name: "SpendImpressionsContribution",
    props: [
      'data_version',
      'dataset',
      'comments_persistent',
      'color_palette'
    ],
    components: {
      GChart,
      CommentSection
    },
    methods: {
      reload_graph: function () {
        this.graph_visible = false;
        setTimeout(() => (this.graph_visible = true), 10);
      },
      open_comments: function(){
        this.spend_impr_contribution_overview_comments = true; 
        this.spend_impr_contribution_col_size = 8;
        this.reload_graph();
      },
      close_comments: function(){
        this.spend_impr_contribution_overview_comments = false; 
        this.spend_impr_contribution_col_size = 12;
        this.reload_graph();
      }
    },
  
    data: () => ({
      graph_visible: false,
  
      pie_chart_config: pieChartConfig,
      pie_chart_data: null,

      spend_total: 0,
      impressions_total: 0,
      sales_attribution_total: 0,

      spend_impr_contribution_overview_comments: false,
      spend_impr_contribution_col_size: 12,
      screenHeight: window.innerHeight * (window.innerHeight > 1000 ? 0.5 : 0.4),
    }),
    mounted() {
      let colors = cpalette.get_color_palette(this.color_palette);
      this.pie_chart_config['colors'] = colors;
      this.pie_chart_data = this.dataset;
      if(this.dataset != null){
        console.log(this.pie_chart_data)
        this.spend_total = this.pie_chart_data['totals']['spend'].toLocaleString("en-CA", {
                                                                style: "currency",
                                                                currency: "CAD",
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0,
                                                                notation: 'compact'
                                                              });
        this.impressions_total = this.pie_chart_data['totals']['impressions'].toLocaleString("en-CA", {
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0,
                                                                notation: 'compact'
                                                              });
        this.sales_attribution_total = this.pie_chart_data['totals']['sales_attribution'].toLocaleString("en-CA", {
                                                                style: "currency",
                                                                currency: "CAD",
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0,
                                                                notation: 'compact'
                                                              });
        this.reload_graph();
      }
    },
    watch: {
      dataset: {
        immediate: false,
        handler: function () {
          this.pie_chart_data = this.dataset;
          if(this.dataset != null){
            console.log(this.pie_chart_data)
            this.spend_total = this.pie_chart_data['totals']['spend'].toLocaleString("en-CA", {
                                                                    style: "currency",
                                                                    currency: "CAD",
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 0,
                                                                    notation: 'compact'
                                                                  });
            this.impressions_total = this.pie_chart_data['totals']['impressions'].toLocaleString("en-CA", {
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 0,
                                                                    notation: 'compact'
                                                                  });
            this.sales_attribution_total = this.pie_chart_data['totals']['sales_attribution'].toLocaleString("en-CA", {
                                                                    style: "currency",
                                                                    currency: "CAD",
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 0,
                                                                    notation: 'compact'
                                                                  });
            this.reload_graph();
          }
        },
        deep: true,
      }
    },
    computed: {},
  };
  </script>
  
  <style scoped>
  .main-page-title {
    /* line-height: 5em; */
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1.5em;
  }
  .report-subtitle {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 1.5em;
  }
  
  .graph-title {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 0.9em;
  }
  
  .report-version {
    font-family: "Poppins";
    font-weight: 600;
    align-items: center;
    /* font-size: 0.5em; */
    /* width: 50px; */
  }
  
  .performanceChart {
    height: 100%;
    width: 100%;
  }
  
  .chartHeader {
    .chartHeaderText {
      font-family: "Poppins" !important;
      font-weight: 600;
      font-size: 1.5em;
    }
  
    display: flex;
  }
  
  .toolBar {
    float: right;
  }
  
  .toolButton {
    padding: 0 32px;
    width: 50px;
    color: black;
  }
  
  ::v-deep .v-overlay__content {
    position: absolute;
    right: 0;
    height: 100%;
    width: 30%;
  }
  
  .overlayField {
    padding: 0 16px;
  }
  
  .overlayButton {
    float: right;
  }
  
  .chartWithOverlay {
    /* position: relative; */
    width: 100%;
    /* height: 500px; */
  }
  .miniChartWithOverlay {
    /* position: relative; */
    width: 100%;
    height: 75px;
  }
  
  .metricToggleOverlay {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 12%;
    left: 90px;
  }
  
  .metricToggleRow {
    margin-left: 0;
    margin-right: auto;
  }
  .metric_toolbar_title {
    font-weight: 800 !important;
  }
  .metric_toolbar_subtitle {
    font-weight: 600 !important;
  }
  .metric_toolbar_metric_title {
    line-height: 1em;
    font-weight: 200 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: calc(80%);
    text-align: left;
    overflow: hidden;
  }
  
  .tooltip_description {
    font-family: "Poppins" !important;
    font-weight: 200;
  }
  .long_description {
    font-family: "Poppins" !important;
  }
  
  .expansionPanel {
    overflow: none;
  }
  
  .reportCommentContent {
    max-height: 350px;
    overflow-y: auto;
  }
  
  .reportCommentInputField {
    float: right;
    width: 90%;
  }

  .chartAlign {
    margin: auto 0
  }
  .centerLabel{
      position: absolute;
      left: 16.67%;
      top: 42%;
      z-index: 10;
      width: 256px;
      height: 82px;
      text-align: center;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 36px;
      transform: translateX(-50%);
      color: black;
  }
  .centerLabelDataTitle{
    font-family: 'Poppins';
    font-weight: 200;
    font-size: 14pt;
  }
  .centerLabelDataStat{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 30pt;
  }
  .centerLabelB{
      position: absolute;
      left: 50%;
      top: 42%;
      z-index: 10;
      width: 256px;
      text-align: center;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 36px;
      transform: translateX(-50%);
      color: black;
  }
  .centerLabelC{
      position: absolute;
      left: 83.33%;
      top: 42%;
      z-index: 10;
      width: 256px;
      text-align: center;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 36px;
      transform: translateX(-50%);
      color: black;
  }
  </style>
  