<template>
    <v-container class="ma-0 pa-3" style="background-color: #1b1b1b; width: 100%; height: 100%;" fluid>
        <v-row class="ma-0 pa-0" style="background-color: #1b1b1b; width: 100%; height: 100%;">
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <v-progress-circular
                    size="100"
                    indeterminate
                    color="white"></v-progress-circular>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-container>
</template>

<script>
import docma_logo from "@/assets/docma-logo-dark.svg";
import hex from "@/assets/footer-hex.svg";
// import {
//   getAuth,
//   signInWithEmailAndPassword,
//   sendPasswordResetEmail,
// } from "firebase/auth";

export default {
  name: "FullPageLoad",
    data() {
        return {
          // email: "",
          // password: "",
            token_loading: false,
            main_logo_inverterd: docma_logo,
            hex_img: hex,

            showpass: false,

            // login_error: false,
            // login_success: false,
            // password_reset_success: false,
            // password_reset_input_error: false,
            // password_reset_error: false,
    };
  },
  mounted() {
  }
};
</script>

<style scoped>
.loginFormTextStyle {
  font-family: "Poppins" !important;
  width: 90%;
    }
    .loginFormAlerts{
        font-family: 'Poppins' !important;
        position: absolute; 
        width: 100%;
        z-index: 4;
    }
    .login_card {
        min-width: 300px; 
        max-width: 30vw; 
        z-index: 3;
    }
    .login_top_hex {
        transform: translateY(30%) translateX(-20%);
        z-index: 0;
        max-width: 150px;
    }
    .login_bottom_hex {
        transform: translateY(-30%) translateX(30%) rotate(240deg);
        z-index: 0;
        max-width: 150px;
    }
</style>