<template>
    <v-card class="ma-0 pa-0">
        <v-alert class="feedbackFormAlerts" elevation="12" :value="rating_success" type="success" transition="scale-transition">
            Thank You for Proving Us with Your Feedback!
        </v-alert>
        <v-alert class="feedbackFormAlerts" elevation="12" :value="rating_error" type="error" transition="scale-transition">
            Oops, there was an error. Please Contact the Docma Team.
        </v-alert>
        <v-row class="ma-3 pa-0">
            <v-spacer></v-spacer>
            <v-col cols="auto" class="ma-0 pa-0">
            <v-btn fab @click="close_feedback_card">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-col>
        </v-row>
        <v-card-title class="ma-3 pa-0 mb-8 docma-text-rating-main-title" style="text-decoration: underline;">
            Provide Us Feedback
        </v-card-title>
        <v-row class="ma-3 pa-0 mb-8">
            <v-col cols="12" class="ma-0 pa-0">
            <span class="docma-text-rating-title">Please Rate Your Experience with Docma</span>
            </v-col>
            <v-row class="ma-0 pa-0">
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <v-rating
                dark
                background-color="#ffffff"
                color="#f3a815"
                v-model="rating"
                empty-icon="$ratingFull"
                hover
                x-large
                class="docma-text-rating-text"
                ></v-rating>
            </v-col>
            <v-spacer></v-spacer>
            </v-row>
        </v-row>
        <v-row class="ma-3 pa-0 mb-8">
            <v-col cols="12" class="ma-0 pa-0">
            <span class="docma-text-rating-title">Do you have any thoughts you would like to share?</span>
            </v-col>
            <v-col cols="12">
            <v-textarea v-model="rating_comments" label="Comments" class="docma-text-rating-text ma-0 pa-0"></v-textarea>
            </v-col>
        </v-row>
        <v-row class="ma-3 pa-0 mb-6">
            <v-col cols="12" class="ma-0 pa-0">
            <span class="docma-text-rating-title">May we follow up on your feedback?</span>
            </v-col>
            <v-col cols="12">
            <v-radio-group v-model="rating_follow_up" row dark class="docma-text-rating-text ma-0 pa-0">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
            </v-radio-group>
            </v-col>
        </v-row>
        <v-card-actions>
            <v-row class="ma-3 pa-0">
            <v-col cols="6">
                <v-btn class="docma-tool-navigation-bar" block :disabled="!cancel_available" @click="close_feedback_card">
                Cancel
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn class="docma-tool-navigation-bar" block :disabled="!submit_available" @click="submit_feedback">
                Submit
                </v-btn>
            </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import {getFirestore, collection, addDoc} from 'firebase/firestore'
import firebase_app from '@/plugins/firebase.js'
import {getAuth} from 'firebase/auth';
export default {
  name: 'FeedbackCard',
  mounted(){

  },
  data: () => ({
    main_logo: './assets/docma-logo-dark.svg',
    main_logo_inverterd: './assets/docma-logo-reversed.svg',

    rating: 5,
    rating_comments: "",
    rating_follow_up: true,

    rating_success: false,
    rating_error: false,

    submit_available: true,
    cancel_available: true
  }),
  methods: {
    submit_feedback: async function() {
        // this.rating_success = true;
        // setTimeout( () => this.rating_success = false, 2000)
        // this.submit_available = false;
        // this.cancel_available = false;
        // setTimeout( () => this.close_feedback_card(), 2000)
        const auth = getAuth();
        let user = auth.currentUser;
        let db = getFirestore(firebase_app);
        // let users_info = doc(db, "docma_user_settings",user.uid);

        let created = new Date();
        created = created.toLocaleString('en-US',{
            day:    'numeric',
            month:  'numeric',
            year:   'numeric',
            hour:   'numeric',
            minute: 'numeric',
            second: 'numeric'
        });

        await addDoc(collection(db, "user_feedback"),{
            "rating": this.rating,
            "comment": this.rating_comments,
            "can_contact": this.rating_follow_up,
            "user_id": user.uid,
            "date": created
        }).then(()=> {
            this.rating_success = true;
            setTimeout( () => this.rating_success = false, 2000)
            this.submit_available = false;
            this.cancel_available = false;
            setTimeout( () => this.close_feedback_card(), 2000)
        }).catch(()=>{
            this.rating_error = true;
            setTimeout( () => this.rating_error = false, 2000)
        })
    },
    close_feedback_card: function(){
        this.$emit('close');
    }
  }
}
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Poppins:200,400,600,800');
  @import url('https://fonts.googleapis.com/css?family=Amiko:400,600,700');
  .docma-text-rating-main-title{
    font-family: 'Poppins' !important;
    font-weight: 600;
    font-size: 1.2em;
  }
  .docma-text-rating-title{
    font-family: 'Poppins' !important;
    font-weight: 600;
    font-size: 0.9em;
  }
  .docma-text-rating-text{
    font-family: 'Poppins' !important;
    font-weight: 200;
    font-size: 1.1em;
  }
  .feedbackFormAlerts{
      font-family: 'Poppins' !important;
      position: absolute; 
      width: 100%;
      z-index: 4;
  }
</style>