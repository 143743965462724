<template>
    <v-card class="ma-3 pa-0" style="max-height:80%; overflow-y: scroll">
        <v-row class="ma-0 pa-0" style="width: 100%">
            <v-col cols="12" class="ma-0 pa-1 pr-3">
                <v-row class="ma-0 pa-0">
                    <v-spacer></v-spacer>
                    <v-col cols="1" class="ma-0 pa-0">
                        <v-btn
                            icon
                            style="height: 20px; width: 20px"
                            :disabled="comments_persistent"
                            @click="close"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>    
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
                <v-list three-line class="reportCommentContent">
                    <template
                    v-for="entry in report_comments.spend_impr_contribution_overview"
                    >
                    <v-subheader
                        v-if="entry.header"
                        :key="entry.header"
                        v-text="entry.header"
                    ></v-subheader>

                    <v-list-item v-else :key="entry.name">
                        <v-list-item-avatar color="black" size="36">
                        <span class="white--text text-h6">{{
                            getUserInitial(entry.name)
                        }}</span>
                        </v-list-item-avatar>

                        <v-list-item-content>
                        <v-list-item-title v-html="entry.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="entry.content"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    </template>
                </v-list>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    class="reportCommentInputField"
                    v-model="report_comment"
                    label="Add comment"
                    placeholder="Add comment"
                    @keyup.enter="onCommentSubmit('spend_impr_contribution_overview')"
                ></v-text-field>
            </v-col>
        </v-row>
    </v-card>
</template>
  
<script>
  
  // import {
  //   format_data_to_gchart,
  //   filter_dataset,
  //   calculate_moving_average,
  //   calculate_moving_average_cross,
  //   calculate_bollinger_band,
  // } from "@/components/Performance/PerformanceStats/statistics.js";
  
  // let screenHeight = window.innerHeight
  // chartConfig["mainChartOptions"]["height"] =  screenHeight * 0.50
  // chartConfig["miniChartOptions"]["height"] =  screenHeight * 0.30
  
  export default {
    name: "CommentSection",
    props: [
      'comments_persistent'
    ],
    components: {
    },
    methods: {
      close: function(){
        this.$emit('close_comments');
      },
  
      onCommentSubmit: function (section) {
        if (this.report_comments[section]) {
          let newCommentContent = null;

          newCommentContent = this.report_comment;
  
          if (newCommentContent) {
            let newComment = {
              name: this.$user_data["first_name"] + " " + this.$user_data["last_name"],
              content: newCommentContent,
              timestamp: new Date().toLocaleString()
            };
            this.report_comments[section].push(newComment);
          }

          this.report_comment = null;
        }
      },
  
      getUserInitial: function (name) {
        let initial = "";
        if (name) {
          let splitted = name.split(" ");
          initial = splitted[0].substring(0, 1).toUpperCase();
  
          if (splitted.length > 1) {
            initial += splitted[splitted.length - 1].substring(0, 1).toUpperCase();
          }
        }
        return initial;
      },
    },
  
    data: () => ({
      report_comment: "",
      report_comments: {
        spend_impr_contribution_overview: [
          {
            name: "Taylor Grant",
            content: "Comment 1",
            timestamp: "01/01/2024 00:00:00 AM",
          },
          {
            name: "Sam Rowe",
            content: "Comment 2",
            timestamp: "02/01/2024 00:00:00 AM",
          },
          {
            name: "Katarina Sacka",
            content: "Comment 3",
            timestamp: "03/01/2024 00:00:00 AM",
          },
        ]
      }
    
    }),
    mounted() {},
    watch: {},
    computed: {},
  };
  </script>
  
  <style scoped>
  .main-page-title {
    /* line-height: 5em; */
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1.5em;
  }
  .report-subtitle {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 1.5em;
  }
  
  .graph-title {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 0.9em;
  }
  
  .report-version {
    font-family: "Poppins";
    font-weight: 600;
    align-items: center;
    /* font-size: 0.5em; */
    /* width: 50px; */
  }
  
  .performanceChart {
    height: 100%;
    width: 100%;
  }
  
  .chartHeader {
    .chartHeaderText {
      font-family: "Poppins" !important;
      font-weight: 600;
      font-size: 1.5em;
    }
  
    display: flex;
  }
  
  .toolBar {
    float: right;
  }
  
  .toolButton {
    padding: 0 32px;
    width: 50px;
    color: black;
  }
  
  ::v-deep .v-overlay__content {
    position: absolute;
    right: 0;
    height: 100%;
    width: 30%;
  }
  
  .overlayField {
    padding: 0 16px;
  }
  
  .overlayButton {
    float: right;
  }
  
  .chartWithOverlay {
    /* position: relative; */
    width: 100%;
    /* height: 500px; */
  }
  .miniChartWithOverlay {
    /* position: relative; */
    width: 100%;
    height: 75px;
  }
  
  .metricToggleOverlay {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 12%;
    left: 90px;
  }
  
  .metricToggleRow {
    margin-left: 0;
    margin-right: auto;
  }
  .metric_toolbar_title {
    font-weight: 800 !important;
  }
  .metric_toolbar_subtitle {
    font-weight: 600 !important;
  }
  .metric_toolbar_metric_title {
    line-height: 1em;
    font-weight: 200 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: calc(80%);
    text-align: left;
    overflow: hidden;
  }
  
  .tooltip_description {
    font-family: "Poppins" !important;
    font-weight: 200;
  }
  .long_description {
    font-family: "Poppins" !important;
  }
  
  .expansionPanel {
    overflow: none;
  }
  
  .reportCommentContent {
    max-height: 350px;
    overflow-y: auto;
  }
  
  .reportCommentInputField {
    float: right;
    width: 90%;
  }
  </style>
  