<template>
    <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" align-content="center" justify="center">
        <v-col class="ma-0 pa-0" cols="12" align="center">
            <span class="statistic_value_text">{{ value == null ? statistic_value : value.toLocaleString("en-CA",{style: "currency", currency: "CAD",  minimumFractionDigits: 2, maximumFractionDigits: 2, notation: 'compact'})}} 
                <v-icon v-if="change > 0" color="green">mdi-arrow-up</v-icon>
                <v-icon v-else-if="change < 0" color="red">mdi-arrow-down</v-icon>
                <v-icon v-else-if="change == 0" color="black">mdi-minus</v-icon>
            </span>
        </v-col>
        <v-col class="ma-0 pa-0" cols="12" align="center">
            <span class="statistic_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'PerformanceMainStat',
        props: [
            'statistic_title_name',
            'value',
            'change'
        ],
        data: () => ({
            statistic_value: '-',
            statistic_name: 'Statistic',
        })
    }
</script>

<style scoped>
    .statistic_value_text{
        font-family: "Poppins" !important;
        font-weight: 600;
        font-size: 18pt;
    }
    .statistic_title{
        font-family: "Amiko";
        font-size: 10pt;
    }
</style>