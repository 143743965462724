<template>
  <v-container class="px-6 pt-0 pb-3" style="max-width: 100%">
    <v-dialog
        v-model="save_view"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Save Bookmark</span>
          </v-card-title>

          <v-card-text>
            <v-text-field v-model="view_name_tmp" hint="Bookmark Name" persistent-hint></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              text
              @click="close_save_view_func()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="black"
              text
              @click="add_view()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-row class="ma-0 pa-0" style="width: 100%; height: 100%">
      <v-col
        :cols="(toolbar_visible || views_visible) && portfolio_type != 'performance' ? 9 : 12"
        style="width: 100%;"
        v-if="tbl_avaialable"
      >
        <v-row class="ma-0 pa-0">
          <v-col cols="7" class="ma-0 pa-0">
            <v-chip
              label
              class="data_breakdown_chip"
              :outlined="portfolio_type != 'portfolio'"
              @click="portfolio_type = 'portfolio'"
              color="black"
            >
              Portfolio
            </v-chip>
            <v-chip
              label
              class="data_breakdown_chip"
              :outlined="portfolio_type != 'performance'"
              @click="portfolio_type = 'performance'"
              color="black"
            >
              Performance
            </v-chip>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="ma-0 pa-0">
            <v-tooltip top v-if="views_visible && portfolio_type != 'performance'">
              <template v-slot:activator="{ on }">
                <v-btn icon elevation="0" v-on="on" @click="views_visible = false">
                  <v-icon color="black">mdi-bookmark-off</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Hide Bookmarks" desc_2=""></TooltipDescription>
            </v-tooltip>
            <v-tooltip top v-if="!views_visible && portfolio_type != 'performance'">
              <template v-slot:activator="{ on }">
                <v-btn icon elevation="0" v-on="on" @click="views_visible = true; toolbar_visible = false">
                  <v-icon color="black">mdi-bookmark-outline</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Bookmarks" desc_2=""></TooltipDescription>
            </v-tooltip>

            <v-tooltip top v-if="toolbar_visible && portfolio_type != 'performance'">
              <template v-slot:activator="{ on }">
                <v-btn icon elevation="0" v-on="on" @click="toolbar_visible = false">
                  <v-icon color="black">mdi-filter-off</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Hide Toolbar" desc_2=""></TooltipDescription>
            </v-tooltip>
            <v-tooltip top v-if="!toolbar_visible && portfolio_type != 'performance'">
              <template v-slot:activator="{ on }">
                <v-btn icon elevation="0" v-on="on" @click="toolbar_visible = true; views_visible = false">
                  <v-icon color="black">mdi-filter-settings-outline</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Toolbar" desc_2=""></TooltipDescription>
            </v-tooltip>
          </v-col>
          <v-col cols="12" class="ma-0 pa-0" v-if="portfolio_type == 'portfolio'">
            <DataLevelTable
              :tableHeader="headers"
              :tableData="performanceData"
              :selectedLevels="selected_levels"
              :tableRowClickFunction='handle_row_click'
              :numericColumns='numericColumns'
              :currencyColumns='currencyColumns'
              :percentageColumns='percentageColumns'
              v-on:filter_data="filter_added"
              :filterable="true"
              :filter_all_vals="filter_base_values"
            />
          </v-col>
          <v-col cols="12" class="ma-0 pa-0" v-if="portfolio_type == 'performance'">
            <PerformanceOverview :data_levels="selected_levels" :project_date_range="project_date_range" :project_filters="data_filters"></PerformanceOverview>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" style="width: 100%; height: 100%; max-height: 550px" v-else>
        <v-row class="ma-0 pa-0" justify="center" :align="'center'" style="height: 100%">
          <v-col cols="auto" class="ma-0 pa-0">
            <v-progress-circular
              size="100"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="3"
        style="height: 100%"
        v-if="views_visible && portfolio_type != 'performance' && tbl_avaialable"
      >
        <v-card style="height: 500px; max-height: 500px; overflow-y: scroll">
          <v-card-title>
            <v-icon class="ml-0 mr-3" color="black">mdi-bookmark-outline</v-icon>
            <span class="toolbar_title">Bookmarks</span>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon elevation="0" v-on="on" @click="refresh_views">
                  <v-icon color="black">mdi-reload</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Refresh" desc_2=""></TooltipDescription>
            </v-tooltip>

            <!-- <v-icon @click="refresh_views">mdi-reload</v-icon> -->
          </v-card-title>
          <!-- <v-col cols="12">
            <v-select
              class="toolbar_selector"
              disabled
              :value="data_version"
              label="Data Version"
              hint="The data version of the blocking chart"
              persistent-hint
              :items="[data_version]"
              @change="reset_table()"
            ></v-select>
          </v-col> -->
          <v-row style="width: 100%" class="ma-0 pa-0">
            <v-col cols="12">
              <v-list style="width: 100%" rounded>
                <v-list-item-group v-model="selected_view">
                  <v-list-item v-for="(view,idx) in available_views" :key="idx">
                    <v-list-item-icon>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon elevation="0" v-on="on" @click="delete_view(view.id)">
                            <v-icon color="black">mdi-bookmark-remove-outline</v-icon>
                          </v-btn>
                        </template>
                        <TooltipDescription title="Delete Bookmark" desc_2=""></TooltipDescription>
                      </v-tooltip>
                    </v-list-item-icon>
                    <v-list-item-title>{{view.name }}</v-list-item-title>
                    <v-list-item-icon>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon elevation="0" v-on="on" @click="open_view(idx)">
                            <v-icon color="black">mdi-open-in-app</v-icon>
                          </v-btn>
                        </template>
                        <TooltipDescription title="Open Bookmark" desc_2=""></TooltipDescription>
                      </v-tooltip>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="3"
        style="height: 100%"
        v-if="toolbar_visible && portfolio_type != 'performance' && tbl_avaialable"
      >
        <v-card style="height: 500px; max-height: 500px; overflow-y: scroll">
          <v-card-title>
            <v-icon class="ml-0 mr-3" color="black">mdi-hammer-screwdriver</v-icon>
            <span class="toolbar_title">Toolbar</span>
            <v-spacer></v-spacer>
            <v-tooltip top v-if="selected_view == null">
              <template v-slot:activator="{ on }">
                <v-btn icon elevation="0" v-on="on" @click="save_view_func">
                  <v-icon color="black">mdi-bookmark-plus-outline</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Add Bookmark" desc_2=""></TooltipDescription>
            </v-tooltip>
            <v-tooltip top v-else>
              <template v-slot:activator="{ on }">
                <v-btn icon elevation="0" v-on="on">
                  <v-icon color="black">mdi-bookmark</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Bookmarked" desc_2=""></TooltipDescription>
            </v-tooltip>
          </v-card-title>
          <v-col cols="12">
            <v-select
              class="toolbar_selector"
              disabled
              :value="data_version"
              label="Data Version"
              hint="The data version of the blocking chart"
              persistent-hint
              :items="[data_version]"
              @change="reset_table()"
            ></v-select>
          </v-col>
          <v-row style="width: 100%" class="ma-0 pa-0">
            <v-col cols="12" v-for="(lvl) in Object.keys(data_filters)" :key="lvl">
              <span class="filter_title">{{ lvl }} Filter: </span><br/>
              <span class="filter_vals">{{ format_filter_val_output(data_filters[lvl]) }}</span>
              <!-- <span>{{ filter_base_values[lvl] }}</span>
              <v-select v-model="data_filters[lvl]" :items="filter_base_values[lvl]"></v-select> -->
            </v-col>
            <v-col cols="12">
              <v-select
                class="toolbar_selector"
                v-model="selected_levels"
                :items="available_data_levels"
                label="Levels"
                multiple
                hint="Pick the level of granularity you wish to view the data."
                persistent-hint
                chips
                @input="refresh_data(true)"
              >
              </v-select>
            </v-col>
            <v-col
              class="mr-0 ml-auto my-0 py-0 mx-3"
              :cols="12 - idx * 2"
              v-for="(val, idx) in selected_levels"
              :key="idx"
            >
              <v-icon class="mb-3" color="black">mdi-arrow-right-bottom-bold</v-icon>
              <span class="toolbar_selector">{{ val }}</span>
            </v-col>
            <DateRange
              :start_date="start_date"
              :end_date="end_date"
              :min_start_date="project_date_range['min_start_date']"
              :max_end_date="project_date_range['max_end_date']"
              v-on:start_date_update="update_start_date"
              v-on:end_date_update="update_end_date"
              v-on:date_update="update_date"
            ></DateRange>
            <!-- <v-col cols="12">
              <v-select
                class="toolbar_selector"
                v-model="selected_sort_keys"
                :items="sort_keys"
                label="Sort By"
                multiple
                hint="Pick the data fields you would like to sort by."
                persistent-hint
                chips
                disabled
                @input="reset_table"
              >
              </v-select>
            </v-col>
          -->
            <v-col cols="12">
              <v-select
                class="toolbar_selector ma-0 pa-0"
                v-model="selected_fields"
                :items="data_fields"
                label="Fields"
                multiple
                hint="Pick the data fields you wish to view."
                persistent-hint
                chips
                @input="reset_table"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-switch
                class="toolbar_selector ma-0 pa-0"
                v-model="compare"
                label="Compare"
                @change="
                  compare_end_date = end_date;
                  update_compare_start(start_date);
                "
              ></v-switch>
              <DateRange
                v-if="compare"
                :start_date="compare_start_date"
                :end_date="compare_end_date"
                :min_start_date="project_date_range['min_start_date']"
                :max_end_date="project_date_range['max_end_date']"
                v-on:start_date_update="update_compare_start"
                v-on:end_date_update="update_compare_end"
                v-on:date_update="update_compare_date"
              ></DateRange>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TooltipDescription from "@/components/Utility/TooltipDescription.vue";
import DataLevelTable from "@/components/Utility/DataLevelTable.vue";
import DateRange from "@/components/Utility/DateRange.vue";
import { ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE } from "@/constants/constants";

import PerformanceOverview from "@/components/Performance/PerformanceStats/PerformanceOverview.vue"

// import performance_data_header from "@/input_data/performance_table_header.json";
// import performance_data from "@/input_data/Performance/MMM_totals.json";

import chartConfig from "@/configs/Performance/performance_portfolio_sales.json";
import categoryChartConfig from "@/configs/Performance/performance_portfolio_categories.json";
import light_mode_colors from "@/configs/ChartColors/light_mode_colors.json";

import api_caller from "@/javascript/data_retrieval.js";
import info_caller from "@/javascript/data_info.js";
// import { GChart } from "vue-google-charts/legacy";

// import cpalette from "@/configs/ChartColors/get_color_palette.js";

export default {
  name: "PerformanceStatsTable",
  props: ["data_version", "data_levels", "project_date_range"],
  components: {
    TooltipDescription,
    DataLevelTable,
    DateRange,
    // GChart,
    PerformanceOverview
  },
  data: () => ({
    performanceDataRaw: null,
    performanceData: [],
    compareDataRaw: null,
    compare_data: null,
    selected_levels: [],
    selected_fields: [],
    selected_sort_keys: [],
    tbl_avaialable: false,
    toolbar_visible: true,
    portfolio_type: "portfolio",

    available_data_levels: [],

    data_filters: {},

    views_visible: false,
    selected_view: null,
    save_view: false,
    view_name_tmp: "",
    available_views: [],

    compare: false,
    compare_start_date: null,
    compare_end_date: null,

    portfolio_sos_config: chartConfig,
    portfolio_sos_data: [
      ["date", "sos", "csos", "sales"],
      ["2023-01-01", 50, 70, 100000],
      ["2023-01-08", 40, 60, 150000],
      ["2023-01-15", 60, 50, 175000],
      ["2023-01-22", 55, 55, 200000],
      ["2023-01-29", 60, 57, 200000],
    ],

    portfolio_categories_config: categoryChartConfig,
    portfolio_categories_data: [
      ["cat", "val"],
      ["OOH", 10],
      ["Programmatic", 30],
      ["TV", 40],
      ["DOOH", 50],
    ],

    start_date: null,
    end_date: null,

    filter_base_values: {}
  }),
  mounted() {
    this.portfolio_sos_config["colors"] = light_mode_colors;
    this.portfolio_categories_config["colors"] = light_mode_colors;
    this.refresh_views();
  },
  watch: {
    project_date_range: {
      immediate: false,
      handler: function () {
        this.start_date = this.project_date_range["start_date"];
        this.end_date = this.project_date_range["end_date"];
        this.refresh_data();
      },
      deep: true,
    },
    data_levels: {
      immediate: false,
      handler: function () {
        this.available_data_levels = this.data_levels['data_levels'];
        if (this.selected_levels.toString() != this.data_levels["selected_levels"].toString()) {
          this.selected_levels = this.data_levels["selected_levels"];
          this.refresh_data();
        }
      },
      deep: true,
    },
  },
  computed: {
    numericColumns: function () {
      return [
        "last",
        "change",
        "cost_basis",
        "reach",
        "frequency",
        "daily_pl",
        "sales",
        "impressions",
        "impressions compare",
        "clicks",
        "clicks compare",
      ];
    },
    currencyColumns: function () {
      return [
        "roi",
        "roi compare",
        "spend",
        "spend compare",
        "sales_attribution",
        "sales_attribution compare",
        "cpm",
        "avg_cpm",
        "cpc",
        "avg_cpc"
      ];
    },
    percentageColumns: function () {
      return [
        "roi change",
        "spend change",
        "impressions change",
        "sales_attribution change",
        "clicks change",
        "ctr",
        "avg_ctr"
      ];
    },
    // available_data_levels: function () {
    //   if (this.performanceDataRaw == null) {
    //     return [];
    //   }
    //   return Object.keys(this.performanceDataRaw["table_levels"]);
    // },
    data_fields: function () {
      let fields = [];
      if (this.performanceDataRaw == null) {
        return [];
      }
      for (let header_row of this.performanceDataRaw["table_headers"]) {
        if (!this.available_data_levels.includes(header_row["value"])) {
          fields.push(header_row["text"]);
        }
      }
      return fields;
    },
    sort_keys: function () {
      let sort_keys = [];
      for (let header_row of this.headers) {
        sort_keys.push(header_row["value"]);
      }
      return sort_keys;
    },
    headers: function () {
      let data_tbl_header = [];

      if (this.performanceDataRaw == null) {
        return [];
      }

      for (let header_row of this.performanceDataRaw["table_headers"]) {
        if (
          (this.selected_fields.length == 0 ||
            this.selected_fields.includes(header_row["text"])) &&
          !this.available_data_levels.includes(header_row["value"])
        ) {
          data_tbl_header.push(header_row);
          if (
            this.compare &&
            !["avg_cpm", "cpm", "cpc", "avg_cpc", "ctr", "avg_ctr", "reach", "frequency"].includes(header_row["value"])
          ) {
            let header_row_tmp = { ...header_row };
            header_row_tmp["value"] = header_row_tmp["value"] + " compare";
            header_row_tmp["text"] = header_row_tmp["text"] + " compare";
            data_tbl_header.push(header_row_tmp);
            header_row_tmp = { ...header_row };
            header_row_tmp["value"] = header_row_tmp["value"] + " change";
            header_row_tmp["text"] = header_row_tmp["text"] + " change";
            data_tbl_header.push(header_row_tmp);
          }
        }
      }

      this.selected_levels
        .slice()
        .reverse()
        .forEach((lvl) =>
          data_tbl_header.unshift(this.performanceDataRaw["table_levels"][lvl])
        );
      return data_tbl_header;
    },
  },
  methods: {
    format_filter_val_output: function(filters){
      let filter_str = "";
      for(let f of filters){
        filter_str += f
        filter_str += ", "
      }
      return filter_str.slice(0,-2)
    },
    refresh_data: async function (emit_update = false) {
      this.tbl_avaialable = false;
      let date_range = {
        start_date: this.start_date,
        end_date: this.end_date,
      };
      let token = await this.$auth.getTokenSilently();
      let portfolio_data = await api_caller
        .get_portfolio_data(token, this.$project.get_val(), date_range, this.data_filters, this.selected_levels)
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          }
          console.log(resp)
          return false;
        })
        .catch((e) => {
          console.log("error", e);
          return false;
        });
      // let portfolio_overview_data = await api_caller
      //   .get_portfolio_overview_data(token, this.$project.get_val())
      //   .then((resp) => {
      //     return resp["data"];
      //   })
      //   .catch((e) => {
      //     console.log("error", e);
      //     return false;
      //   });
      // if (!portfolio_data) {
      //   this.performanceDataRaw = null;
      //   this.update_data_levels(false);
      //   this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
      //   return;
      // }
      // if (!portfolio_overview_data) {
      //   this.portfolio_sos_data = [];
      // }
      
      if(portfolio_data){
        this.performanceDataRaw = portfolio_data;
        this.performanceData = portfolio_data["table_content"];
        this.filter_base_values = portfolio_data["available_filter_vals"];



        // this.portfolio_sos_data = portfolio_overview_data["search_sales"];
        // this.portfolio_categories_data = portfolio_overview_data["overview"];

        this.update_data_levels(emit_update);
        this.reset_table();
      }
    },
    refresh_compare_data: async function () {
      this.tbl_avaialable = false;
      let date_range = {
        start_date: this.compare_start_date,
        end_date: this.compare_end_date,
      };
      let token = await this.$auth.getTokenSilently();
      let portfolio_data = await api_caller
        .get_portfolio_data(token, this.$project.get_val(), date_range, this.data_filters)
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          }
          console.log(resp)
          return false;
        })
        .catch((e) => {
          console.log("error", e);
          return false;
        });
      if (!portfolio_data) {
        this.compareDataRaw = null;
        this.update_data_levels(false);
        this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
        return;
      }
      this.compareDataRaw = portfolio_data;
      this.update_data_levels(false);
      this.reset_table();
    },
    refresh_views: async function(){
      let token = await this.$auth.getTokenSilently();
      let saved_views = await info_caller
        .get_saved_views(token, this.$project.get_val())
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          }
          return false;
        })
        .catch((e) => {
          console.log("error", e);
          return false;
        });
      if (!saved_views) {
        this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
        return;
      }
      this.available_views = saved_views['views'];      
    },
    reset_table: function () {
      this.tbl_avaialable = false;
      setTimeout(() => (this.tbl_avaialable = true), 100);
    },
    // sort_table: function () {
    //   return;
    // },
    handle_row_click: function (value) {
      let channel = {
        data_levels: [],
        data_query: [],
      };
      let levels = [];
      for (let level of this.selected_levels) {
        if (value[level]) {
          channel["data_query"].push({
            level: level,
            value: value[level],
          });
          levels.push(level);
        } else {
          return;
        }
      }
      channel["data_levels"] = levels;
      this.$emit("switch_tab", "graph", channel);
    },
    level_val: function (item, col) {
      let idx = this.selected_levels.indexOf(col);
      let hide = false;
      for (let i = this.selected_levels.length - 1; i > idx; i--) {
        if (item[this.selected_levels[i]] != null) {
          hide = true;
        }
      }
      if (hide) {
        return "";
      } else {
        return item[col];
      }
    },
    update_data_levels: function (emit_update) {
      if (this.performanceDataRaw == null) {
        this.performanceData = [];
        return;
      }
      // function onlyUnique(value, index, array) {
      //   return array.indexOf(value) === index;
      // }
      // let filter_base_vals = {};
      // for (let l of this.selected_levels) {
      //   filter_base_vals[l] = []
      // }
      // for (let r of this.performanceDataRaw["table_content"]){
      //   for (let l of this.selected_levels) {
      //     if(r[l] != null){
      //       filter_base_vals[l].push(r[l]);
      //     }
      //   }
      // }
      // for (let l of this.selected_levels) {
      //   filter_base_vals[l] = filter_base_vals[l].filter(onlyUnique)
      // }
      // console.log(filter_base_vals)
      // this.filter_base_values = filter_base_vals;
      this.performanceData = this.filter_dataset(
        this.performanceDataRaw["table_content"]
      );
      if (this.compare) {
        let compare_data = this.filter_dataset(this.compareDataRaw["table_content"]);
        for (let idx = 0; idx < this.performanceData.length; idx++) {
          let row_id = null;
          for (let cdx = 0; cdx < compare_data.length; cdx++) {
            for (let level of this.selected_levels) {
              if (!(compare_data[cdx][level] === this.performanceData[idx][level])) {
                row_id = null;
                break;
              } else {
                row_id = cdx;
              }
            }
            if (row_id != null) {
              break;
            }
          }
          this.performanceData[idx]["roi compare"] = compare_data[row_id]["roi"];
          this.performanceData[idx]["roi change"] =
            this.performanceData[idx]["roi"] == 0 || this.performanceData[idx]["roi"] == 0
              ? 0
              : (this.performanceData[idx]["roi"] -
                  this.performanceData[idx]["roi compare"]) /
                this.performanceData[idx]["roi compare"];

          this.performanceData[idx]["spend compare"] = compare_data[row_id]["spend"];
          this.performanceData[idx]["spend change"] =
            this.performanceData[idx]["spend"] == 0 ||
            this.performanceData[idx]["spend"] == 0
              ? 0
              : (this.performanceData[idx]["spend"] -
                  this.performanceData[idx]["spend compare"]) /
                this.performanceData[idx]["spend compare"];

          this.performanceData[idx]["impressions compare"] =
            compare_data[row_id]["impressions"];
          this.performanceData[idx]["impressions change"] =
            this.performanceData[idx]["impressions"] == 0 ||
            this.performanceData[idx]["impressions"] == 0
              ? 0
              : (this.performanceData[idx]["impressions"] -
                  this.performanceData[idx]["impressions compare"]) /
                this.performanceData[idx]["impressions compare"];

          this.performanceData[idx]["sales_attribution compare"] =
            compare_data[row_id]["sales_attribution"];
          this.performanceData[idx]["sales_attribution change"] =
            this.performanceData[idx]["sales_attribution"] == 0 ||
            this.performanceData[idx]["sales_attribution"] == 0
              ? 0
              : (this.performanceData[idx]["sales_attribution"] -
                  this.performanceData[idx]["sales_attribution compare"]) /
                this.performanceData[idx]["sales_attribution compare"];
          
          this.performanceData[idx]["clicks compare"] =
            compare_data[row_id]["clicks"];
          this.performanceData[idx]["clicks change"] =
            this.performanceData[idx]["clicks"] == 0 ||
            this.performanceData[idx]["clicks"] == 0
              ? 0
              : (this.performanceData[idx]["clicks"] -
                  this.performanceData[idx]["clicks compare"]) /
                this.performanceData[idx]["clicks compare"];
        }
      }

      let level_values = [];
      let level_name = "";
      let valid = false;

      for (let row of this.performanceData) {
        level_name = "";
        valid = true;
        for (let l of this.selected_levels) {
          if (row[l]) {
            level_name += row[l];
          } else {
            valid = false;
          }
          if (row[l] != null) {
            level_name += "__";
          }
        }
        if (valid) {
          level_values.push(level_name.slice(0, -2));
        }
      }
      if (emit_update || this.data_levels["level_values"].length == 0) {
        this.$emit("level_update", {
          data_levels: this.available_data_levels,
          selected_levels: this.selected_levels,
          level_values: level_values,
          data_filters: this.data_filters,
          filter_base_values: this.filter_base_values
        });
      }
    },
    filter_added(filter){
      this.data_filters = filter;
      this.refresh_data(true);
      this.$emit('filter_update', this.data_filters);
    },
    filter_dataset(data) {
      let data_rows = [];
      let level_idxs = {};
      let include = false;
      let include_sub = false;
      let num_levels = this.selected_levels.length;
      let i = 0;
      for (let level of this.selected_levels) {
        level_idxs[level] = i;
        i++;
      }
      for (let row of data) {
        include = true;
        for (let level of this.available_data_levels) {
          //group rows
          if (!this.selected_levels.includes(level) && row[level] != null) {
            include = false;
          }
        }
        include_sub = true;
        i = 0;
        for (i = 0; i < num_levels; i++) {
          if (i == 0 && row[this.selected_levels[i]] == null) {
            include = false;
            break;
          } else if (row[this.selected_levels[i]] == null) {
            include_sub = false;
          } else if (include_sub == false && row[this.selected_levels[i]] != null) {
            include = false;
            break;
          }
          if(this.selected_levels[i] in this.data_filters){
            if(!this.data_filters[this.selected_levels[i]].includes(row[this.selected_levels[i]]) && row[this.selected_levels[i]] != null){
              include = false;
              break;
            }
          }
        }
        if (include) {
          data_rows.push(row);
        }
      }
      return data_rows;
    },
    // Dashboard Date Changes
    update_start_date(date) {
      this.selected_view = null;
      this.$emit("date_update", { start_date: date, end_date: this.end_date });
    },
    update_end_date(date) {
      this.selected_view = null;
      this.$emit("date_update", { start_date: this.start_date, end_date: date });
    },
    update_date(date) {
      this.selected_view = null;
      this.$emit("date_update", { start_date: date[0], end_date: date[1] });
    },
    update_compare_start(date) {
      this.compare_start_date = date;
      this.selected_view = null;
      this.refresh_compare_data();
    },
    update_compare_end(date) {
      this.compare_end_date = date;
      this.selected_view = null;
      this.refresh_compare_data();
    },
    update_compare_date(date){
      this.compare_start_date = date[0];
      this.compare_end_date = date[1];
      this.selected_view = null;
      this.refresh_compare_data();
    },
    // Bookmark Functions
    open_view: async function(view){
      let data_view = this.available_views[view];
      if(data_view['compare']){
        this.compare = data_view['compare'];
        this.compare_start_date = data_view['compare_start_date'];
        this.compare_end_date = data_view['compare_end_date'];
        await this.refresh_compare_data();
      }
      else{
        this.compare = data_view['compare'];
        this.compare_start_date = data_view['compare_start_date'];
        this.compare_end_date = data_view['compare_end_date'];
      }
      this.$emit("date_update", { start_date: data_view['start_date'], end_date: data_view['end_date'] });
      this.selected_view = view;
    },
    save_view_func: function(){
      this.save_view = true;
    },
    close_save_view_func: function(){
      this.save_view = false;
      this.view_name_tmp = '';
    },
    add_view: async function(){
      let token = await this.$auth.getTokenSilently();
      let view = {
        'name': this.view_name_tmp,
        'start_date': this.start_date,
        'end_date': this.end_date,
        'data_levels': this.selected_levels.join('|'),
        'compare': this.compare,
        'compare_start_date': this.compare_start_date,
        'compare_end_date': this.compare_end_date
      };
      await info_caller.add_view(token, this.$project.get_val(),view);
      this.view_name_tmp = '';
      this.save_view = false;
      await this.refresh_views();
      this.selected_view = this.available_views.length - 1
    },
    delete_view: async function (view) {
      let token = await this.$auth.getTokenSilently();
      await info_caller.delete_view(token, this.$project.get_val(), view);
      this.refresh_views();
    }
  },
};
</script>

<style>

.table-title {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 1.5em;
}
.performance_section_title {
  font-family: 'Poppins' !important;
  font-weight: 500 ;
  font-size: 0.9 em;
}
.toolbar_title {
  font-family: 'Poppins' !important;
  font-weight: 600 ;
  font-size: 1.1em;
}
.toolbar_selector {
  font-family: 'Poppins' !important;
  font-weight: 400 ;
  font-size: 0.9 em;
}
.v-list-item .v-list-item__title {
  font-family: 'Poppins' !important;
  font-weight: 400 ;
  font-size: 0.85em;
}

.filter_title {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1em;
}
.filter_vals {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 0.9em;
}
</style>
