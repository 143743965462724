import Vue from 'vue'
import Router from 'vue-router'
// import { getAuth } from 'firebase/auth'

import LoginPage from '../views/LoginView.vue'
import AudienceBasePage from '../components/Audiences/AudienceBasePage.vue';
import PerformanceBasePage from '../components/Performance/PerformanceBasePage';
import PlanningBasePage from '../components/Planning/PlanningBasePage';
import SignUpPage from '../views/SignUpView.vue'
import FullPageLoad from '../views/FullPageLoad.vue';
import { authGuard } from '../auth/authGuard';

Vue.use(Router)

const router = new Router({
    routes : [
        {
          path: '*',
          redirect: '/login'
        },
        {
          path: '/',
          redirect: '/login'
        },
        {
        path: '/audience',
        name: 'Audience',
        component: AudienceBasePage,
          meta: {
              requiresAuth: true,
              title: 'Docma',
              hideNavbar: false,
          },
        beforeEnter: authGuard
        },
        {
          path: '/performance',
          name: 'Performance',
          component: PerformanceBasePage,
            meta: {
                requiresAuth: true,
                title: 'Docma',
                hideNavbar: false,
            },
            beforeEnter: authGuard
        },
        {
          path: '/planning',
          name: 'Planning',
          component: PlanningBasePage,
            meta: {
                requiresAuth: true,
                title: 'Docma',
                hideNavbar: false,
            },
            beforeEnter: authGuard
        },
        {
          path: '/login',
          name: 'Login',
        //   redirect: '/ServerMaintenance',
          component: LoginPage,
          meta: {
              title: 'Docma',
              hideNavbar: true,
          }
        },
        {
          path: '/signup',
          name: 'SignUp',
          component: SignUpPage,
          meta: {
            title: 'Docma',
            hideNavbar: true,
          }
        },
        {
          path: '/load',
          name: 'FullPageLoad',
          component: FullPageLoad,
          meta: {
            title: 'Docma',
            hideNavbar: true
          }
        }
    ]
});


export default router;
