var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-0 pa-0",staticStyle:{"height":"100%","max-height":"100%","overflow-y":"scroll"},attrs:{"flat":""}},[_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"report-subtitle"},[_vm._v("Spend, Impressions & Contribution")])]),_c('v-spacer'),_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"height":"10px"},attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mx-12"})],1),(!_vm.graph_visible)?_c('v-col',{staticClass:"px-12",attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"size":"100","indeterminate":"","color":"primary"}})],1):_vm._e(),(_vm.graph_visible)?_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"height":"calc(100% - 10px)"},attrs:{"cols":_vm.spend_impr_contribution_col_size}},[_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"height":"90%"}},[_c('v-col',{staticClass:"chartAlign",attrs:{"cols":"4"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"centerLabel"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"centerLabelDataStat pb-1",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.spend_total))])]),_c('v-col',{staticClass:"centerLabelDataTitle pt-1",attrs:{"cols":"12"}},[_c('span',[_vm._v("Spend")])])],1)],1),_c('div',{style:({
                    height: _vm.screenHeight + 'px'
                  })},[_c('GChart',{staticClass:"performanceChart",attrs:{"type":"PieChart","data":_vm.pie_chart_data['spend'],"options":_vm.pie_chart_config,"resizeDebounce":50}})],1)])],1),_c('v-col',{staticClass:"chartAlign",attrs:{"cols":"4"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"centerLabelB"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"centerLabelDataStat pb-1",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.impressions_total))])]),_c('v-col',{staticClass:"centerLabelDataTitle pt-1",attrs:{"cols":"12"}},[_c('span',[_vm._v("Impressions")])])],1)],1),_c('div',{style:({
                    height: _vm.screenHeight + 'px'
                  })},[_c('GChart',{staticClass:"performanceChart",attrs:{"type":"PieChart","data":_vm.pie_chart_data['impressions'],"options":_vm.pie_chart_config,"resizeDebounce":50}})],1)])],1),_c('v-col',{staticClass:"chartAlign",attrs:{"cols":"4"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"centerLabelC"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"centerLabelDataStat pb-1",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.sales_attribution_total))])]),_c('v-col',{staticClass:"centerLabelDataTitle pt-1",attrs:{"cols":"12"}},[_c('span',[_vm._v("Attribution")])])],1)],1),_c('div',{style:({
                  height: _vm.screenHeight + 'px'
                })},[_c('GChart',{staticClass:"performanceChart",attrs:{"type":"PieChart","data":_vm.pie_chart_data['sales_attribution'],"options":_vm.pie_chart_config,"resizeDebounce":50}})],1)])],1)],1)],1):_vm._e()],1),_c('v-spacer'),(_vm.spend_impr_contribution_overview_comments)?_c('v-col',{attrs:{"cols":"4"}},[_c('CommentSection',{attrs:{"comments_persistent":_vm.comments_persistent},on:{"close_comments":_vm.close_comments}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }