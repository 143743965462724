<template>
    <v-row class="ma-0 pa-0" style="max-width: 300px;">
        <v-col cols="12" class="ma-0 pa-0">
            <span class="tooltip_title">{{title}}</span>
        </v-col>
        <v-col cols="12" class="ma-0 pa-0">
            <span class="tooltip_description">{{desc_2}}</span>
        </v-col>
        <v-col cols="12" class="ma-0 pa-0">
            <span class="tooltip_description">{{desc_3}}</span>
        </v-col>
    </v-row>
</template>

<script>
export default {
  name: 'TooltipDescription',
  props: [
    'title',
    'desc_2',
    'desc_3'
  ],
  mounted(){

  },
  data: () => ({

  }),
  methods: {
    
  }
}
</script>

<style scoped>
    .tooltip_description{
        font-family: 'Poppins' !important;
        font-weight: 200;
    }
    .tooltip_title{
        font-family: 'Poppins' !important;
        font-weight: 400;
    }
</style>