import axios from 'axios'

// const api_endpoint = 'https://enterprise.api.docmasweetspot.ca'
const api_endpoint = 'https://staging.enterprise.api.docmasweetspot.ca'
// const api_endpoint = 'http://127.0.0.1:5001'


function get_project_info(token, project_id){
    let api_url = api_endpoint+'/reporting/info/project_info';
    let data = {"project_id": project_id};
    return axios_post_call(api_url,data,token);
}

function get_saved_projects(token){
    let api_url = api_endpoint+'/reporting/info/saved_projects';
    let data = {};
    return axios_post_call(api_url,data,token);
}

function get_user_orgs(token, user_id){
    let api_url = api_endpoint+'/user_management/get_user_orgs';
    let data = {"user_id": user_id};
    return axios_post_call(api_url,data,token);
}

function get_saved_views(token, project_id){
    let api_url = api_endpoint+'/reporting/info/saved_views';
    let data = {"project_id": project_id};
    return axios_post_call(api_url,data,token);
}

function add_view(token, project_id, view){
    let api_url = api_endpoint+'/reporting/info/add_view';
    let data = {"project_id": project_id, "view": view};
    return axios_post_call(api_url,data,token);
}
function delete_view(token, project_id, view){
    let api_url = api_endpoint+'/reporting/info/delete_view';
    let data = {"project_id": project_id, "view_id": view};
    return axios_post_call(api_url,data,token);
}


/*
Base Caller functions
*/
function axios_post_call(api_url,data,token){
    return new Promise(function(resolve,reject){
        axios.post(api_url, data, {headers:{Authorization: `Bearer ${token}`}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        }).catch((e)=>{
            reject(api_url+e);
        })
    })
}

export default {get_project_info, get_saved_projects, get_user_orgs, get_saved_views, add_view, delete_view}