<template>
    <v-container class="ma-0 pa-0" fluid style="max-height: calc(100vh - 98px); width: 100%;">
      <v-row class="ma-0 pa-0" style="height: 100%">
        <v-col cols="12" class="ma-0 pa-0" style="height: 100%">
          <!-- Title And Highlights Row-->
          <v-row width="100%" style="min-height: 10%;" class="ma-0 pa-6">
            <!-- Title Column-->
            <v-col cols="4" class="ma-0 pa-0">
              <v-row width="100%" class="ma-0 pa-0">
                <v-img :src="main_logo" max-height="48px" max-width="80px" contain></v-img>
                <span class="page-title">TalkTrend</span>
              </v-row>
              <v-row width="100%" class="ma-0 pa-0">
                <v-tabs v-model="audience_tab" align-tabs="start" light>
                    <v-tab class="audience-tool-navigation-bar" href="#design">Design</v-tab>
                    <v-tab class="audience-tool-navigation-bar" href="#view">Stats</v-tab>
                    <v-tab disabled class="audience-tool-navigation-bar" href="#save">Saved</v-tab>
                </v-tabs>
              </v-row>
            </v-col>
            <!--Highlights Column-->
            <v-col cols="8" class="ma-0 pa-0" v-if="audience_tab == 'view'">
                <AudienceHighlights :audience_data="viewing_audience_data" :graph_index="viewing_graph_index" />
            </v-col>
            <!--Runner Column-->
            <v-col cols="8" class="ma-0 pa-0" v-if="audience_tab == 'design'">
                <AudienceSelectionRunner :audiences="available_audiences" :selected_editing_audience="editing_audience_info" :selected_viewing_audience="viewing_audience_info" 
                v-on:view_stats="audience_tab = 'view'" v-on:editing_audience="update_editing_audience" v-on:viewing_audience="update_viewing_audience" v-on:build_audience="build_audience"></AudienceSelectionRunner>
            </v-col>
          </v-row>

          <v-tabs-items v-model="audience_tab" style="height: 80%; max-height: 80%; width: 100%;">
            <v-tab-item style="height: 100%; max-height: 100%; width: 100%;" value="design">
              <AudienceSelectionPage :audience_info="editing_audience_info" :audience_configuration="editing_audience_configuration"></AudienceSelectionPage>
            </v-tab-item>
            <v-tab-item style="height: 100%; max-height: 100%; width: 100%;" value="view">
              <AudienceStatsBasePage :audiences="available_audiences" :audience_info="viewing_audience_info" :audience_data="viewing_audience_data" v-on:graphIndexChanged="graphIndexOnChange" v-on:viewing_audience="update_viewing_audience"></AudienceStatsBasePage>
            </v-tab-item>
            <v-tab-item value="save">

            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import AudienceSelectionPage from "./AudienceSelectorDrawer/AudienceSelectionPage.vue"
import AudienceSelectionRunner from "./AudienceSelectorDrawer/AudienceSelectionRunner.vue";
// Import Audience Stats sections
import AudienceHighlights from "./AudienceStats/AudienceHighlights.vue"
import AudienceStatsBasePage from "./AudienceStats/AudienceStatsBasePage.vue";
// Import Demo Data
import demo_configs from "@/test_data/demo_configurations.json"
import demo_config_info from "@/test_data/demo_configuration_info.json"
import demo_data from "@/test_data/pull_demo_data.js"

// import api_caller from "@/javascript/audience.js";

import logo from "@/assets/TalkTrendLogo.svg"

export default {
    name: 'AudienceBasePage',
    components: {
      AudienceSelectionRunner,
      AudienceSelectionPage,

      AudienceHighlights,
      AudienceStatsBasePage
    },
    mounted(){
      this.configuration_setups = demo_configs;
      this.available_audiences = demo_config_info;
      this.audience_data = demo_data;
      this.update_editing_audience(5);
      this.update_viewing_audience(5);
    },
    data: () => ({
        main_logo: logo,

        available_audiences: [],
        configuration_setups: {},
        audience_data: {},

        editing_audience_info: {},
        editing_audience_configuration: {},
        viewing_audience_info: {},
        viewing_graph_index: 0,
        viewing_audience_data: {},

        audience_tab: 'view'

    }),
    methods:{
      update_editing_audience: function(audience_id){
        let editing_aud = {};
        for(let aud of this.available_audiences){
          if(aud.audience_id == audience_id){
            editing_aud = aud;
            break;
          }
        }
        this.editing_audience_info = editing_aud;
        this.editing_audience_configuration = this.configuration_setups[audience_id];
      },
      update_viewing_audience: function(audience_id){
        let viewing_aud = {};
        for(let aud of this.available_audiences){
          if(aud.audience_id == audience_id){
            viewing_aud = aud;
            break;
          }
        }
        this.viewing_audience_info = viewing_aud;
        this.viewing_audience_data = this.audience_data[audience_id];
        this.audience_tab = 'view';
      },
      build_audience: async function(audience_id){
        console.log(audience_id)
        let building_aud = -1;
        for(let aud in this.available_audiences){
          if(this.available_audiences[aud].audience_id == audience_id){
            building_aud = aud;
            break;
          }
        }
        console.log(this.available_audiences[building_aud])
        this.available_audiences[building_aud]['audience_state'] = 'building';
        console.log(this.configuration_setups[audience_id]);
        console.log(this.configuration_setups[building_aud]);
        
        // let token = await this.$auth.getTokenSilently();
        // await api_caller.build_audience(token, this.$project.get_val());

        setTimeout( () => this.available_audiences[building_aud]['load_state'] = 10, 10000)
        setTimeout( () => this.available_audiences[building_aud]['load_state'] = 50, 70000)
        setTimeout( () => this.available_audiences[building_aud]['load_state'] = 75, 100000)
        setTimeout( () => this.available_audiences[building_aud]['load_state'] = 99, 125000)
        setTimeout( () => this.available_audiences[building_aud]['audience_state'] = 'built', 100000)
      },
      graphIndexOnChange: function(value) {
        this.viewing_graph_index = value;
      }
    }
}
</script>

<style scoped>
  .page-title{
      font-family: 'Poppins' !important;
      font-weight: 800 ;
      font-size: 2em;
  }
  .audience-tool-navigation-bar{
      font-family: 'Poppins' !important;
      font-weight: 400 !important;
      font-size: 0.9em !important;
  }
  /deep/.v-window__container{
    height: 100% !important;
  }
</style>