<template>
    <v-container class="ma-0 pa-0" style="height: 100%; width: 100%">
        <v-card style="height: 100%; width: 100%" flat>
            <v-row style="height: 100%; width: 100%" class="ma-0 pa-0" align="center">
                <v-col cols="1">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon class="ma-0 pa-0" color="black" disabled>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <TooltipDescription title="Create A New Audience"></TooltipDescription>
                    </v-tooltip>
                </v-col>
                <v-col cols="5">
                    <v-select class="audience_load_bar_title" v-model="editing_audience_id" :items="available_audiences" @change="update_scenario($event)" :item-value="'audience_id'" :item-text="'audience_name'" persistent-hint hint="Select An Audience"  return-object>
                        <template v-slot:item="{item, attrs, on }">
                            <v-row class="ma-3 pa-0" v-on="on" v-bind="attrs">
                                <v-col cols="12" class="ma-0 pa-0">
                                    <span class="audience_load_bar_title">{{item.audience_name}}</span>
                                </v-col>
                                <v-col cols="12" class="ma-0 pa-0 pl-3">
                                    <span class="audience_load_bar_text">{{item.audience_id == viewing_audience_id.audience_id ? 'viewing' : item.audience_state}}</span>
                                </v-col>
                            </v-row>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="2" v-if="available_build">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <v-btn class="audience_runner_btn" :disabled="!available_build" @click="build_audience">
                                    <v-icon class="ma-0 pa-0 px-1" color="black">mdi-hammer-wrench</v-icon>
                                    Build
                                </v-btn>
                            </div>
                        </template>
                        <TooltipDescription title="Build Your Audience"
                                            desc_2="When you are finished designing your audience, click this button to calculate and view your audience statistics."></TooltipDescription>
                    </v-tooltip>
                </v-col>
                <v-col cols="5" v-if="editing_audience_state == 'building'">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <v-progress-linear bottom rounded :value="build_progress" :buffer-value="build_buffer" color="#f3a815" height="36" class="audience_load_bar_main_text">
                                    <span v-if="editing_audience_state == 'building'">{{build_progress}}%</span>
                                    <span v-else-if="editing_audience_state == 'designing'"></span>
                                    <span v-else-if="editing_audience_state == 'built'">Built</span>
                                </v-progress-linear>
                            </div>
                        </template>
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" class="ma-0 pa-0">
                                <span class="tooltip_description">Audience Run Status</span>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0">
                                <span class="audience_load_bar_title">Demo 3</span>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0 pl-4">
                                <span class="audience_load_bar_text">{{progress_statement}}</span>
                            </v-col>
                        </v-row>
                    </v-tooltip>
                </v-col>   
                <v-col cols="2" v-if="available_view">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <v-btn class="audience_runner_btn" @click="view_scenario_stats" :disabled="!available_view">
                                    <v-icon class="ma-0 pa-0 px-1" color="black">mdi-table-eye</v-icon>
                                    View
                                </v-btn>
                            </div>
                        </template>
                        <TooltipDescription title="View Your Audience"
                                            desc_2="Now that your audience is built, click this button to view your audience on the Stats page."
                                            desc_3="When viewing an audience, You can still design and build your other audiences."></TooltipDescription>
                    </v-tooltip>
                </v-col>   
                <v-col cols="2" v-if="available_view">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <v-btn class="audience_runner_btn" @click="edit_audience" :disabled="!available_view">
                                    <v-icon class="ma-0 pa-0 px-1" color="black">mdi-pencil-ruler</v-icon>
                                    Design
                                </v-btn>
                            </div>
                        </template>
                        <TooltipDescription title="Continue Designing"
                                            desc_2="Want to make updates to your audience?"
                                            desc_3="Overwrite this audience or create a new version."></TooltipDescription>
                    </v-tooltip>
                </v-col>  
                <v-col cols="2" v-if="available_save">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <v-btn class="audience_runner_btn" :disabled="!available_save">
                                    <v-icon class="ma-0 pa-0 px-1" color="black">mdi-content-save</v-icon>
                                    Save
                                </v-btn>
                            </div>
                        </template>
                        <span class="tooltip_description">Save Your Audience</span>
                    </v-tooltip>
                </v-col>   
                <v-spacer></v-spacer>     
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import TooltipDescription from '@/components/Utility/TooltipDescription.vue';
export default{
    name: 'AudienceSelectionRunner',
    props: [
        'selected_editing_audience',
        'selected_viewing_audience',
        'audiences'
    ],
    components: {
        TooltipDescription
    },
    mounted(){
        this.available_audiences = this.audiences;
        this.editing_audience_id = this.selected_editing_audience;
        this.viewing_audience_id = this.selected_viewing_audience;

        this.editing_audience_state = this.selected_editing_audience.audience_state;
        this.editing_auience_type = this.selected_editing_audience.audience_type;
        this.update_build_progress(this.selected_editing_audience.load_state);
        this.update_available_actions();
    },
    watch: {
        selected_editing_audience: {
            handler: function() {
                this.editing_audience_state = this.selected_editing_audience.audience_state;
                this.editing_auience_type = this.selected_editing_audience.audience_type;
                this.update_build_progress(this.selected_editing_audience.load_state);
                this.update_available_actions();
            },
            deep: true
        },
        selected_viewing_audience: {
            handler: function(updated_audience) {
                this.viewing_audience_id = updated_audience;
            },
            deep: true
        }
    },
    data(){
        return {
            build_progress: 0,
            build_buffer: 100,
            progress_statement: 0,

            available_build: false,
            available_view: false,
            available_save: false,


            available_audiences: [
            ],
            editing_audience_id: 0,
            viewing_audience_id: 0,
            editing_audience_state: '',
            editing_auience_type: ''
        }
    },
    methods: {
        update_scenario(item){
            this.$emit('editing_audience',item.audience_id);
        },
        build_audience(){
            this.$emit('build_audience',this.editing_audience_id.audience_id);
        },
        view_scenario_stats: function(){
            this.$emit('viewing_audience',this.editing_audience_id.audience_id);
        },
        edit_audience(){

        },
        update_available_actions(){
            let state = this.editing_audience_state;
            this.available_build = false;
            this.available_view = false;
            switch(state){
                case 'built':
                    this.available_view = true;
                    break;
                case 'viewing':
                    this.available_build = true;
                    break
                case 'building':
                    break;
                case 'designing':
                    this.available_build = true;
                    break;
            }
        },
        update_build_progress(progress){
            if(this.editing_audience_state == 'building'){
                this.build_progress = progress;
                if(this.build_progress < 10){
                    this.progress_statement = this.build_progress + "% - Assembling Audience.";
                }
                else if(this.build_progress < 50){
                    this.progress_statement = this.build_progress + "% - Finding Tangental Audiences.";
                }
                else if(this.build_progress < 75){
                    this.progress_statement = this.build_progress + "% - Build WOM Models";
                }
                else{
                    this.progress_statement = this.build_progress + "% - Assembling Report";
                }
            }
            else if(this.editing_audience_state == 'built'){
                this.build_progress = 100;
                this.progress_statement = "Your Audience has been built, to view your audience click the view button on the right."
            }
            else{
                this.build_progress = 0;
                this.progress_statement = "When you are finished building your audience click the build button on the left to build your audience and view results."
            }
        }
    }
}
</script>

<style>
.audience_runner_btn{
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    text-overflow: ellipsis;
    width: 100%;
}
.audience_load_bar_main_text{
    font-family: 'Poppins' !important;
    font-weight: 600 !important;
}
.audience_load_bar_title{
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}
.audience_load_bar_text{
    font-family: 'Poppins' !important;
    font-weight: 200 !important;
}
.tooltip_description{
    font-family: 'Poppins' !important;
    font-weight: 200;
}
.tooltip_title{
    font-family: 'Poppins' !important;
    font-weight: 400;
}

</style>