<template>
    <v-card style="height: 100%; max-height: 100%; overflow-y: scroll;" class="ma-0 pa-0">
        <v-row class="ma-0 pa-0" style="width: 100%" justify="center">
        <v-col cols="11">
          <span class="report-subtitle">Product Correlation</span>
        </v-col>
        <v-col cols="1">
          <v-btn
            icon
            @click="
              response_curve_comments = true;
              response_curve_col_size = 9;
            "
          >
            <v-icon>mdi-comment-edit-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" class="ma-0 pa-0">
          <v-divider class="mx-12"></v-divider>
        </v-col>
        <v-col :cols="response_curve_col_size" class="ma-0 pa-0">
          <v-row class="ma-0 pa-0" justify="center">
          </v-row>
        </v-col>
        <v-col cols="3" v-if="response_curve_comments">
          <v-row class="ma-0 pa-0">
            <v-col cols="12">
              <v-row class="ma-0 pa-0">
                <v-spacer></v-spacer>
                <v-col cols="1">
                  <v-btn
                    icon
                    style="height: 25px; width: 25px"
                    @click="
                      response_curve_comments = false;
                      response_curve_col_size = 12;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12"> </v-col>
            <v-col cols="12">
              <v-textarea
                clearable
                clear-icon="mdi-close-circle"
                label="Text"
                value="This is clearable text."
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </template>
  
  <script>
  
  // //Demo Data
  // import chartData from "@/input_data/performance_chart_data.json";
  // import chartDataTest from "@/input_data/Performance/MMM_daily.json";
  
  // Configs
  import pieChartConfig from "@/configs/Performance/performance_pie_chart_config.json";
  import columnChartConfig from "@/configs/Performance/performance_roi_contribution_overview_config.json";
  import stackedChartConfig from "@/configs/Performance/performance_spend_impr_contribution_stacked_chart_config.json";
  import responseCurveConfig from "@/configs/Performance/performance_response_curve_config.json";
  import timeSeriesConfig from "@/configs/Performance/performance_time_series_chart_config.json";
//   import indicator_list from "@/configs/Performance/available_indicators.json";
  
  import responseCurveData from "@/input_data/Performance/PerformanceResponseCurve.json";
  
  // import {
  //   format_data_to_gchart,
  //   filter_dataset,
  //   calculate_moving_average,
  //   calculate_moving_average_cross,
  //   calculate_bollinger_band,
  // } from "@/components/Performance/PerformanceStats/statistics.js";
  
  // let screenHeight = window.innerHeight
  // chartConfig["mainChartOptions"]["height"] =  screenHeight * 0.50
  // chartConfig["miniChartOptions"]["height"] =  screenHeight * 0.30
  
  export default {
    name: "CorrelationMatrix",
    props: [
      'data_version'
    ],
    components: {
    },
    methods: {
      reload_graph: function () {
        this.graph_visible = false;
        setTimeout(() => (this.graph_visible = true), 10);
      },
  
      onCommentSubmit: function (section) {
        if (this.report_comments[section]) {
          let newCommentContent = null
          
          switch(section){
            case 'spend_impr_contribution_overview':
              newCommentContent = this.new_spend_impr_contribution_overview_comment
              this.new_spend_impr_contribution_overview_comment = null;
              break;
            case 'spend_impr_contribution_environmental':
              newCommentContent = this.new_spend_impr_contribution_environmental_comment
              this.new_spend_impr_contribution_environmental_comment = null;
              break;
            case 'roi_contribution':
              newCommentContent = this.new_roi_contribution_comment
              this.new_roi_contribution_comment = null;
              break;
          }
  
          if (newCommentContent) {
            let newComment = {
              name: this.$user_data["first_name"] + " " + this.$user_data["last_name"],
              content: newCommentContent,
              timestamp: new Date().toLocaleString()
            };
            this.report_comments[section].push(newComment);
          }
        }
      },
  
      getUserInitial: function (name) {
        let initial = "";
        if (name) {
          let splitted = name.split(" ");
          initial = splitted[0].substring(0, 1).toUpperCase();
  
          if (splitted.length > 1) {
            initial += splitted[splitted.length - 1].substring(0, 1).toUpperCase();
          }
        }
        return initial;
      },
    },
  
    data: () => ({
      current_graph: 1,
  
      legend_points: ["TV", "Radio", "OOH", "Social", "Programmatic"],
      legend_colors: ["blue", "red", "orange", "green", "purple"],
  
      pie_chart_config: pieChartConfig,
      pie_chart_data: {
        spend: [
          ["Channel", "Value"],
          ["OOH", 243878.99640000015],
          ["Programmatic", 1240689.6640767402],
          ["Radio", 642524.8727247573],
          ["Social", 1066313.48202],
          ["TV", 229755.61916450714],
        ],
        impressions: [
          ["Channel", "Value"],
          ["OOH", 52093168.3037037],
          ["Programmatic", 452739714.4886184],
          ["Radio", 38493875.263339154],
          ["Social", 124303136.00000001],
          ["TV", 6618915.297047674],
        ],
        attribution: [
          ["Channel", "Value"],
          ["OOH", 159206.99076311823],
          ["Programmatic", 566935.4438899586],
          ["Radio", 1464894.3984722616],
          ["Social", 187610.30798707745],
          ["TV", 916780.8332305193],
        ],
      },
      spend_impr_contribution_overview_comments: false,
      spend_impr_contribution_col_size: 12,
  
      spend_impr_contribution_environmental_config: stackedChartConfig,
      spend_impr_contribution_data: {
        Q1: [
          ["stat", "OOH", "Programmatic", "Radio", "Social", "TV"],
          [
            "impressions",
            52093168.3037037,
            452739714.4886184,
            38493875.263339154,
            124303136.00000001,
            6618915.297047674,
          ],
          [
            "sales_attribution",
            159206.99076311823,
            566935.4438899586,
            1464894.3984722616,
            187610.30798707745,
            916780.8332305193,
          ],
          [
            "spend",
            243878.99640000015,
            1240689.6640767402,
            642524.8727247573,
            1066313.48202,
            229755.61916450714,
          ],
        ],
        Q2: [
          ["stat", "OOH", "Programmatic", "Radio", "Social", "TV"],
          [
            "impressions",
            52093168.3037037,
            452739714.4886184,
            38493875.263339154,
            124303136.00000001,
            6618915.297047674,
          ],
          [
            "sales_attribution",
            159206.99076311823,
            566935.4438899586,
            1464894.3984722616,
            187610.30798707745,
            916780.8332305193,
          ],
          [
            "spend",
            243878.99640000015,
            1240689.6640767402,
            642524.8727247573,
            1066313.48202,
            229755.61916450714,
          ],
        ],
        Q3: [
          ["stat", "OOH", "Programmatic", "Radio", "Social", "TV"],
          [
            "impressions",
            52093168.3037037,
            452739714.4886184,
            38493875.263339154,
            124303136.00000001,
            6618915.297047674,
          ],
          [
            "sales_attribution",
            159206.99076311823,
            566935.4438899586,
            1464894.3984722616,
            187610.30798707745,
            916780.8332305193,
          ],
          [
            "spend",
            243878.99640000015,
            1240689.6640767402,
            642524.8727247573,
            1066313.48202,
            229755.61916450714,
          ],
        ],
        Q4: [
          ["stat", "OOH", "Programmatic", "Radio", "Social", "TV"],
          [
            "impressions",
            52093168.3037037,
            452739714.4886184,
            38493875.263339154,
            124303136.00000001,
            6618915.297047674,
          ],
          [
            "sales_attribution",
            159206.99076311823,
            566935.4438899586,
            1464894.3984722616,
            187610.30798707745,
            916780.8332305193,
          ],
          [
            "spend",
            243878.99640000015,
            1240689.6640767402,
            642524.8727247573,
            1066313.48202,
            229755.61916450714,
          ],
        ],
      },
  
      report_comments: {
        spend_impr_contribution_overview: [
          {
            name: "Taylor Grant",
            content: "Comment 1",
            timestamp: "01/01/2024 00:00:00 AM",
          },
          {
            name: "Sam Rowe",
            content: "Comment 2",
            timestamp: "02/01/2024 00:00:00 AM",
          },
          {
            name: "Katarina Sacka",
            content: "Comment 3",
            timestamp: "03/01/2024 00:00:00 AM",
          },
        ],
        spend_impr_contribution_environmental: [
          {
            name: "Taylor Grant",
            content: "environmental comment 1",
            timestamp: "01/01/2024 00:00:00 AM",
          },
          {
            name: "Sam Rowe",
            content: "environmental comment 2",
            timestamp: "02/01/2024 00:00:00 AM",
          },
        ],
        roi_contribution: [
          {
            name: "Taylor Grant",
            content: "roi contribution comment 1",
            timestamp: "01/01/2024 00:00:00 AM",
          },
          {
            name: "Katarina Sacka",
            content: "roi contribution 2",
            timestamp: "03/01/2024 00:00:00 AM",
          },
        ],
        time_series: [
          {
            name: "Taylor Grant",
            content: "time series comment 1",
            timestamp: "01/01/2024 00:00:00 AM",
          },
          {
            name: "Sam Rowe",
            content: "time series comment 2",
            timestamp: "03/01/2024 00:00:00 AM",
          },
        ],
        response_curve: [
          {
            name: "Sam Rowe",
            content: "response curve comment 1",
            timestamp: "01/01/2024 00:00:00 AM",
          },
        ],
      },
  
      spend_impr_contribution_environmental_col_size: 12,
      spend_impr_contribution_environmental_comments: false,
  
      time_series_config: timeSeriesConfig,
      time_series_data: [
        ["Year", "Sales", "Expenses", "", "", ""],
        ["2013", 1000, 400, 200, 400, 350],
        ["2014", 1170, 460, 500, 500, 450],
        ["2015", 660, 1120, 300, 700, 500],
        ["2016", 1030, 540, 1000, 300, 700],
      ],
      time_series_col_size: 12,
      time_series_comments: false,
  
      roi_contribution_config: columnChartConfig,
      roi_data: [
        ["Channel", "ROI"],
        ["TV", 0.1],
        ["OOH", 0.2],
        ["Digital", 0.5],
        ["Etc", 1],
      ],
      roi_contribution_col_size: 12,
      roi_contribution_comments: false,
  
      response_curve_config: responseCurveConfig,
      response_curve_data: responseCurveData,
      response_curve_col_size: 12,
      response_curve_comments: false,
    }),
    mounted() {},
    watch: {},
    computed: {},
  };
  </script>
  
  <style scoped>
  .main-page-title {
    /* line-height: 5em; */
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1.5em;
  }
  .report-subtitle {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 1.5em;
  }
  
  .graph-title {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 0.9em;
  }
  
  .report-version {
    font-family: "Poppins";
    font-weight: 600;
    align-items: center;
    /* font-size: 0.5em; */
    /* width: 50px; */
  }
  
  .performanceChart {
    height: 100%;
    width: 100%;
  }
  
  .chartHeader {
    .chartHeaderText {
      font-family: "Poppins" !important;
      font-weight: 600;
      font-size: 1.5em;
    }
  
    display: flex;
  }
  
  .toolBar {
    float: right;
  }
  
  .toolButton {
    padding: 0 32px;
    width: 50px;
    color: black;
  }
  
  ::v-deep .v-overlay__content {
    position: absolute;
    right: 0;
    height: 100%;
    width: 30%;
  }
  
  .overlayField {
    padding: 0 16px;
  }
  
  .overlayButton {
    float: right;
  }
  
  .chartWithOverlay {
    /* position: relative; */
    width: 100%;
    /* height: 500px; */
  }
  .miniChartWithOverlay {
    /* position: relative; */
    width: 100%;
    height: 75px;
  }
  
  .metricToggleOverlay {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 12%;
    left: 90px;
  }
  
  .metricToggleRow {
    margin-left: 0;
    margin-right: auto;
  }
  .metric_toolbar_title {
    font-weight: 800 !important;
  }
  .metric_toolbar_subtitle {
    font-weight: 600 !important;
  }
  .metric_toolbar_metric_title {
    line-height: 1em;
    font-weight: 200 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: calc(80%);
    text-align: left;
    overflow: hidden;
  }
  
  .tooltip_description {
    font-family: "Poppins" !important;
    font-weight: 200;
  }
  .long_description {
    font-family: "Poppins" !important;
  }
  
  .expansionPanel {
    overflow: none;
  }
  
  .reportCommentContent {
    max-height: 350px;
    overflow-y: auto;
  }
  
  .reportCommentInputField {
    float: right;
    width: 90%;
  }
  </style>
  