import { getInstance } from './index';

export const authGuard = (to, from, next) => {
  const authService = getInstance();
  // const allowedOrganizations = ['org1-id', 'org2-id'];

  const fn = async () => {
    // // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
    //   try {
    //     const user = await authService.user; // Get the user's profile

    //     // Assume organization ID is in the user's profile as `org_id` (adjust this as needed)
    //     const userOrg = user.org_id; 
    //     // console.log(userOrg)

      //   // Check if the user's organization is allowed
      //   if (allowedOrganizations.includes(userOrg)) {
      //     return next(); // Continue to the route if the organization is allowed
      //   } else {
      //     // Redirect to an unauthorized page or show an error message
      //     return next({ path: '/unauthorized' });
      //   }
      // } catch (error) {
      //   console.error('Error fetching user profile:', error);
      //   return next({ path: '/unauthorized' }); // Handle any errors with fetching the user
      // }
      return next();
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn();
    }
  });
};