import { initializeApp } from 'firebase/app';
// import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyC5r14OssAABak1xCBIMn2TKmwSrM0wkHc",
    authDomain: "docma-enterprise.firebaseapp.com",
    projectId: "docma-enterprise",
    storageBucket: "docma-enterprise.appspot.com",
    messagingSenderId: "487803870087",
    appId: "1:487803870087:web:b466ccd9642506b3061122"
  };

const firebase_app =  initializeApp(firebaseConfig);

export default firebase_app