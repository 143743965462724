<template>
  <v-container class="ma-0 pa-0" style="height: 100%; width: 100%">
    <v-card style="height: 100%; width: 100%" flat>
      <v-row style="position: absolute; width: 100%;">
        <v-spacer></v-spacer>
        <v-col cols="auto" class="ma-0 pa-1">
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span class="graphIcon" v-on="on" @click="overlay = true">
                  <v-icon> mdi-cog</v-icon>
                </span>
              </template>
              <TooltipDescription
                title="Social Network Graph Settings"
              ></TooltipDescription>
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="auto" class="ma-0 pa-1">
          <div>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <span class="graphIcon" v-on="on">
                  <v-icon> mdi-information-variant  </v-icon>
                </span>
              </template>
              <v-row class="ma-0 pa-0" style="max-width: 30vw;">
                <v-col cols="12" class="ma-0 pa-0 py-2">
                  <span class="tooltip_title">Social Network Graph</span>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <span class="tooltip_description"
                    >The social network graph is a visual representation of how word of
                    mouth travels through audiences.</span
                  >
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <span class="tooltip_description"
                    >View how awarness increases and tune your model to best represent
                    your situation at the design phase.</span
                  >
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-row class="ma-0 pa-0">
                    <v-col cols="8">
                      <v-simple-table dark style="background-color: #00000000">
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td><v-icon color="#0bb4ff">mdi-circle</v-icon></td>
                              <td class="tooltip_title">Unaware</td>
                            </tr>
                            <tr>
                              <td><v-icon color="#e60049">mdi-circle</v-icon></td>
                              <td class="tooltip_title">Aware</td>
                            </tr>
                            <tr>
                              <td><v-icon color="#50e991">mdi-circle</v-icon></td>
                              <td class="tooltip_title">Converted</td>
                            </tr>
                            <tr>
                              <td><v-icon color="#9b19f5">mdi-circle</v-icon></td>
                              <td class="tooltip_title">Recovered</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-col>
              </v-row>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-overlay :absolute="true" :value="overlay">
            <v-card style="height:100%">
              <v-card-text style="height:100%">
                <v-subheader>Node Density</v-subheader>
                <v-card-text style="height:75%">
                  <v-slider
                    v-model="nodeDensity"
                    color="blue"
                    :max="3"
                    step="1"
                    ticks="always"
                    tick-size="4"
                    append-icon="mdi-arrow-up-drop-circle-outline"
                    prepend-icon="mdi-arrow-down-drop-circle-outline"
                  ></v-slider>
                </v-card-text>
                <v-card-text class="overlayButtonContainer">
                  <v-btn color="success" @click="confirmOnClick" class="overlayButton">
                    Confirm
                  </v-btn>
                  <v-btn @click="overlay = false" class="overlayButton"> Cancel </v-btn>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-overlay>
          <v-img :src="require(`${graph_url}`)" contain maxHeight="50vh">
            <!-- circular progress visual to show image loading -->
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="blue"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-spacer v-if="outwards_only"></v-spacer>
        <v-col :cols="slider_size" class="ma-0 pa-1">
          <v-slider
            v-model="sliderValue"
            color="primary"
            :max="slider_notches"
            step="1"
            ticks="always"
            tick-size="4"
            hide-details
            @end="slider_on_change"
          >
          </v-slider>
        </v-col>
        <v-spacer v-if="inwards_only"></v-spacer>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col cols="6" class="ma-0 pa-1">
          <v-row class="ma-0 pa-0">
            <v-spacer></v-spacer>
            <v-col cols="auto" class="ma-0 pa-0">
              <v-btn plain block @click="slider_on_change((sliderValue -= 1))">
                <span class="slider-description">Consolidate</span>
                <v-icon>mdi-arrow-left-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="ma-0 pa-1">
          <v-row class="ma-0 pa-0">
            <v-col cols="auto" class="ma-0 pa-0 mr-3">
              <v-btn plain block @click="slider_on_change((sliderValue += 1))">
                <v-icon>mdi-arrow-right-thick</v-icon>
                <span class="slider-description">Expand</span>
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-spacer></v-spacer>
        <v-col cols="auto" class="ma-0 pa-1">
          <span class="slider-description">AI Scaling Recommendation</span>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import TooltipDescription from "@/components/Utility/TooltipDescription.vue";
export default {
  name: "AudienceGraph",
  props: ["audience_info", "audience_data_graph_imgs"],
  components: {
    TooltipDescription,
  },
  mounted() {
    this.set_slider_notches();
  },
  watch: {
    audience_data_graph_imgs: {
      handler: function () {
        this.set_slider_notches();
        this.sliderValue = 0;
        this.slider_on_change(0);
      },
      deep: true,
    },
    audience_info: {
      handler: function (updated_audience) {
        this.sliderValue = parseInt(updated_audience["graph_start"]);
        console.log(this.sliderValue);
        console.log(this.audience_data_graph_imgs.length);
        if (this.sliderValue == this.audience_data_graph_imgs.length - 1) {
          this.inwards_only = true;
          this.outwards_only = false;
          this.slider_size = 6;
        } else if (this.sliderValue == 0) {
          this.inwards_only = false;
          this.outwards_only = true;
          this.slider_size = 6;
        } else {
          this.inwards_only = false;
          this.outwards_only = false;
          this.slider_size = 12;
        }
        this.slider_on_change(this.sliderValue);
      },
      deep: true,
    },
  },
  data: () => ({
    slider_notches: 0,
    graph_url: null,
    sliderValue: 0,
    overlay: false,
    absolute: false,
    opacity: 0.5,
    nodeDensity: 1,
    inwards_only: true,
    outwards_only: true,
    slider_size: 6,
  }),
  methods: {
    set_slider_notches: function () {
      if (this.audience_data_graph_imgs instanceof Array) {
        this.slider_notches = this.audience_data_graph_imgs.length - 1;
      } else {
        this.slider_notches = 0;
      }
    },
    slider_on_change: function (value) {
      let val = value;
      if (this.sliderValue < 0) {
        val = 0;
      } else if (this.sliderValue > this.audience_data_graph_imgs.length - 1) {
        val = this.audience_data_graph_imgs.length - 1;
      }
      this.sliderValue = val;
      if (
        this.audience_data_graph_imgs instanceof Array &&
        this.audience_data_graph_imgs.length > 0
      ) {
        this.graph_url = this.audience_data_graph_imgs[val];
        this.$emit("graphIndexChanged", val);
      } else {
        this.graph_url = null;
      }
    },
    confirmOnClick: function () {
      this.$emit("nodeDensityChanged", this.nodeDensity);
      this.overlay = false;
    }
  }
}
</script>

<style scoped>
.slider-description {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 1em;
  color: grey;
  text-align: center;
}

.graphIcon {
  color: grey;
  cursor: pointer;
}

.overlayButtonContainer {
  display: flex;

  .overlayButton {
    flex: 1;
  }
}

::v-deep .v-overlay__content {
  height: 100%;
  width: 100%;
}

.tooltip_description {
  font-family: "Poppins" !important;
  font-weight: 200;
  color: "white" !important;
}
.tooltip_title {
  font-family: "Poppins" !important;
  font-weight: 400;
}
</style>
