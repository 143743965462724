function format_data_to_gchart(chart_data, rows) {
    let formatted_data = [];
    let add_null = false;
    if(rows.length < 2){
        add_null = true;
    }
    for(let data_row of chart_data){
        let tmp_formatted_row = [];
        let rdx = 0;
        for(let row of rows){
            if(rdx == 0){
                let d = data_row[row];
                d = d.split('-')
                tmp_formatted_row.push({"v": new Date(Number(d[0]), Number(d[1]) -1, Number(d[2])), "f": null});
            }
            else{
                tmp_formatted_row.push({"v": data_row[row], "f": null});
            }
            if(add_null == true){
                tmp_formatted_row.push({"v": null, "f": null});
            }
            rdx += 1;
        }
        tmp_formatted_row = {"c": tmp_formatted_row};
        formatted_data.push(tmp_formatted_row);
    }
    return formatted_data;
}

function filter_dataset(chart_data, data_point, filter_values){
    let updated_data = [];
    let val = 0;
    chart_data.forEach((row) => {
        val = row[data_point];
        if(filter_values.includes(val)){
            updated_data.push(row);
        }
    })
    return updated_data;
}

function set_marker_line(chart_data, data_point, selected_col, output_marker_line, output_marker_point){
    let updated_data = [];
    let val = chart_data[selected_col][data_point]
    chart_data.forEach((row, index) => {
        let tmp_row = {...row}
        if(index == selected_col) {
            tmp_row[output_marker_point] = val
        } else {
            tmp_row[output_marker_point] = null
        }
        tmp_row[output_marker_line] = val
        updated_data.push(tmp_row);
    })
    return updated_data;
}

function calculate_simple_moving_average(chart_data, ma_length, data_point, output_name){
    let updated_data = [];
    let sevenDaysSum = 0;
    let val = 0;
    let movingAvgValue = 0;
    chart_data.forEach((row, index) => {

        val = row[data_point]

        sevenDaysSum += val
        if(index < ma_length) {
            movingAvgValue = sevenDaysSum / (index + 1)
            // movingAvgValue = null;
        } else {
            sevenDaysSum -= chart_data[index - ma_length][data_point];
            movingAvgValue = sevenDaysSum / ma_length;
        }
        
        let tmp_row = {...row}
        tmp_row[output_name] = movingAvgValue
        updated_data.push(tmp_row);
    })
    return updated_data;
}

function calculate_exponential_moving_average(chart_data, ma_length, data_point, output_name){
    let updated_data = [];
    let sevenDaysSum = 0;
    let val = 0;
    let K = 2 / (ma_length + 1);
    let movingAvgValue = 0;
    let movingAvgPrev = 0;
    chart_data.forEach((row, index) => {

        val = row[data_point]

        sevenDaysSum += val
        movingAvgPrev = movingAvgValue;
        if(index < ma_length) {
            movingAvgValue = sevenDaysSum / (index + 1)
            // movingAvgValue = null;
        } else {
            // sevenDaysSum -= chart_data[index - ma_length][data_point];
            // movingAvgValue = sevenDaysSum / ma_length;

            movingAvgValue = (val * K) + (movingAvgPrev * (1 - K))
        }
        
        let tmp_row = {...row}
        tmp_row[output_name] = movingAvgValue
        updated_data.push(tmp_row);
    })
    return updated_data;
}

function calculate_average_true_range(chart_data, ma_length, data_point, output_name){
    let updated_data = [];
    let sevenDaysSum = 0;
    let val = 0;
    let val_prev = 0;
    let TR = 0;
    let ATR_prev = 0;
    let movingAvgValue = 0;
    chart_data.forEach((row, index) => {

        val = row[data_point]
        TR = Math.max(val, val_prev) - Math.min(val,val_prev)
        val_prev = val;

        sevenDaysSum += TR;
        if(index < ma_length) {
            movingAvgValue = sevenDaysSum / (index + 1)
            ATR_prev = movingAvgValue;
        } else {
            movingAvgValue = (ATR_prev * (ma_length - 1) + TR) / ma_length;
            ATR_prev = movingAvgValue;
        }
        
        let tmp_row = {...row}
        tmp_row[output_name] = movingAvgValue
        updated_data.push(tmp_row);
    })
    return updated_data;
}

function calculate_keltner_channels(chart_data, data_point, output_name_base, output_name_lower, output_name_upper){
    let tmp_data = calculate_average_true_range(chart_data, 20, data_point, "k_atr");
    tmp_data = calculate_exponential_moving_average(tmp_data, 20, data_point, "k_ema");

    let updated_data = [];
    let bollinger_lower = 0;
    let bollinger_upper = 0;
    tmp_data.forEach((row, index) => {

        bollinger_lower = tmp_data[index]['k_ema'] + (2 * tmp_data[index]['k_atr']);
        bollinger_upper = tmp_data[index]['k_ema'] - (2 * tmp_data[index]['k_atr']);
        
        let tmp_row = {...row};
        tmp_row[output_name_base] = tmp_data[index]['k_ema'];
        tmp_row[output_name_lower] = bollinger_lower;
        tmp_row[output_name_upper] = bollinger_upper;
        delete tmp_row['k_atr'];
        delete tmp_row['k_ema'];
        updated_data.push(tmp_row);
    })
    return updated_data;
}

function calculate_MACD(chart_data, data_point, output_name){
    let tmp_data = calculate_exponential_moving_average(chart_data, 12, data_point, "macd_ema_12");
    tmp_data = calculate_exponential_moving_average(tmp_data, 26, data_point, "macd_ema_26");

    let updated_data = [];
    let macd = 0;
    tmp_data.forEach((row, index) => {

        macd = tmp_data[index]['macd_ema_12'] - tmp_data[index]['macd_ema_26'];
        
        let tmp_row = {...row};
        tmp_row[output_name] = macd
        delete tmp_row['macd_ema_12'];
        delete tmp_row['macd_ema_26'];
        updated_data.push(tmp_row);
    })
    return updated_data;
}

function calculate_simple_moving_average_cross(chart_data, data_point, fast_ma_length, slow_ma_length, output_name_cross, output_name_fast_ma, output_name_slow_ma) {
    let updated_data = [];
    let crossSignal = [0]

    // add both slow and fast moving avg tied to the cross metric
    let temp_data = []
    temp_data = calculate_simple_moving_average(chart_data, fast_ma_length, data_point, output_name_fast_ma)
    temp_data = calculate_simple_moving_average(temp_data, slow_ma_length, data_point, output_name_slow_ma)

    for(let i = 1; i < chart_data.length; i++) {
      if(temp_data[i][output_name_fast_ma] > temp_data[i][output_name_slow_ma]) {
        crossSignal.push(1) 
      } else {
        crossSignal.push(0) 
      }

      let tmp_row = {...temp_data[i]}
      let crossValue = null
      if(crossSignal[i] != crossSignal[i-1]) {
        // set style of the cross pointer
        // crossValue = 'point { size: 10; shape-type: star; shape-sides: 4; fill-color: blue; }'
        crossValue = temp_data[i][output_name_slow_ma]
      }
      tmp_row[output_name_cross] = crossValue
      updated_data.push(tmp_row);
    }
    return updated_data;
}

function calculate_moving_standard_deviation(chart_data, sd_length, data_point, output_name){

    let tmp_data = calculate_simple_moving_average(chart_data,sd_length,data_point,"ma_tmp");

    let updated_data = [];
    let SDValue = 0;
    let SDSum = 0;
    tmp_data.forEach((row, index) => {

        if(index < sd_length) {
            SDSum = 0
            for(let jdx = (0); jdx <= index; jdx ++){
                SDSum += (tmp_data[jdx][data_point] - tmp_data[index]['ma_tmp'])**2;
            }
            SDValue = Math.sqrt(SDSum / sd_length);
            // SDValue = null;
        } else {
            SDSum = 0;
            for(let jdx = (index - sd_length + 1); jdx <= index; jdx ++){
                SDSum += (tmp_data[jdx][data_point] - tmp_data[index]['ma_tmp'])**2;
            }
            SDValue = Math.sqrt(SDSum / sd_length);
        }
        
        let tmp_row = {...row};
        tmp_row[output_name] = SDValue;
        delete tmp_row['ma_tmp'];
        updated_data.push(tmp_row);
    })
    return updated_data;
}

function calculate_bollinger_band(chart_data, data_point, output_name_base, output_name_lower, output_name_upper){
    let tmp_data = calculate_simple_moving_average(chart_data, 20, data_point, "bb_ma");
    tmp_data = calculate_moving_standard_deviation(tmp_data, 20, data_point, "bb_sd");

    let updated_data = [];
    let bollinger_lower = 0;
    let bollinger_upper = 0;
    tmp_data.forEach((row, index) => {

        if(index < 20) {
            bollinger_lower = tmp_data[index]['bb_ma'] + (2 * tmp_data[index]['bb_sd']);
            bollinger_upper = tmp_data[index]['bb_ma'] - (2 * tmp_data[index]['bb_sd']);
        } else {
            bollinger_lower = tmp_data[index]['bb_ma'] + (2 * tmp_data[index]['bb_sd']);
            bollinger_upper = tmp_data[index]['bb_ma'] - (2 * tmp_data[index]['bb_sd']);
        }
        
        let tmp_row = {...row};
        tmp_row[output_name_base] = tmp_row[data_point];
        tmp_row[output_name_lower] = bollinger_lower;
        tmp_row[output_name_upper] = bollinger_upper;
        delete tmp_row['bb_ma'];
        delete tmp_row['bb_sd'];
        updated_data.push(tmp_row);
    })
    return updated_data;
}

function compute_minichart_volume(chart_data, data_point, output_name, output_name_normalized){

    let updated_data = [] 
    let tmp_row = {...chart_data[0]}
    tmp_row[output_name] = 0
    updated_data.push(tmp_row)
    for(let i  = 1; i < chart_data.length; i++) {
        tmp_row = {...chart_data[i]};
        tmp_row[output_name] = chart_data[i][data_point] - chart_data[i-1][[data_point]]
        tmp_row[output_name_normalized] = Math.abs(tmp_row[output_name])
        updated_data.push(tmp_row)
    }
    return updated_data;
}

export {format_data_to_gchart, filter_dataset, calculate_MACD, calculate_keltner_channels, calculate_average_true_range, calculate_simple_moving_average, calculate_exponential_moving_average, calculate_simple_moving_average_cross, calculate_moving_standard_deviation, calculate_bollinger_band, compute_minichart_volume, set_marker_line};