<template>
    <v-row class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
            <v-row class="ma-0 pa-0" justify="space-around">
                <v-col cols="12" class="mt-6" v-if="tbl_avaialable">
                  <GChart
                      style="height: 400px"
                      type="LineChart"
                      :options="portfolio_sos_config"
                      :data="portfolio_sos_data"
                  ></GChart>
                </v-col>
                <v-col cols="12" class="mt-6" v-else>
                  <v-skeleton-loader light style="height: 400px; max-height: none; " type="image,image"></v-skeleton-loader>
                </v-col>
                <v-col cols="12">
                    <SpendImpressionsContribution :dataset="report_dataset['spend_impressions_contribution']" :color_palette="cpalette"></SpendImpressionsContribution>
                </v-col>
                <v-col cols="12">
                    <TimeSeries :dataset="report_dataset['time_series_comparison']" :color_palette="cpalette"></TimeSeries>
                </v-col>
                <v-col cols="12">
                  <div :class="{'scrollable-container': report_dataset['qoq_yoy']['qoq'].length > 6}">
                    <v-row class="ma-0 pa-0" justify="space-around" style="flex-wrap: nowrap;">
                      <v-col cols="2" v-for="(qoq,idx) in report_dataset['qoq_yoy']['qoq']" :key="idx">
                        <CompPart :dataset="qoq"></CompPart>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12">
                    <VolumeVsEfficiency :dataset="report_dataset['volume_vs_efficiency']" :available_adunits="report_dataset['level_values']" :color_palette="cpalette" v-on:ve_levels="update_volume_vs_efficiency"></VolumeVsEfficiency>
                </v-col>
                <v-col cols="12">
                    <ROASContribution :dataset="report_dataset['roas_contribution']" :color_palette="cpalette"></ROASContribution>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE } from "@/constants/constants";
// import performance_data_header from "@/input_data/performance_table_header.json";
// import performance_data from "@/input_data/Performance/MMM_totals.json";

import ROASContribution from "./PerformanceReportSections/roas_contribution.vue";
import TimeSeries from "./PerformanceReportSections/time_series_comparison.vue";
import SpendImpressionsContribution from "./PerformanceReportSections/spend_impressions_contribution.vue";
import VolumeVsEfficiency from "./PerformanceReportSections/volume_vs_efficiency.vue";
import CompPart from "./PerformanceReportSections/comp_part.vue"

import chartConfig from "@/configs/Performance/performance_portfolio_sales.json";
import categoryChartConfig from "@/configs/Performance/performance_portfolio_categories.json";
import light_mode_colors from "@/configs/ChartColors/light_mode_colors.json";

import api_caller from "@/javascript/data_retrieval.js";
// import info_caller from "@/javascript/data_info.js";
import { GChart } from "vue-google-charts/legacy";

// import cpalette from "@/configs/ChartColors/get_color_palette.js";

// import cpalette from "@/configs/ChartColors/get_color_palette.js";

export default {
  name: "PerformanceOverview",
  props: ["data_levels", "project_date_range", "project_filters"],
  components: {
    GChart,
    ROASContribution,
    TimeSeries,
    SpendImpressionsContribution,
    VolumeVsEfficiency,
    CompPart
  },
  data: () => ({
    cpalette: 'retro',

    performanceDataRaw: null,
    performanceData: [],
    compareDataRaw: null,
    compare_data: null,
    selected_levels: [],
    selected_fields: [],
    selected_sort_keys: [],
    tbl_avaialable: false,
    toolbar_visible: true,
    portfolio_type: "portfolio",

    views_visible: false,
    selected_view: null,
    save_view: false,
    view_name_tmp: "",
    available_views: [],

    compare: false,
    compare_start_date: null,
    compare_end_date: null,

    portfolio_sos_config: chartConfig,
    portfolio_sos_data: [
      ["date", "sos", "csos", "sales"],
      ["2023-01-01", 50, 70, 100000],
      ["2023-01-08", 40, 60, 150000],
      ["2023-01-15", 60, 50, 175000],
      ["2023-01-22", 55, 55, 200000],
      ["2023-01-29", 60, 57, 200000],
    ],

    portfolio_categories_config: categoryChartConfig,
    portfolio_categories_data: [
        {      
            "stat": "Channel",
            "data": [
                ["cat", "val"],
                ["OOH", 10],
                ["Programmatic", 30],
                ["TV", 40],
                ["DOOH", 50]
            ]
        },
        {      
            "stat": "Format",
            "data": [
                ["cat", "val"],
                ["OOH", 10],
                ["Programmatic", 30],
                ["TV", 40],
                ["DOOH", 50]
            ]
        },
    ],

    report_dataset: {
        'spend_impressions_contribution': null,
        'spend_impressions_contribution_timing': null,
        'roas_contribution': null, 
        'time_series_comparison': null,
        'time_series_comparison_roas': null,
        'response_curves': null,
        'qoq_yoy': {
          'qoq': [],
          'yoy': []
        },
        'level_values': []
    },

    start_date: null,
    end_date: null,
  }),
  mounted() {
    this.portfolio_sos_config["colors"] = light_mode_colors;
    this.portfolio_categories_config["colors"] = light_mode_colors;
    this.refresh_data();
  },
  watch: {
    project_date_range: {
      immediate: true,
      handler: function () {
        this.start_date = this.project_date_range["start_date"];
        this.end_date = this.project_date_range["end_date"];
        // this.refresh_data();
      },
      deep: true,
    },
    // data_levels: {
    //   immediate: false,
    //   handler: function () {
    //     if (
    //       this.selected_levels.toString() !=
    //       this.data_levels["selected_levels"].toString()
    //     ) {
    //       this.selected_levels = this.data_levels["selected_levels"];
    //       this.refresh_data();
    //     }
    //   },
    //   deep: true,
    // },
  },
  computed: {
    available_data_levels: function () {
      if (this.performanceDataRaw == null) {
        return [];
      }
      return Object.keys(this.performanceDataRaw["table_levels"]);
    },
    
    
  },
  methods: {
    update_volume_vs_efficiency: async function(levels){
      this.report_dataset['volume_vs_efficiency'] = null;
      let token = await this.$auth.getTokenSilently();
      let date_range = {
        start_date: this.project_date_range['start_date'],
        end_date: this.project_date_range['end_date'],
      };
      let payload = {
        'project_id': this.$project.get_val(),
        'level_values': levels,
        'data_levels': this.data_levels,
        'date_range': date_range
      }
      let volume_efficiency = await api_caller.get_updated_volume_efficiency(token, payload).then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          }
          return false;
        })
        .catch((e) => {
          console.log("error", e);
          return false;
        });
      if (!volume_efficiency) {
        this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
        return;
      }else{
        this.report_dataset['volume_vs_efficiency'] = volume_efficiency['volume_vs_efficiency']
      }
    },
    refresh_data: async function () {
      this.tbl_avaialable = false;
      let date_range = {
        start_date: this.start_date,
        end_date: this.end_date,
      };
      let token = await this.$auth.getTokenSilently();
      
      let portfolio_overview_data = await api_caller.get_portfolio_overview_data(token, this.$project.get_val(), this.data_levels, date_range, this.project_filters)
        .then((resp) => {
          if(typeof resp === 'string'){
            return JSON.parse(resp)['data']
          }
          return resp["data"];
        })
        .catch((e) => {
          console.log("error", e);
          return false;
        });

      if (!portfolio_overview_data) {
        this.portfolio_sos_data = [];
      }

      this.report_dataset = portfolio_overview_data;
      this.portfolio_sos_data = portfolio_overview_data["search_sales"];
      this.portfolio_categories_data = portfolio_overview_data["overview"];

      this.reset_table();
    },
    // refresh_compare_data: async function () {
    //   this.tbl_avaialable = false;
    //   let date_range = {
    //     start_date: this.compare_start_date,
    //     end_date: this.compare_end_date,
    //   };
    //   let token = await this.$auth.getTokenSilently();
    //   let portfolio_data = await api_caller
    //     .get_portfolio_data(token, this.$project.get_val(), date_range)
    //     .then((resp) => {
    //       if (resp["success"]) {
    //         return resp["data"];
    //       }
    //       return false;
    //     })
    //     .catch((e) => {
    //       console.log("error", e);
    //       return false;
    //     });
    //   if (!portfolio_data) {
    //     this.compareDataRaw = null;
    //     this.update_data_levels(false);
    //     this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
    //     return;
    //   }
    //   this.compareDataRaw = portfolio_data;
    //   this.update_data_levels(false);
    //   this.reset_table();
    // },
    // refresh_views: async function(){
    //   let token = await this.$auth.getTokenSilently();
    //   let saved_views = await info_caller
    //     .get_saved_views(token, this.$project.get_val())
    //     .then((resp) => {
    //       if (resp["success"]) {
    //         return resp["data"];
    //       }
    //       return false;
    //     })
    //     .catch((e) => {
    //       console.log("error", e);
    //       return false;
    //     });
    //   if (!saved_views) {
    //     this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
    //     return;
    //   }
    //   this.available_views = saved_views['views'];      
    // },
    reset_table: function () {
      this.tbl_avaialable = false;
      setTimeout(() => (this.tbl_avaialable = true), 100);
    },
    // sort_table: function () {
    //   return;
    // },
    // handle_row_click: function (value) {
    //   let channel = {
    //     data_levels: [],
    //     data_query: [],
    //   };
    //   let levels = [];
    //   for (let level of this.selected_levels) {
    //     if (value[level]) {
    //       channel["data_query"].push({
    //         level: level,
    //         value: value[level],
    //       });
    //       levels.push(level);
    //     } else {
    //       return;
    //     }
    //   }
    //   channel["data_levels"] = levels;
    //   this.$emit("switch_tab", "graph", channel);
    // },
    // // update_color: function (item) {
    // //   // let grey_notches = ["#FFFFFF","#cccccc","#808080"];
    // //   let grey_notches = [
    // //     "#FFFFFF",
    // //     "#F0F0F0",
    // //     "#DCDCDC",
    // //     "#D0D0D0",
    // //     "#BEBEBE",
    // //     "#A9A9A9",
    // //   ];
    // //   let null_count = 0;
    // //   for (let level of this.selected_levels) {
    // //     if (item[level] == null) {
    // //       null_count++;
    // //     }
    // //   }
    // //   return grey_notches[null_count];
    // // },
    // level_val: function (item, col) {
    //   let idx = this.selected_levels.indexOf(col);
    //   let hide = false;
    //   for (let i = this.selected_levels.length - 1; i > idx; i--) {
    //     if (item[this.selected_levels[i]] != null) {
    //       hide = true;
    //     }
    //   }
    //   if (hide) {
    //     return "";
    //   } else {
    //     return item[col];
    //   }
    // },
    // update_data_levels: function (emit_update) {
    //   if (this.performanceDataRaw == null) {
    //     this.performanceData = [];
    //     return;
    //   }
    //   this.performanceData = this.filter_dataset(
    //     this.performanceDataRaw["table_content"]
    //   );
    //   if (this.compare) {
    //     let compare_data = this.filter_dataset(this.compareDataRaw["table_content"]);
    //     for (let idx = 0; idx < this.performanceData.length; idx++) {
    //       let row_id = null;
    //       for (let cdx = 0; cdx < compare_data.length; cdx++) {
    //         for (let level of this.selected_levels) {
    //           if (!(compare_data[cdx][level] === this.performanceData[idx][level])) {
    //             row_id = null;
    //             break;
    //           } else {
    //             row_id = cdx;
    //           }
    //         }
    //         if (row_id != null) {
    //           break;
    //         }
    //       }
    //       this.performanceData[idx]["roi compare"] = compare_data[row_id]["roi"];
    //       this.performanceData[idx]["roi change"] =
    //         this.performanceData[idx]["roi"] == 0 || this.performanceData[idx]["roi"] == 0
    //           ? 0
    //           : (this.performanceData[idx]["roi"] -
    //               this.performanceData[idx]["roi compare"]) /
    //             this.performanceData[idx]["roi compare"];

    //       this.performanceData[idx]["spend compare"] = compare_data[row_id]["spend"];
    //       this.performanceData[idx]["spend change"] =
    //         this.performanceData[idx]["spend"] == 0 ||
    //         this.performanceData[idx]["spend"] == 0
    //           ? 0
    //           : (this.performanceData[idx]["spend"] -
    //               this.performanceData[idx]["spend compare"]) /
    //             this.performanceData[idx]["spend compare"];

    //       this.performanceData[idx]["impressions compare"] =
    //         compare_data[row_id]["impressions"];
    //       this.performanceData[idx]["impressions change"] =
    //         this.performanceData[idx]["impressions"] == 0 ||
    //         this.performanceData[idx]["impressions"] == 0
    //           ? 0
    //           : (this.performanceData[idx]["impressions"] -
    //               this.performanceData[idx]["impressions compare"]) /
    //             this.performanceData[idx]["impressions compare"];

    //       this.performanceData[idx]["sales_attribution compare"] =
    //         compare_data[row_id]["sales_attribution"];
    //       this.performanceData[idx]["sales_attribution change"] =
    //         this.performanceData[idx]["sales_attribution"] == 0 ||
    //         this.performanceData[idx]["sales_attribution"] == 0
    //           ? 0
    //           : (this.performanceData[idx]["sales_attribution"] -
    //               this.performanceData[idx]["sales_attribution compare"]) /
    //             this.performanceData[idx]["sales_attribution compare"];
    //     }
    //   }

    //   let level_values = [];
    //   let level_name = "";
    //   let valid = false;
    //   for (let row of this.performanceData) {
    //     level_name = "";
    //     valid = true;
    //     for (let l of this.selected_levels) {
    //       if (row[l]) {
    //         level_name += row[l];
    //       } else {
    //         valid = false;
    //       }
    //       if (row[l] != null) {
    //         level_name += "__";
    //       }
    //     }
    //     if (valid) {
    //       level_values.push(level_name.slice(0, -2));
    //     }
    //   }
    //   if (emit_update || this.data_levels["level_values"].length == 0) {
    //     this.$emit("level_update", {
    //       data_levels: this.available_data_levels,
    //       selected_levels: this.selected_levels,
    //       level_values: level_values,
    //     });
    //   }
    // },
    // filter_dataset(data) {
    //   let data_rows = [];
    //   let level_idxs = {};
    //   let include = false;
    //   let include_sub = false;
    //   let num_levels = this.selected_levels.length;
    //   let i = 0;
    //   for (let level of this.selected_levels) {
    //     level_idxs[level] = i;
    //     i++;
    //   }
    //   for (let row of data) {
    //     include = true;
    //     for (let level of this.available_data_levels) {
    //       if (!this.selected_levels.includes(level) && row[level] != null) {
    //         include = false;
    //       }
    //     }
    //     include_sub = true;
    //     i = 0;
    //     for (i = 0; i < num_levels; i++) {
    //       if (i == 0 && row[this.selected_levels[i]] == null) {
    //         include = false;
    //         break;
    //       } else if (row[this.selected_levels[i]] == null) {
    //         include_sub = false;
    //       } else if (include_sub == false && row[this.selected_levels[i]] != null) {
    //         include = false;
    //         break;
    //       }
    //     }
    //     if (include) {
    //       data_rows.push(row);
    //     }
    //   }
    //   return data_rows;
    // },
    // update_start_date(date) {
    //   this.selected_view = null;
    //   this.$emit("date_update", { start_date: date, end_date: this.end_date });
    // },
    // update_end_date(date) {
    //   this.selected_view = null;
    //   this.$emit("date_update", { start_date: this.start_date, end_date: date });
    // },
    // update_compare_start(date) {
    //   this.compare_start_date = date;
    //   this.selected_view = null;
    //   this.refresh_compare_data();
    // },
    // update_compare_end(date) {
    //   this.compare_end_date = date;
    //   this.selected_view = null;
    //   this.refresh_compare_data();
    // },
    // open_view(view){
    //   let data_view = this.available_views[view];
    //   this.$emit("date_update", { start_date: data_view['start_date'], end_date: data_view['end_date'] });
    //   if(data_view['compare']){
    //     this.compare = data_view['compare'];
    //     this.compare_start_date = data_view['compare_start_date'];
    //     this.compare_end_date = data_view['compare_end_date'];
    //     this.refresh_compare_data();
    //     // this.update_compare_end(data_view['compare_end_date']);
    //   }
    //   else{
    //     this.compare = data_view['compare'];
    //     this.compare_start_date = data_view['compare_start_date'];
    //     this.compare_end_date = data_view['compare_end_date'];
    //   }
    //   this.selected_view = view;
    // },
    // save_view_func: function(){
    //   this.save_view = true;
    // },
    // close_save_view_func: function(){
    //   this.save_view = false;
    //   this.view_name_tmp = '';
    // },
    // add_view: async function(){
    //   let token = await this.$auth.getTokenSilently();
    //   let view = {
    //     'name': this.view_name_tmp,
    //     'start_date': this.start_date,
    //     'end_date': this.end_date,
    //     'data_levels': this.selected_levels.join('|'),
    //     'compare': this.compare,
    //     'compare_start_date': this.compare_start_date,
    //     'compare_end_date': this.compare_end_date
    //   };
    //   await info_caller.add_view(token, this.$project.get_val(),view);
    //   this.view_name_tmp = '';
    //   this.save_view = false;
    //   await this.refresh_views();
    //   this.selected_view = this.available_views.length - 1
    // },
    // delete_view: async function (view) {
    //   let token = await this.$auth.getTokenSilently();
    //   await info_caller.delete_view(token, this.$project.get_val(), view);
    //   this.refresh_views();
    // }
  },
};
</script>

<style>

.table-title {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 1.5em;
}
.performance_section_title {
  font-family: 'Poppins' !important;
  font-weight: 500 ;
  font-size: 0.9 em;
}
.toolbar_title {
  font-family: 'Poppins' !important;
  font-weight: 600 ;
  font-size: 1.1em;
}
.toolbar_selector {
  font-family: 'Poppins' !important;
  font-weight: 400 ;
  font-size: 0.9 em;
}
.v-list-item .v-list-item__title {
  font-family: 'Poppins' !important;
  font-weight: 400 ;
  font-size: 0.85em;
}
.scrollable-container {
  overflow-x: auto;
  display: flex;
}
</style>
