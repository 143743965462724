<template>
  <v-card style="height: 100%; max-height: 100%; overflow-y: scroll" class="ma-0 pa-0">
    <v-row class="ma-0 pa-0" style="width: 100%">
      <v-col cols="auto">
        <span class="report-subtitle">Quarterly Spend, Impressions & Contribution</span>
      </v-col>
      <v-spacer></v-spacer>
      <!-- <v-col cols="1">
        <v-btn icon @click="open_comments()" :disabled="!comments_persistent || true">
          <v-icon>mdi-comment-edit-outline</v-icon>
        </v-btn>
      </v-col> -->
      <v-col cols="12" class="ma-0 pa-0" style="height: 10px">
        <v-divider class="mx-12"></v-divider>
      </v-col>
      <v-col v-if="graph_visible" :cols="main_graph_section_cols" class="ma-0 pa-0">
        <v-row class="ma-0 pa-0">
          <v-col
            :cols="minor_graph_section_cols"
            v-for="(value, index) in Object.entries(graph_data)"
            :key="index"
          >
            <v-col cols="12">
              <span class="graph-title">{{ value[0] }}</span>
            </v-col>
            <v-col cols="12">
              <div
                :style="{
                  height: screenHeight + 'px',
                }"
              >
                <GChart
                  type="ColumnChart"
                  :data="value[1]"
                  :options="graph_config"
                  :resizeDebounce="50"
                  class="performanceChart"
                />
              </div>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="4" v-if="comments_section">
        <CommentSection v-on:close_comments="close_comments"></CommentSection>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { GChart } from "vue-google-charts/legacy";

// Configs
import stackedChartConfig from "@/configs/Performance/performance_spend_impr_contribution_stacked_chart_config.json";
import cpalette from "@/configs/ChartColors/get_color_palette.js";
import CommentSection from "./comment_section.vue";

export default {
  name: "SpendImpressionsContributionQuarterly",
  props: ["data_version", "dataset", "comments_persistent",'color_palette'],
  components: {
    GChart,
    CommentSection,
  },
  methods: {
    reload_graph: function () {
      this.graph_visible = false;
      setTimeout(() => (this.graph_visible = true), 10);
    },
    open_comments: function () {
      this.comments_section = true;
      this.main_graph_section_cols = 8;
      this.minor_graph_section_cols = 6;
      this.reload_graph();
    },
    close_comments: function () {
      this.comments_section = false;
      this.main_graph_section_cols = 12;
      this.minor_graph_section_cols = 3;
      this.reload_graph();
    },
  },

  data: () => ({
    graph_visible: false,

    graph_config: stackedChartConfig,
    graph_data: null,
    comments_section: false,
    main_graph_section_cols: 12,
    minor_graph_section_cols: 3,

    screenHeight: window.innerHeight * (window.innerHeight > 1000 ? 0.5 : 0.4)
  }),
  mounted() {
    let colors = cpalette.get_color_palette(this.color_palette);
    this.graph_config['colors'] = colors;
    this.graph_data = this.dataset;
    this.reload_graph();
  },
  watch: {
    comments_persistent: {
      immediate: true,
      handler: function () {
        if (this.comments_persistent) {
          this.open_comments();
        } else {
          this.close_comments();
        }
      },
    },
  },
  computed: {},
};
</script>

<style scoped>
.main-page-title {
  /* line-height: 5em; */
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.5em;
}
.report-subtitle {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 1.5em;
}

.graph-title {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 0.9em;
}

.report-version {
  font-family: "Poppins";
  font-weight: 600;
  align-items: center;
  /* font-size: 0.5em; */
  /* width: 50px; */
}

.performanceChart {
  height: 100%;
  width: 100%;
}

.chartHeader {
  .chartHeaderText {
    font-family: "Poppins" !important;
    font-weight: 600;
    font-size: 1.5em;
  }

  display: flex;
}

.toolBar {
  float: right;
}

.toolButton {
  padding: 0 32px;
  width: 50px;
  color: black;
}

::v-deep .v-overlay__content {
  position: absolute;
  right: 0;
  height: 100%;
  width: 30%;
}

.overlayField {
  padding: 0 16px;
}

.overlayButton {
  float: right;
}

.chartWithOverlay {
  /* position: relative; */
  width: 100%;
  /* height: 500px; */
}
.miniChartWithOverlay {
  /* position: relative; */
  width: 100%;
  height: 75px;
}

.metricToggleOverlay {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 12%;
  left: 90px;
}

.metricToggleRow {
  margin-left: 0;
  margin-right: auto;
}
.metric_toolbar_title {
  font-weight: 800 !important;
}
.metric_toolbar_subtitle {
  font-weight: 600 !important;
}
.metric_toolbar_metric_title {
  line-height: 1em;
  font-weight: 200 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: calc(80%);
  text-align: left;
  overflow: hidden;
}

.tooltip_description {
  font-family: "Poppins" !important;
  font-weight: 200;
}
.long_description {
  font-family: "Poppins" !important;
}

.expansionPanel {
  overflow: none;
}

.reportCommentContent {
  max-height: 350px;
  overflow-y: auto;
}

.reportCommentInputField {
  float: right;
  width: 90%;
}

.chartAlign {
  margin: auto 0;
}
</style>
