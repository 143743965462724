<template>
    <v-container class="px-6 pt-0 pb-3" style="max-width: 100%;">
      <v-row class="ma-0 pa-0">
        <span class="table-title">
          My Studies
        </span>
      </v-row>
      <v-row class="ma-0 pa-0" style="width: 100%; height: 100%;">
        <v-col cols="4" style="width: 100%; height: 100%; max-height: 550px;">
          <v-card style="height: 500px; max-height: 500px; overflow-y: scroll;">
              <v-card-title>
                <v-icon class="ml-0 mr-3" color="black">mdi-filter-settings-outline</v-icon>
                <span>Brands</span>
            </v-card-title>
              <v-row style="width: 100%;" class="ma-0 pa-0">
                <v-col cols="12" v-for="study, idx in savedStudies" :key="idx">
                  <v-card>
                    <v-card-title>
                      <span>{{study['brandTitle']}}</span>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="viewingStudy=idx; openStudy(idx);">
                        <v-icon class="mx-1">mdi-open-in-app</v-icon> 
                      </v-btn>
                      <v-btn icon @click="viewingStudy=idx" v-if="idx != viewingStudy">
                        <v-icon class="mx-1">mdi-eye-off</v-icon> 
                      </v-btn>
                      <v-btn icon v-else>
                        <v-icon class="mx-1">mdi-eye</v-icon> 
                      </v-btn>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
          </v-card>
        </v-col>
        <v-col cols="8" style="height: 100%;">
          <v-card style="height: 500px; max-height: 500px; overflow-y: scroll;">
              <v-card-title>
                <span>{{savedStudies[viewingStudy]['brand_title']}}</span>
                <v-spacer></v-spacer>
                <v-btn @click="openStudy(viewingStudy)">
                  Open Study
                  <v-icon class="mx-1">mdi-open-in-app</v-icon>
                </v-btn>
              </v-card-title>
              <v-col cols="12" class="ma-0 py-0">
                <v-divider></v-divider>
              </v-col>
              <v-row style="width: 100%;" class="ma-0 pa-0">
                <v-col cols="8">
                  <v-card flat>
                    <v-card-title>
                      Study Versions
                    </v-card-title>
                    <v-row>
                      <v-col cols="12" v-for="study_version, idx in savedStudies[viewingStudy]['project_versions']" :key="idx">
                        <v-card>
                          <v-card-title>
                            Version {{study_version['version_id']}}
                            <v-spacer></v-spacer>
                            <v-icon>mdi-checkbox-blank-outline</v-icon>
                          </v-card-title>
                          <v-card-subtitle>2023-02-29</v-card-subtitle>
                          <v-col cols="12" class="ma-0 py-0">
                            <span>Data Levels: Channel, Format</span>
                          </v-col>
                          <v-col cols="12" class="ma-0 py-0">
                            <span>Start Date: {{study_version['date_range']['start_date']}}</span>
                          </v-col>
                          <v-col cols="12" class="ma-0 py-0">
                            <span>End Date: {{study_version['date_range']['end_date']}}</span>
                          </v-col>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card flat>
                    <v-card-title>
                      Access
                    </v-card-title>
                    <v-row>
                      <v-col cols="12" v-for="user_access, idx in savedStudies[viewingStudy]['uaccess']" :key="idx">
                        <v-card>
                          <v-card-title>{{user_access['user_name'] }}<v-spacer></v-spacer><v-icon>mdi-close</v-icon></v-card-title>
                          <v-card-subtitle>{{user_access['email'] }}</v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
//   import TooltipDescription from "@/components/Utility/TooltipDescription.vue";
//   import DateRange from "@/components/Utility/DateRange.vue";

  
  export default {
    name: "PerformanceMyStudies",
    props: [
      
    ],
    components: {
    //   TooltipDescription,
    //   DateRange
    },
    data: () => ({
      savedStudies : [
        {
          'brand_title': 'Planet Fitness',
          'project_id': '00010001',
          'project_versions': [
            {
              'version_id': '1.1.1',
              'data_levels': ['channel','format'],
              'date_range': {
                'start_date': '2023-01-01',
                'end_date': '2023-09-30'
              }
            },
            {
              'version_id': '2.1.1',
              'data_levels': ['channel','format'],
              'date_range': {
                'start_date': '2023-01-01',
                'end_date': '2023-12-30'
              }
            },
            {
              'version_id': '3.1.1',
              'data_levels': ['channel','format'],
              'date_range': {
                'start_date': '2023-01-01',
                'end_date': '2024-02-29'
              }
            },
          ],
          'uaccess': [
            {
              'user_name': 'Taylor Grant',
              'email': 'tgrant@docma.ca',
              'access_level': 'admin'
            }
          ]
        },
        {
          'brandTitle': 'Ricola',
          'project_id': '00010002',
          'project_versions': [
            {
              'version_id': '1.1.1',
              'data_levels': ['channel','format'],
              'date_range': {
                'start_date': '2021-01-01',
                'end_date': '2023-09-30'
              }
            }
          ],
          'uaccess': [
            {
              'user_name': 'Taylor Grant',
              'email': 'tgrant@docma.ca',
              'access_level': 'admin'
            },
            {
              'user_name': 'Sam Rowe',
              'email': 'srowe@docma.ca',
              'access_level': 'admin'
            }
          ]
        }
      ],
      viewingStudy: 0
    }),
    mounted(){
    },  
    watch: {
    },
    computed: {
      
    },
    methods: {
      openStudy: function(idx){
        console.log(idx);
      }
    },
  };
  </script>
  
  <style>
    
  </style>
  