<template>
    <v-card style="height: 100%; max-height: 100%; overflow-y: scroll;" class="ma-0 pa-0" flat>
      <v-row class="ma-0 pa-0" style="width: 100%;">
        <v-col cols="auto" align="center" justify="center">
          <span class="report-subtitle">Volume vs Efficiency</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <!-- <v-menu v-model="filter_menu" :close-on-content-click="false" :close-on-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="ml-0 mr-1" v-bind="attrs" v-on="on">mdi-filter</v-icon>
            </template>
            <v-card class="filter_card">
              <v-card-title class="dialogTitle"><v-icon class="mx-1" color="black" :disabled="!graph_visible">mdi-filter</v-icon>Filter</v-card-title>
              <v-row class="ma-0 pa-0" style="max-height: 200px; overflow-y: scroll;">
                <v-col cols="12">
                  <v-select class="actionText" chip v-model="selected_adunits" :items="available_adunits" multiple>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span class="actionText">{{ item }}</span>
                      </v-chip>
                      <v-chip v-if="index === 1">
                        <span class="actionText">{{ item }}</span>
                      </v-chip>
                      <v-chip v-if="index === 2">
                        <span class="actionText">{{ item }}</span>
                      </v-chip>
                      <span
                        v-if="index === 3"
                        class="grey--text text-caption"
                      >
                        (+{{ selected_adunits.length - 3 }} others)
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-row class="ma-0 pa-0">
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <v-btn class="actionText" outlined @click="cancel_filter(h.value);$nextTick(() => { filter_menu= false; })">Cancel</v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn class="actionText" outlined @click="save_filter(h.value); filter_menu = false;" :disabled="arraysEqual(filter_vals[h.value], filter_base[h.value]) || filter_vals[h.value].length == 0">Save</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-menu> -->
          <v-select style="max-width: 400px; width: 400px" class="ma-0 pa-0" dense hide-details v-model="selected_adunits" :items="available_adunits" multiple :loading="!graph_visible">
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item }}</span>
              </v-chip>
              <v-chip v-if="index === 1">
                <span>{{ item }}</span>
              </v-chip>
              <span
                v-if="index === 2"
                class="grey--text text-caption"
              >
                (+{{ selected_adunits.length - 2 }} others)
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" class="ma-0 pa-0" style="height: 10px;">
          <v-divider class="mx-12"></v-divider>
        </v-col>
        <v-col cols="12" class="px-12" v-if="!graph_visible">
            <v-progress-linear
              size="100"
              indeterminate
              color="primary"
            ></v-progress-linear>
        </v-col>
        <v-col v-if="graph_visible" :cols="spend_impr_contribution_col_size" class="ma-0 pa-0" style="height: calc(100% - 10px);">
            <v-row class="ma-0 pa-0" style="height: 90%">
              <v-col cols="12" class="chartAlign">
                  <GChart
                  :style="{height: screenHeight + 'px'}"
                  type="ComboChart"
                  :options="graph_config"
                  :data="graph_data"
                  :resizeDebounce="50"
                  />
              </v-col>
            </v-row>
          </v-col>
      </v-row>
    </v-card>
  </template>
  
  <script>
  import { GChart } from "vue-google-charts/legacy";
  
  // Configs
  // import cpalette from "@/configs/ChartColors/get_color_palette.js";
  
  export default {
    name: "VolumeVsEfficiency",
    props: [
      'data_version',
      'dataset',
      'color_palette',
      'available_adunits'
    ],
    components: {
      GChart
    },
    data: () => ({
      filter_menu: false,
      graph_visible: false,
  
      graph_config: {
        font: 'Poppins',
        hAxis: {
          title: 'ROAS ($)',
          viewWindow: {'min': 0},
          gridlines: { color: 'transparent' },
          baselineColor: 'black',
          format: "short"
        },
        vAxis: {
          title: 'Contribution ($)',
          viewWindow: {'min': 0},
          gridlines: { color: 'transparent' },
          baselineColor: 'black',
          format: "short"
        },
        chartArea: { top: 10, bottom: 50, width: "75%"},
        legend: 'none',
        pointSize: 10,
        seriesType: 'scatter',
        series: {
          0: {
            annotations: {
              stem: {
                length: 50
              },
              textStyle: {
                color: '#1b1b1b',
                fontSize: 14,
                font: 'Poppins',
                bold: true,
                auraColor: 'white'
              },
              alwaysOutside: true
            },
            color: '#FD6262',
            enableInteractivity: true,
            tooltip: {isHtml: true}
          },
          1: {
            annotations: {
              stem: {
                length: 0
              },
              textStyle: {
                color: 'black',
                fontSize: 20,
                font: 'Poppins',
                bold: true,
                auraColor: 'white'
              }
            },
            color: 'transparent',
            enableInteractivity: false
          },
          2: {
            color: '#333333',
            enableInteractivity: false,
            type: 'line',
            pointSize: 0,
            lineDashStyle: [10,5],
            pointsVisible: false
          },
          3: {
            color: '#333333',
            enableInteractivity: false,
            type: 'line',
            pointSize: 0,
            lineDashStyle: [10,5],
            pointsVisible: false
          }
        },
        tooltip: {isHtml: true}
      },
      graph_data: null,

      spend_impr_contribution_overview_comments: false,
      spend_impr_contribution_col_size: 12,
      screenHeight: window.innerHeight * (window.innerHeight > 1000 ? 0.9 : 0.75),

      selected_adunits: [],
      adunit_updates: 0
    }),
    mounted() {
      this.graph_data = this.dataset;
      if(this.dataset != null){
        this.reload_graph();
      }
    },
    watch: {
      dataset: {
        immediate: false,
        handler: function () {
          this.graph_data = this.dataset;
          if(this.dataset != null){
            this.reload_graph();
          }
        },
        deep: true,
      },
      selected_adunits: {
        immediate: false,
        handler: function () {
          this.adunit_updates += 1;
          setTimeout(() => {
            this.adunit_updates -= 1; 
            if(this.adunit_updates == 0){
              this.$emit('ve_levels', this.selected_adunits);
            }
          }, 2000);
        },
      },
      available_adunits: {
        immediate: false,
        handler: function () {
          this.selected_adunits = this.available_adunits;
        },
      }
    },
    computed: {},
    methods: {
      reload_graph: function () {
        this.graph_visible = false;
        setTimeout(() => (this.graph_visible = true), 10);
      },
    },
  };
  </script>
  
  <style scoped>
    .report-subtitle {
      font-family: "Poppins";
      font-weight: 500;
      font-size: 1.5em;
    }
    .chartAlign {
      margin: auto 0
    }
  </style>
  