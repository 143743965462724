<template>
  <v-container class="px-6 pt-0 pb-3" style="max-width: 100%">
    <v-row class="ma-0 pa-0" style="width: 100%; height: 100%">
      <v-col :cols="toolbar_visible ? 9 : 12" style="width: 100%;">
        <v-row class="ma-0 pa-0">
          <v-col cols="7" class="ma-0 pa-0">
            <v-chip
              label
              class="data_breakdown_chip"
              :outlined="data_breakdown_type != 'spend'"
              @click="switch_data_breakdown('spend')"
              color="black"
            >
              Media Spend
            </v-chip>
            <v-chip
              label
              class="data_breakdown_chip"
              :outlined="data_breakdown_type != 'impressions'"
              @click="switch_data_breakdown('impressions')"
              color="black"
            >
              Impressions
            </v-chip>
          </v-col>
          <v-spacer></v-spacer>
          <v-select
                class="toolbar_selector ma-0 pa-0 px-3"
                style="max-width: 200px"
                v-model="data_breakdown_timing"
                :items="['weekly', 'bi-weekly', 'monthly', 'quarterly', 'yearly']"
                @change="reset_table()"
                hide-details
              ></v-select>
          <v-col cols="auto" class="ma-0 pa-0">
            <v-btn icon elevation="0" disabled>
              <v-icon color="black">mdi-pencil-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="ma-0 pa-0">
            <v-btn icon elevation="0" disabled>
              <v-icon color="black">mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="ma-0 pa-0">
            <v-tooltip top v-if="toolbar_visible">
              <template v-slot:activator="{ on }">
                <v-btn icon elevation="0" v-on="on" @click="toolbar_visible = false">
                  <v-icon color="black">mdi-filter-off</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Hide Toolbar" desc_2=""></TooltipDescription>
            </v-tooltip>
            <v-tooltip top v-if="!toolbar_visible">
              <template v-slot:activator="{ on }">
                <v-btn icon elevation="0" v-on="on" @click="toolbar_visible = true">
                  <v-icon color="black">mdi-filter-settings-outline</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Toolbar" desc_2=""></TooltipDescription>
            </v-tooltip>
          </v-col>
          <v-col cols="12" class="ma-0 pa-0">
            <DataLevelTable
              :tableHeader="headers"
              :tableData="processedData"
              :selectedLevels="selected_levels"
              :tableRowClickFunction="handle_row_click"
              :numericColumns="numericColumns"
              :currencyColumns="currencyColumns"
              :percentageColumns="[]"
              v-if="tbl_avaialable"
            />
          </v-col>
          <v-col
            cols="12"
            class="ma-0 pa-0"
            style="height: 500px; width: 100%; align-items: center"
            v-if="!tbl_avaialable"
          >
            <v-row
              class="ma-0 pa-0"
              justify="center"
              :align="'center'"
              style="height: 100%"
            >
              <v-col cols="auto" class="ma-0 pa-0">
                <v-progress-circular
                  size="100"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3" style="height: 100%" v-if="toolbar_visible">
        <v-card style="height: 500px; max-height: 500px; overflow-y: scroll">
          <v-card-title>
            <v-icon class="ml-0 mr-3" color="black">mdi-hammer-screwdriver</v-icon>
            <span class="toolbar_title">Toolbar</span>
          </v-card-title>
          <v-row style="width: 100%" class="ma-0 pa-0">
            <v-col cols="12">
              <v-select
                class="toolbar_selector"
                disabled
                :value="data_version"
                label="Data Version"
                hint="The data version of the blocking chart"
                persistent-hint
                :items="[data_version]"
                @change="reset_table()"
              ></v-select>
            </v-col>
            <v-col cols="12" v-for="(lvl) in Object.keys(data_filters)" :key="lvl">
              <span class="filter_title">{{ lvl }} Filter: </span><br/>
              <span class="filter_vals">{{ format_filter_val_output(data_filters[lvl]) }}</span>
              <!-- <span>{{ filter_base_values[lvl] }}</span>
              <v-select v-model="data_filters[lvl]" :items="filter_base_values[lvl]"></v-select> -->
            </v-col>
            <v-col cols="12">
              <v-select
                class="toolbar_selector"
                v-model="selected_levels"
                :items="available_data_levels"
                label="Levels"
                multiple
                hint="Pick the level of granularity you wish to view the data."
                persistent-hint
                chips
                :sort-by="['Channel', 'Format']"
              >
              </v-select>
            </v-col>
            <v-col
              class="mr-0 ml-auto my-0 py-0 mx-3"
              :cols="12 - idx * 2"
              v-for="(val, idx) in selected_levels"
              :key="idx"
            >
              <v-icon class="mb-3" color="black">mdi-arrow-right-bottom-bold</v-icon>
              <span class="toolbar_selector">{{ val }}</span>
            </v-col>
            <v-col cols="12">
              <DateRange
                :start_date="start_date"
                :end_date="end_date"
                :min_start_date="project_date_range['min_start_date']"
                :max_end_date="project_date_range['max_end_date']"
                v-on:start_date_update="update_start_date"
                v-on:end_date_update="update_end_date"
                v-on:date_update="update_date"
              ></DateRange>
            </v-col>
            <!-- <v-col cols="12">
              <v-select
                class="toolbar_selector"
                label="Time Intervals"
                hint="The time interval of the blocking chart"
                persistent-hint
                v-model="data_breakdown_timing"
                :items="['weekly', 'bi-weekly', 'monthly', 'quarterly', 'yearly']"
                @change="reset_table()"
              ></v-select>
            </v-col> -->
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TooltipDescription from "@/components/Utility/TooltipDescription.vue";
import DataLevelTable from "@/components/Utility/DataLevelTable.vue";
import DateRange from "@/components/Utility/DateRange.vue";
import performance_data_header from "@/input_data/performance_table_header.json";

import api_caller from "@/javascript/data_retrieval.js";
import { ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE } from "@/constants/constants";

export default {
  name: "DataBreakdown",
  props: ["data_version", "data_levels", "project_date_range", "data_filters"],
  components: {
    DataLevelTable,
    TooltipDescription,
    DateRange,
  },
  data: () => ({
    performanceData: null,
    processedData: null,
    selected_levels: [],
    selected_sort_keys: [],
    tbl_avaialable: false,
    data_breakdown_timing: "monthly",
    data_breakdown_type: "spend",

    start_date: null,
    end_date: null,
    toolbar_visible: true,
    numericColumns: [],
    currencyColumns: [],
  }),
  mounted() {
    this.start_date = this.project_date_range["start_date"];
    this.end_date = this.project_date_range["end_date"];
    this.selected_levels = this.data_levels["selected_levels"];
  },
  watch: {
    project_date_range: {
      handler: function () {
        if (
          this.start_date != this.project_date_range["start_date"] ||
          this.end_date != this.project_date_range["end_date"]
        ) {
          this.start_date = this.project_date_range["start_date"];
          this.end_date = this.project_date_range["end_date"];
          this.reset_table();
        }
      },
      deep: true,
    },
    data_levels: {
      handler: function () {
        if (
          this.data_levels["selected_levels"].toString() !=
          this.selected_levels.toString()
        ) {
          this.selected_levels = this.data_levels["selected_levels"];
        }
      },
      deep: true,
    },
    data_filters: {
      handler: function () {
        this.reset_table();
      },
      deep: true,
    },
    selected_levels: {
      handler: function () {
        this.reset_table();
      },
      deep: true,
    },
  },
  computed: {
    columnList: function () {
      let tmp = [];
      for (let row of this.headers) {
        if (row["text"] != "channel") {
          tmp.push(row["text"]);
        }
      }
      return tmp;
    },
    available_data_levels: function () {
      return this.data_levels["data_levels"];
    },
    data_fields: function () {
      let fields = [];
      for (let header_row of performance_data_header["table_header_statistics"]) {
        fields.push(header_row["text"]);
      }
      return fields;
    },
    sort_keys: function () {
      let sort_keys = [];
      for (let header_row of this.headers) {
        sort_keys.push(header_row["value"]);
      }
      return sort_keys;
    },
    headers: function () {
      let data_tbl_header = [];
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var end = new Date(this.end_date);
      let daySkip = 7;
      let s = new Date(
        this.start_date.split("-")[0],
        parseInt(this.start_date.split("-")[1]) - 1,
        parseInt(this.start_date.split("-")[2])
      );
      let d = s;

      if (["weekly", "bi-weekly"].includes(this.data_breakdown_timing)) {
        if (this.data_breakdown_timing == "weekly") {
          daySkip = 7;
        } else if (this.data_breakdown_timing == "bi-weekly") {
          daySkip = 14;
        }
        for (d = s; d <= end; d.setDate(d.getDate() + daySkip)) {
          data_tbl_header.push({
            text: d.toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            value: d.toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            sortable: true,
            desc: "",
          });
        }
      } else if (["monthly"].includes(this.data_breakdown_timing)) {
        let m = 0;
        for (d = s; d <= end; d.setMonth(d.getMonth() + 1)) {
          m = months[d.getMonth()] + " " + d.getFullYear();
          data_tbl_header.push({ text: m, value: m, sortable: true, desc: "" });
        }
      } else if (["quarterly"].includes(this.data_breakdown_timing)) {
        let q = 0;
        for (d = s; d <= end; d.setMonth(d.getMonth() + 3)) {
          q = Math.floor((d.getMonth() + 3) / 3);
          data_tbl_header.push({
            text: "Q" + q + " " + d.getFullYear(),
            value: "Q" + q + " " + d.getFullYear(),
            sortable: false,
            desc: "",
          });
        }
      } else {
        for (d = s; d <= end; d.setFullYear(d.getFullYear() + 1)) {
          data_tbl_header.push({
            text: d.getFullYear().toString(),
            value: d.getFullYear().toString(),
            sortable: true,
            desc: "",
          });
        }
      }
      console.log(this.selected_levels)
      this.selected_levels
        .slice()
        .reverse()
        .forEach((lvl) =>
          data_tbl_header.unshift(performance_data_header["table_header_levels"][lvl])
        );
      return data_tbl_header;
    },
  },
  methods: {
    format_filter_val_output: function(filters){
      let filter_str = "";
      for(let f of filters){
        filter_str += f
        filter_str += ", "
      }
      return filter_str.slice(0,-2)
    },
    update_color: function (item) {
      // let grey_notches = ["#FFFFFF","#F0F0F0","#cccccc","#808080","#696969"];
      let grey_notches = [
        "#FFFFFF",
        "#F0F0F0",
        "#DCDCDC",
        "#D0D0D0",
        "#BEBEBE",
        "#A9A9A9",
      ];
      let null_count = 0;
      for (let level of this.selected_levels) {
        if (item[level] == null) {
          null_count++;
        }
      }
      return grey_notches[null_count];
    },
    level_val: function (item, col) {
      let idx = this.selected_levels.indexOf(col);
      let hide = false;
      for (let i = this.selected_levels.length - 1; i > idx; i--) {
        if (item[this.selected_levels[i]] != null) {
          hide = true;
        }
      }
      if (hide) {
        return "";
      } else {
        return item[col];
      }
    },
    reset_table: async function () {
      this.tbl_avaialable = false;

      let date_range = {
        start_date: this.start_date,
        end_date: this.end_date,
      };
      let token = await this.$auth.getTokenSilently();
      let breakdown_data = await api_caller
        .get_breakdown_data(
          token,
          this.$project.get_val(),
          this.data_breakdown_timing,
          date_range,
          this.selected_levels,
          this.data_filters
        )
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          }
          return false;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
      if (!breakdown_data) {
        this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
        return;
      }
      this.performanceData = breakdown_data;
      let level_values = [];
      let level_name = "";
      let valid = false;
      for (let row of this.performanceData) {
        level_name = "";
        valid = true;
        for (let l of this.selected_levels) {
          if (row[l]) {
            level_name += row[l];
          } else {
            valid = false;
          }
          if (row[l] != null) {
            level_name += "__";
          }
        }
        if (valid) {
          level_values.push(level_name.slice(0, -2));
        }
      }
      this.switch_data_breakdown(this.data_breakdown_type);
      this.$emit("level_update", {
        data_levels: this.available_data_levels,
        selected_levels: this.selected_levels,
        level_values: level_values,
      });
      setTimeout(() => (this.tbl_avaialable = true), 100);
    },
    update_start_date(date) {
      this.$emit("date_update", { start_date: date, end_date: this.end_date });
    },
    update_end_date(date) {
      this.$emit("date_update", { start_date: this.start_date, end_date: date });
    },
    update_date(date) {
      this.$emit("date_update", { start_date: date[0], end_date: date[1] });
    },
    switch_data_breakdown(type) {
      this.data_breakdown_type = type;

      if (type == "spend") {
        this.numericColumns = [];
        this.currencyColumns = this.columnList;
      } else {
        this.numericColumns = this.columnList;
        this.currencyColumns = [];
      }
      this.process_breakdown_data();
    },
    process_breakdown_data() {
      let tempData = JSON.parse(JSON.stringify(this.performanceData));
      for (let row of tempData) {
        for (const obj in row) {
          if (!this.selected_levels.includes(obj)) {
            row[obj] = row[obj][this.data_breakdown_type];
          }
        }
      }
      this.processedData = tempData;
    },
    handle_row_click: function (value) {
      let channel = {
        data_levels: [],
        data_query: [],
      };
      let levels = [];
      for (let level of this.selected_levels) {
        if (value[level]) {
          channel["data_query"].push({
            level: level,
            value: value[level],
          });
          levels.push(level);
        } else {
          return;
        }
      }
      channel["data_levels"] = levels;
      this.$emit("switch_tab", "graph", channel);
    },
  },
};
</script>

<style>
.table-title {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 1.5em;
}

.data_breakdown_chip {
  font-family: Poppins;
  color: white !important;
  font-weight: 600;
  width: 125px;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
}

.toolbar_title {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 1.1em;
}
.toolbar_selector {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 0.9 em;
}
.v-list-item .v-list-item__title {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 0.85em;
}

.filter_title {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1em;
}
.filter_vals {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 0.9em;
}
</style>
