var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-6 pt-0 pb-3",staticStyle:{"max-width":"100%","max-height":"74vh","overflow-y":"scroll"}},[_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"max-height":"10%"},attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"main-page-title mx-3"},[_vm._v("My Report")]),_c('v-chip',{staticClass:"report-version",attrs:{"small":""}},[_vm._v(_vm._s(_vm.data_version))])],1)],1),_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"max-height":"90%"}},[_c('v-row',{staticClass:"ma-0 my-12 pa-0",staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('span',{staticClass:"report-subtitle"},[_vm._v("Channel Spend, Impressions & Contribution Overview")])]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.spend_impr_contribution_overview_comments = true;
            _vm.spend_impr_contribution_col_size = 8;}}},[_c('v-icon',[_vm._v("mdi-comment-edit-outline")])],1)],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mx-12"})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":_vm.spend_impr_contribution_col_size}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"graph-title"},[_vm._v("Spend")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('GChart',{attrs:{"type":"PieChart","data":_vm.pie_chart_data['spend'],"options":_vm.pie_chart_config,"resizeDebounce":50}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"graph-title"},[_vm._v("Impressions")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('GChart',{attrs:{"type":"PieChart","data":_vm.pie_chart_data['impressions'],"options":_vm.pie_chart_config,"resizeDebounce":50}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"graph-title"},[_vm._v("Contribution")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('GChart',{attrs:{"type":"PieChart","data":_vm.pie_chart_data['attribution'],"options":_vm.pie_chart_config,"resizeDebounce":50}})],1)],1)],1)],1),_c('v-spacer'),(_vm.spend_impr_contribution_overview_comments)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticStyle:{"height":"25px","width":"25px"},attrs:{"icon":""},on:{"click":function($event){_vm.spend_impr_contribution_overview_comments = false;
                    _vm.spend_impr_contribution_col_size = 12;}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{staticClass:"reportCommentContent",attrs:{"three-line":""}},[_vm._l((_vm.report_comments.spend_impr_contribution_overview),function(entry){return [(entry.header)?_c('v-subheader',{key:entry.header,domProps:{"textContent":_vm._s(entry.header)}}):_c('v-list-item',{key:entry.name},[_c('v-list-item-avatar',{attrs:{"color":"black","size":"36"}},[_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(_vm.getUserInitial(entry.name)))])]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(entry.name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(entry.content)}})],1)],1)]})],2),_c('v-text-field',{staticClass:"reportCommentInputField",attrs:{"label":"Add comment","placeholder":"Add comment"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onCommentSubmit('spend_impr_contribution_overview')}},model:{value:(_vm.new_spend_impr_contribution_overview_comment),callback:function ($$v) {_vm.new_spend_impr_contribution_overview_comment=$$v},expression:"new_spend_impr_contribution_overview_comment"}})],1)],1)],1):_vm._e()],1),_c('v-row',{staticClass:"ma-0 my-12 pa-0",staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('span',{staticClass:"report-subtitle"},[_vm._v("Format Spend, Impressions & Contribution by Quarter")])]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.spend_impr_contribution_environmental_comments = true;
            _vm.spend_impr_contribution_environmental_col_size = 8;}}},[_c('v-icon',[_vm._v("mdi-comment-edit-outline")])],1)],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mx-12"})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":_vm.spend_impr_contribution_environmental_col_size}},[_c('v-row',{staticClass:"ma-0 pa-0"},_vm._l((Object.entries(_vm.spend_impr_contribution_data)),function(value,index){return _c('v-col',{key:index,attrs:{"cols":"3"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"graph-title"},[_vm._v(_vm._s(value[0]))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('GChart',{attrs:{"type":"ColumnChart","data":value[1],"options":_vm.spend_impr_contribution_environmental_config,"resizeDebounce":50}})],1)],1)}),1)],1),_c('v-spacer'),(_vm.spend_impr_contribution_environmental_comments)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticStyle:{"height":"25px","width":"25px"},attrs:{"icon":""},on:{"click":function($event){_vm.spend_impr_contribution_environmental_comments = false;
                    _vm.spend_impr_contribution_environmental_col_size = 12;}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{staticClass:"reportCommentContent",attrs:{"three-line":""}},[_vm._l((_vm.report_comments.spend_impr_contribution_environmental),function(entry){return [(entry.header)?_c('v-subheader',{key:entry.header,domProps:{"textContent":_vm._s(entry.header)}}):_c('v-list-item',{key:entry.name},[_c('v-list-item-avatar',{attrs:{"color":"black","size":"36"}},[_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(_vm.getUserInitial(entry.name)))])]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(entry.name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(entry.content)}})],1)],1)]})],2),_c('v-text-field',{staticClass:"reportCommentInputField",attrs:{"label":"Add comment","placeholder":"Add comment"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onCommentSubmit('spend_impr_contribution_environmental')}},model:{value:(_vm.new_spend_impr_contribution_environmental_comment),callback:function ($$v) {_vm.new_spend_impr_contribution_environmental_comment=$$v},expression:"new_spend_impr_contribution_environmental_comment"}})],1)],1)],1):_vm._e()],1),_c('v-row',{staticClass:"ma-0 my-12 pa-0",staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('span',{staticClass:"report-subtitle"},[_vm._v("ROI / Contribution Overview")])]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.roi_contribution_comments = true;
            _vm.roi_contribution_col_size = 8;}}},[_c('v-icon',[_vm._v("mdi-comment-edit-outline")])],1)],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mx-12"})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":_vm.roi_contribution_col_size}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"graph-title"},[_vm._v("Format ROI")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('GChart',{attrs:{"type":"ColumnChart","data":_vm.roi_data,"options":_vm.roi_contribution_config,"resizeDebounce":50}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"graph-title"},[_vm._v("Format Contribution")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('GChart',{attrs:{"type":"ColumnChart","data":_vm.roi_data,"options":_vm.roi_contribution_config,"resizeDebounce":50}})],1)],1)],1)],1),_c('v-spacer'),(_vm.roi_contribution_comments)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticStyle:{"height":"25px","width":"25px"},attrs:{"icon":""},on:{"click":function($event){_vm.roi_contribution_comments = false;
                    _vm.roi_contribution_col_size = 12;}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{staticClass:"reportCommentContent",attrs:{"three-line":""}},[_vm._l((_vm.report_comments.roi_contribution),function(entry){return [(entry.header)?_c('v-subheader',{key:entry.header,domProps:{"textContent":_vm._s(entry.header)}}):_c('v-list-item',{key:entry.name},[_c('v-list-item-avatar',{attrs:{"color":"black","size":"36"}},[_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(_vm.getUserInitial(entry.name)))])]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(entry.name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(entry.content)}})],1)],1)]})],2),_c('v-text-field',{staticClass:"reportCommentInputField",attrs:{"label":"Add comment","placeholder":"Add comment"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onCommentSubmit('roi_contribution')}},model:{value:(_vm.new_roi_contribution_comment),callback:function ($$v) {_vm.new_roi_contribution_comment=$$v},expression:"new_roi_contribution_comment"}})],1)],1)],1):_vm._e()],1),_c('v-row',{staticClass:"ma-0 my-12 pa-0",staticStyle:{"width":"100%"},attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('span',{staticClass:"report-subtitle"},[_vm._v("Channel Time Series Comparison")])]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.time_series_comments = true;
            _vm.time_series_col_size = 9;}}},[_c('v-icon',[_vm._v("mdi-comment-edit-outline")])],1)],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mx-12"})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":_vm.time_series_col_size}},[_c('v-col',{attrs:{"cols":"12"}},[_c('GChart',{attrs:{"type":"AreaChart","data":_vm.time_series_data,"options":_vm.time_series_config,"resizeDebounce":50}})],1)],1),(_vm.time_series_comments)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticStyle:{"height":"25px","width":"25px"},attrs:{"icon":""},on:{"click":function($event){_vm.time_series_comments = false;
                    _vm.time_series_col_size = 12;}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"clearable":"","clear-icon":"mdi-close-circle","label":"Text","value":"This is clearable text."}})],1)],1)],1):_vm._e()],1),_c('v-row',{staticClass:"ma-0 my-12 pa-0",staticStyle:{"width":"100%"},attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('span',{staticClass:"report-subtitle"},[_vm._v("Format Response Curves")])]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.response_curve_comments = true;
            _vm.response_curve_col_size = 9;}}},[_c('v-icon',[_vm._v("mdi-comment-edit-outline")])],1)],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mx-12"})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":_vm.response_curve_col_size}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},_vm._l((Object.entries(_vm.response_curve_data)),function(value,index){return _c('v-col',{key:index,staticClass:"mx-3",attrs:{"cols":"2"}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"graph-title"},[_vm._v(_vm._s(value[0].split("__")[1]))])]),_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"height":"300px"},attrs:{"cols":"12"}},[_c('GChart',{staticStyle:{"height":"300px"},attrs:{"type":"LineChart","data":value[1],"options":_vm.response_curve_config,"resizeDebounce":50}})],1)],1)}),1)],1),(_vm.response_curve_comments)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticStyle:{"height":"25px","width":"25px"},attrs:{"icon":""},on:{"click":function($event){_vm.response_curve_comments = false;
                    _vm.response_curve_col_size = 12;}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"clearable":"","clear-icon":"mdi-close-circle","label":"Text","value":"This is clearable text."}})],1)],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }