<template>
  <v-container
    class="ma-0 pa-0"
    fluid
    style="max-height: calc(100vh - 93px); overflow-y: scroll; width: 100%"
  >
    <div v-if="showAlert">
        <v-alert
          v-model="showAlert"
          border="left"
          close-text="Close Alert"
          dismissible
          :type="alertType"
        >
          {{ alertMsg }}
        </v-alert>
    </div>
    
    <v-row class="ma-0 pa-0" style="height: 100%">
      <v-col cols="12" class="ma-0 pa-0" style="height: 100%">
        <!-- Title And Highlights Row-->
        <v-row style="min-height: 10%; max-height: 20" class="ma-0 px-6 pt-4 pb-2">
          <!-- Title Column-->
          <v-col cols="12" class="ma-0 pa-0">
            <v-row width="100%" class="ma-0 pa-0">
              <v-col cols="auto" class="ma-0 pa-0">
                <v-img :src="main_logo" max-height="48px" max-width="80px" contain></v-img>
              </v-col>
              <v-col cols="auto" class="ma-0 pa-0">
                <span class="page-title">Performance</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-col sm="6" md="5" lg="4" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;">
                  <v-col cols="4" class="ma-0 pa-0">
                    <PerformanceMainStatistic statistic_title_name="Attribution" :value="total_attribution"/>
                  </v-col>
                  <v-col cols="4" class="ma-0 pa-0">
                    <PerformanceMainStatistic statistic_title_name="ROAS" :value="total_roas"/>
                  </v-col>
                  <v-col cols="4" class="ma-0 pa-0">
                    <PerformanceMainStatistic statistic_title_name="Spend" :value="total_spend"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row width="100%" class="ma-0 pa-0">
              <v-tabs v-model="performance_tab" align-tabs="start" light>
                <v-tab class="tool-navigation-bar" href="#performance">My Portfolio</v-tab>
                <v-tab class="tool-navigation-bar" href="#graph">Dashboard</v-tab>
                <v-tab class="tool-navigation-bar" href="#data">Blocking Chart</v-tab>
                <!-- <v-tab class="tool-navigation-bar" href="#report">My Report</v-tab> -->
              </v-tabs>
            </v-row>
          </v-col>
          <!-- <v-col cols="4" class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <PerformanceMainStatistic statistic_title_name="Attribution" :value="total_attribution"/>
              </v-col>
              <v-col cols="4" class="ma-0 pa-0">
                <PerformanceMainStatistic statistic_title_name="ROAS" :value="total_roas"/>
              </v-col>
              <v-col cols="4" class="ma-0 pa-0">
                <PerformanceMainStatistic statistic_title_name="Spend" :value="total_spend"/>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
        <v-tabs-items
          class="ma-0 pa-0"
          v-model="performance_tab"
          style="height: 80%; max-height: 80%; width: 100%"
        >
          <v-tab-item style="height: 100%; max-height: 100%; width: 100%" value="performance">
            <v-row width="100%" style="min-height: 10%" class="ma-0 pa-0">
              <PerformanceStatsTable  :project_date_range="current_project_date_range"
                                      :data_levels="current_project_levels" :data_version="current_project_version" 
                                      v-on:switch_tab="switch_tab" v-on:level_update="level_update" v-on:date_update="date_update" v-on:filter_update="update_data_filters"
                                      v-on:triggerAlert="triggerAlert"
                                    />
            </v-row>
          </v-tab-item>
          <v-tab-item style="height: 100%; max-height: 100%; width: 100%" value="graph">
            <v-row width="100%" style="min-height: 10%" class="ma-0 pa-0">
              <PerformanceStatsGraph :project_date_range="current_project_date_range"
                                     :selected_channel="selected_channel"
                                     :data_levels="current_project_levels"
                                     :data_filters="current_project_filter_values"
                                     v-on:triggerAlert="triggerAlert"
                                     />
            </v-row>
          </v-tab-item>
          <v-tab-item style="height: 100%; max-height: 100%; width: 100%" value="data">
            <v-row width="100%" style="min-height: 10%" class="ma-0 pa-0">
              <PerformanceDataBreakdown :project_date_range="current_project_date_range"
                                        :data_levels="current_project_levels" :data_version="current_project_version" :data_filters="current_project_filter_values"
                                        v-on:switch_tab="switch_tab" v-on:level_update="level_update" v-on:date_update="date_update"
                                        v-on:triggerAlert="triggerAlert"
                                        />
            </v-row>
          </v-tab-item>
          <!-- <v-tab-item value="report"> 
            <v-row width="100%" style="min-height: 10%" class="ma-0 pa-0">
              <PerformanceReport  :project_date_range="current_project_date_range"
                                  :data_levels="current_project_levels" :data_version="current_project_version"
                                  v-on:level_update="level_update" v-on:date_update="date_update"/>
            </v-row>
          </v-tab-item> -->
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import PerformanceMyStudies from "./PerformanceStats/PerformanceMyStudies.vue";
import PerformanceStatsTable from "./PerformanceStats/PerformanceStatsTable.vue";
import PerformanceStatsGraph from "./PerformanceStats/PerformanceStatsGraph.vue";
import PerformanceDataBreakdown from "./PerformanceStats/PerformanceDataBreakdown.vue";
// import PerformanceReport from "./PerformanceStats/PerformanceReportU.vue"

import PerformanceMainStatistic from "./PerformanceStats/PerformanceReportSections/PerformanceMainStatistic.vue";
// import AudienceHighlightCard from "../Audiences/AudienceStats/AudienceHighlightCard.vue";
import performance_table_data from "@/input_data/performance_table_data.json";

import logo from "@/assets/PerformanceLogo.svg";

import api_caller from "@/javascript/data_info.js"
import api_caller_data from "@/javascript/data_retrieval.js"

export default {
  name: "PerformanceBasePage",
  components: {
    // PerformanceMyStudies,
    PerformanceStatsTable,
    PerformanceStatsGraph,
    PerformanceDataBreakdown,
    // PerformanceReport,
    PerformanceMainStatistic
    // AudienceHighlightCard,
  },
  mounted() {
    this.reset_project_data();
  }, 
  data: () => ({
    main_logo: logo,
    performance_tab: "",
    dropdown_configs: performance_table_data["dropdown_options"],
    selected_channel: null,
    alertMsg: null,
    alertType: null,
    showAlert: false,

    //All Project Variables:
    saved_projects: [],

    //Current Project Variables
    current_project_id: null,
    current_project_version: null,
    current_project_date_range: {'start_date': null, 'end_date': null, 'min_start_date': null, 'max_end_date': null},
    current_project_levels: {
      'data_levels': [],
      'selected_levels': [],
      'level_values': []
    },
    current_project_filter_values: {},

    total_attribution: null,
    total_roas: null, 
    total_spend: null
  }),
  methods: {
    // reset_client_environment: async function(){
    //   this.saved_projects = await api_caller.get_saved_projects(this.$api_token.get_val(), this.$client.get_val()).then((resp)=>{
    //                           return resp['data']
    //                       }).catch((e)=>{
    //                           console.log(e)
    //                           return 'False'
    //                       });
    //   this.current_project_id = this.saved_projects[0]['project_id'];
    //   this.reset_project_data();
    // },
    reset_project_data: async function(){
      if(this.$project.get_val() == null){
        return;
      }
      let token = await this.$auth.getTokenSilently();
      let project_info = await api_caller.get_project_info(token, this.$project.get_val()).then((resp)=>{
                              return resp['data']
                          }).catch((e)=>{
                              console.log(e)
                              return 'False'
                          });
      this.current_project_id = project_info['project_id'];
      this.current_project_version = project_info['version'];
      this.current_project_date_range= {'start_date': project_info['start_date'], 'end_date': project_info['end_date'], 'min_start_date': project_info['start_date'], 'max_end_date': project_info['end_date']};
      this.current_project_levels = {
        'data_levels': project_info['data_levels'],
        'selected_levels': [project_info['data_levels'][0]],
        'level_values': []
      };

      await this.get_project_highlights();

    },
    get_project_highlights: async function(){
      if(this.$project.get_val() == null){
        return;
      }
      let token = await this.$auth.getTokenSilently();
      let date_range = {
        'start_date': this.current_project_date_range['start_date'],
        'end_date': this.current_project_date_range['end_date'],
      }
      let highlight_data = await api_caller_data.get_highlights_data(token, this.$project.get_val(), date_range, this.current_project_filter_values).then((resp)=>{
                              return resp['data']
                          }).catch((e)=>{
                              console.log('Error',e)
                              return 'False'
                          });
      this.total_attribution = highlight_data['attribution'];
      this.total_roas = highlight_data['roas'];
      this.total_spend = highlight_data['spend'];
    },
    switch_tab: function (tab, value) {
      this.performance_tab = tab;
      this.selected_channel = value;
    },
    level_update: function(levels){
      this.current_project_levels = levels;
    },
    triggerAlert: function(type, message){
      this.alertType = type ? type : "info";
      this.alertMsg = message;
      this.showAlert = true;
    },
    date_update: function(date){
      this.current_project_date_range['start_date'] = date['start_date'];
      this.current_project_date_range['end_date'] = date['end_date'];
      this.get_project_highlights();
    },
    update_data_filters: function(data_filters){
      this.current_project_filter_values = data_filters;
      this.get_project_highlights();
    }
  },
};
</script>

<style scoped>
  .page-title {
    font-family: "Poppins" !important;
    font-weight: 800;
    font-size: 2em;
  }

  .drop-down-filter {
    margin-top: -20px;
    padding-top: 0px;
  }

  .tool-navigation-bar {
    font-family: "Poppins" !important;
    font-weight: 400 !important;
    font-size: 0.9em !important;
  }

  /deep/.v-window__container {
    height: 100% !important;
  }
  .statistic_value_text{
    font-family: "Amiko";
    font-size: 30pt;
  }
  .statistic_title{
    font-family: "Amiko";
    font-size: 10pt;
  }
</style>