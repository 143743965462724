<template>
  <v-container
    class="px-6 pt-0 pb-3"
    style="max-width: 100%; height: 74vh; overflow-y: scroll"
  >
    <v-row class="ma-0 pa-0">
      <v-col cols="6">
        <span class="main-page-title mx-3">My Report</span>
        <v-chip class="report-version" small>{{data_version}}</v-chip>
      </v-col>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon disabled v-on="on" @click="report_comments_persistent=!report_comments_persistent; reset_table();"> 
            <v-icon color="black">mdi-comment-edit-outline</v-icon>
          </v-btn>
        </template>
        <TooltipDescription title="Persistent Comments"></TooltipDescription>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="report_legend_visible=!report_legend_visible; reset_table();"> 
            <v-icon color="black">mdi-map-legend</v-icon>
          </v-btn>
        </template>
        <TooltipDescription title="Report Legend"></TooltipDescription>
      </v-tooltip>
    </v-row>
    <v-row style="max-height: 90%; height: 90%;" class="ma-0 pa-0" justify="space-around">
      <v-item-group
        v-model="current_graph"
        class="shrink mr-6 my-6"
        mandatory
      >
        <v-item v-slot="{ active, toggle }" class="my-2">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn icon :input-value="active" @click="toggle" v-on="on">
                  <v-icon color="black">mdi-filter</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Toolbar"></TooltipDescription>
            </v-tooltip>
          </div>
        </v-item>
        <v-item v-slot="{ active, toggle }" class="my-2">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn icon :input-value="active" @click="toggle" v-on="on" :disabled="!report_available">
                  <v-icon color="black">mdi-chart-pie</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Spend, Impressions & Contribution"></TooltipDescription>
            </v-tooltip>
          </div>
        </v-item>
        <v-item v-slot="{ active, toggle }" class="my-2">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn icon :input-value="active" @click="toggle" v-on="on" :disabled="!report_available">
                  <v-icon color="black">mdi-chart-bar-stacked</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Quarterly Spend, Impressions & Contribution"></TooltipDescription>
            </v-tooltip>
          </div>
        </v-item>
        <v-item v-slot="{ active, toggle }" class="my-2">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn icon :input-value="active" @click="toggle" v-on="on" :disabled="!report_available">
                  <v-icon color="black">mdi-chart-bar</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="ROAS & Contribution"></TooltipDescription>
            </v-tooltip>
          </div>
        </v-item>
        <v-item v-slot="{ active, toggle }" class="my-2">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn icon :input-value="active" @click="toggle" v-on="on" :disabled="!report_available">
                  <v-icon color="black">mdi-chart-areaspline-variant</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Time Series Contribution Comparison"></TooltipDescription>
            </v-tooltip>
          </div>
        </v-item>
        <!-- <v-item v-slot="{ active, toggle }" class="my-2">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn icon :input-value="active" @click="toggle" v-on="on" disabled>
                  <v-icon color="black">mdi-chart-line-stacked</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Time Series CPM Comparison"></TooltipDescription>
            </v-tooltip>
          </div>
        </v-item>
        <v-item v-slot="{ active, toggle }" class="my-2">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn icon :input-value="active" @click="toggle" v-on="on" disabled>
                  <v-icon color="black">mdi-chart-areaspline</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Time Series CPM Comparison"></TooltipDescription>
            </v-tooltip>
          </div>
        </v-item> -->
        <v-item v-slot="{ active, toggle }" class="my-2">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn icon :input-value="active" @click="toggle" v-on="on" >
                  <v-icon color="black">mdi-chart-multiple</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Response Curves"></TooltipDescription>
            </v-tooltip>
          </div>
        </v-item>
        <v-item v-slot="{ active, toggle }" class="my-2">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn icon :input-value="active" @click="toggle" v-on="on" disabled>
                  <v-icon color="black">mdi-matrix</v-icon>
                </v-btn>
              </template>
              <TooltipDescription title="Correlation"></TooltipDescription>
            </v-tooltip>
          </div>
        </v-item>
      </v-item-group>
      <!-- <v-col v-if="!report_available" align="center" style="height: 100%; display: flex; align-items: center; justify-content: center">
        <v-progress-circular
                    size="100"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
      </v-col> -->
      <v-col style="height: 100%">
        <v-window v-model="current_graph" vertical style="height: 100%">
          <v-window-item class="pa-1 ma-0" style="height: 100%">
            <v-card>
              <v-card-title>
                <v-icon class="ml-0 mr-3" color="black">mdi-filter-settings-outline</v-icon>
                <span>Toolbar</span>
              </v-card-title>
              <v-row style="width: 100%;" class="ma-0 pa-0">
                <v-col cols="12">
                    <v-select disabled :value="data_version" label="Data Version" hint="The data version of the blocking chart" persistent-hint :items="[data_version]" @change="reset_table()"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="selected_levels"
                    :items="available_data_levels"
                    label="Levels"
                    multiple
                    hint="Pick the level of granularity you wish to view the data."
                    persistent-hint
                    chips
                    :disabled="available_data_levels.length == 1"
                    @change="update_selected_levels"
                  >
                  </v-select>
                </v-col>
                <v-col class="mr-0 ml-auto my-0 py-0 mx-3" :cols="12 - (idx)" v-for="val, idx in selected_levels" :key="idx">
                  <v-icon class="mb-3" color="black">mdi-arrow-right-bottom-bold</v-icon>
                  <span>{{val}}</span>
                </v-col>
                <v-col cols="12">
                  <DateRange :start_date="start_date" :end_date="end_date"
                            :min_start_date="project_date_range['min_start_date']" :max_end_date="project_date_range['max_end_date']"
                            v-on:start_date_update="update_start_date" v-on:end_date_update="update_end_date"></DateRange>
                </v-col>
              </v-row>
            </v-card>
          </v-window-item>
          <v-window-item class="pa-1 ma-0" style="height: 100%">
            <SpendImpressionsContribution v-if="report_available" :dataset="report_dataset['spend_impressions_contribution']" :comments_persistent="report_comments_persistent" :color_palette="color_palette"></SpendImpressionsContribution>
            <v-col v-if="!report_available" align="center" style="height: 100%; display: flex; align-items: center; justify-content: center">
              <v-progress-circular
                          size="100"
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
            </v-col>
          </v-window-item>
          <v-window-item class="pa-1 ma-0" style="height: 100%">
            <SpendImpressionsContributionQuarterly v-if="report_available" :dataset="report_dataset['spend_impressions_contribution_timing']" :comments_persistent="report_comments_persistent" :color_palette="color_palette"></SpendImpressionsContributionQuarterly>
            <v-col v-if="!report_available" align="center" style="height: 100%; display: flex; align-items: center; justify-content: center">
              <v-progress-circular
                          size="100"
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
            </v-col>
          </v-window-item>
          <v-window-item class="pa-1 ma-0" style="height: 100%">
            <ROASContribution v-if="report_available" :dataset="report_dataset['roas_contribution']" :comments_persistent="report_comments_persistent" :color_palette="color_palette"></ROASContribution>
            <v-col v-if="!report_available" align="center" style="height: 100%; display: flex; align-items: center; justify-content: center">
              <v-progress-circular
                          size="100"
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
            </v-col>
          </v-window-item>
          <v-window-item class="pa-1 ma-0" style="height: 100%">
            <TimeSeries v-if="report_available" :dataset="report_dataset['time_series_comparison']" :comments_persistent="report_comments_persistent" :color_palette="color_palette"></TimeSeries>
            <v-col v-if="!report_available" align="center" style="height: 100%; display: flex; align-items: center; justify-content: center">
              <v-progress-circular
                          size="100"
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
            </v-col>
          </v-window-item>
          <v-window-item class="pa-1 ma-0" style="height: 100%">
            <ResponseCurves v-if="response_curves_available" :dataset="report_dataset['response_curves']" :color_palette="color_palette"></ResponseCurves>
            <v-col v-if="!response_curves_available" align="center" style="height: 100%; display: flex; align-items: center; justify-content: center">
              <v-progress-circular
                          size="100"
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
            </v-col>
          </v-window-item>
          <v-window-item class="pa-1 ma-0" style="height: 100%">
            <CorrelationMatrix></CorrelationMatrix>
          </v-window-item>
        </v-window>
      </v-col>
      <!-- <v-col cols="8" v-if="toolbar">
        <v-card>
              <v-card-title>
                <v-icon class="ml-0 mr-3" color="black">mdi-filter-settings-outline</v-icon>
                <span>Toolbar</span>
              </v-card-title>
              <v-row style="width: 100%;" class="ma-0 pa-0">
                <v-col cols="12">
                    <v-select disabled :value="data_version" label="Data Version" hint="The data version of the blocking chart" persistent-hint :items="[data_version]" @change="reset_table()"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="selected_levels"
                    :items="available_data_levels"
                    label="Levels"
                    multiple
                    hint="Pick the level of granularity you wish to view the data."
                    persistent-hint
                    chips
                    :disabled="available_data_levels.length == 1"
                    @change="update_selected_levels"
                  >
                  </v-select>
                </v-col>
                <v-col class="mr-0 ml-auto my-0 py-0 mx-3" :cols="12 - (idx)" v-for="val, idx in selected_levels" :key="idx">
                  <v-icon class="mb-3" color="black">mdi-arrow-right-bottom-bold</v-icon>
                  <span>{{val}}</span>
                </v-col>
                <v-col cols="12">
                  <DateRange :start_date="start_date" :end_date="end_date"
                            :min_start_date="project_date_range['min_start_date']" :max_end_date="project_date_range['max_end_date']"
                            v-on:start_date_update="update_start_date" v-on:end_date_update="update_end_date"></DateRange>
                </v-col>
              </v-row>
            </v-card>
      </v-col> -->
      <v-col cols="3" v-if="report_legend_visible" style="height: 100%;" class="pa-4">
        <v-card style="height: 100%; overflow-y: scroll;">
          <v-card-title class="legend-title">Report Legend</v-card-title>
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="ma-0 py-1" v-for="lbl, idx in report_dataset['level_values']" :key="idx">
              <v-icon class="mr-3" :color="legend_colors[idx]">mdi-circle</v-icon>
              <span class="legend-entry">{{ lbl.replaceAll('__',' - ').replaceAll('_',' ') }}</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TooltipDescription from "@/components/Utility/TooltipDescription.vue";

import SpendImpressionsContribution from "../PerformanceReportSections/spend_impressions_contribution.vue";
import SpendImpressionsContributionQuarterly from "../PerformanceReportSections/spend_impressions_contribution_barChart.vue";
import ROASContribution from "../PerformanceReportSections/roas_contribution.vue";
import TimeSeries from "../PerformanceReportSections/time_series_comparison.vue";
// import TimeSeriesLine from "./PerformanceReportSections/time_series_line_comparison.vue";
import ResponseCurves from "../PerformanceReportSections/response_curves.vue";
import CorrelationMatrix from "../PerformanceReportSections/correlation.vue"
import cpalette from "@/configs/ChartColors/get_color_palette.js";

import DateRange from "@/components/Utility/DateRange.vue";

import api_caller from "@/javascript/data_retrieval.js"

export default {
  name: "PerformanceReport",
  props: [
      'data_version',
      'data_levels',
      'project_start_date',
      'project_end_date',
      'project_date_range'
  ],
  components: {
    // GChart,
    TooltipDescription,
    SpendImpressionsContribution,
    SpendImpressionsContributionQuarterly,
    ROASContribution,
    TimeSeries,
    ResponseCurves,
    CorrelationMatrix,
    DateRange
  },

  data: () => ({
    report_available: false, 
    response_curves_available: false,

    current_graph: 1,

    selected_levels: [],

    legend_points: ["TV", "Radio", "OOH", "Social", "Programmatic"],
    legend_colors: [],
    color_palette: 'retro',

    report_legend_visible: false,
    report_comments_persistent: false,

    start_date: null,
    end_date: null,

    report_dataset: {
      'spend_impressions_contribution': null,
      'spend_impressions_contribution_timing': null,
      'roas_contribution': null, 
      'time_series_comparison': null,
      'time_series_comparison_roas': null,
      'response_curves': null,
      'level_values': []
    },

    data_loading: false
  }),
  mounted() {
    let colors = cpalette.get_color_palette(this.color_palette);
    this.legend_colors = colors;

    this.start_date = this.project_date_range['start_date'];
    this.end_date = this.project_date_range['end_date'];
    this.selected_levels = [... this.data_levels['selected_levels']];
    this.reset_data();
  },
  watch: {
      project_date_range:{
        handler: function () {
          this.start_date = this.project_date_range['start_date'];
          this.end_date = this.project_date_range['end_date'];
          this.reset_data();
        },
        deep: true
      },
      data_levels: {
        handler: function () {
          if(this.data_levels['selected_levels'].toString() != this.selected_levels.toString()){
            this.selected_levels = this.data_levels['selected_levels'];
            this.reset_data();
          }
        },
        deep: true
      },
      current_graph: {
        handler: function() {
          if(this.current_graph > 0){
            if(this.current_graph == 1 && this.report_dataset['spend_impressions_contribution'] == null){
              this.reset_data();
            }else if (this.current_graph == 2 && this.report_dataset['spend_impressions_contribution_timing'] == null){
              this.reset_data();
            }else if (this.current_graph == 3 && this.report_dataset['roas_contribution'] == null){
              this.reset_data();
            }else if (this.current_graph == 4 && this.report_dataset['time_series_comparison'] == null){
              this.reset_data();
            }else if (this.current_graph == 5 && this.report_dataset['response_curves'] == null){
              this.reset_data();
            }
          }
          this.reset_table();
        }
      }
  },
  computed: {
    available_data_levels: function() {
        return this.data_levels['data_levels'];
    }
  },
  methods: {
    reset_table: function(){
      this.report_available = false;
      setTimeout(() => (this.report_available = true), 100);
    },
    reset_data: async function(){
      let date_range = {
        'start_date': this.start_date,
        'end_date': this.end_date
      }
      if(this.current_graph == 0){
        this.report_dataset = {
          'spend_impressions_contribution': null,
          'spend_impressions_contribution_timing': null,
          'roas_contribution': null, 
          'time_series_comparison': null,
          'time_series_comparison_roas': null,
          'response_curves': null,
          'level_values': []
        }
      }
      else if(this.current_graph < 5){
        this.report_available = false;
        let token = await this.$auth.getTokenSilently();
        let report_data = await api_caller.get_report_data(token, this.$project.get_val(), date_range, this.selected_levels).then((resp)=>{
                                if(typeof resp == 'string'){
                                  resp = JSON.parse(resp);
                                }
                                return resp['data']
                            }).catch((e)=>{
                                console.log(e)
                                return 'False'
                            });
        this.report_dataset = report_data;
        this.report_available = true;
        this.$emit('level_update', {'data_levels': this.available_data_levels, 'selected_levels': this.selected_levels, 'level_values': this.report_dataset['level_values']});
      }else if(this.current_graph == 5){
        this.response_curves_available = false;
        let token = await this.$auth.getTokenSilently();
        let report_data = await api_caller.get_response_curves_data(token, this.$project.get_val(), date_range, this.selected_levels).then((resp)=>{
                                if(typeof resp == 'string'){
                                  resp = JSON.parse(resp);
                                }
                                return resp['data']
                            }).catch((e)=>{
                                console.log(e)
                                return 'False'
                            });
        this.report_dataset['response_curves'] = report_data['response_curves'];
        this.response_curves_available = true;
        this.$emit('level_update', {'data_levels': this.available_data_levels, 'selected_levels': this.selected_levels, 'level_values': report_data['level_values']});
      }
    },
    update_selected_levels: function(){
      this.reset_data();
    },
    update_start_date(date){
      this.start_date = date;
      this.$emit('date_update', {'start_date': this.start_date, 'end_date': this.end_date});
    },
    update_end_date(date){
      this.end_date = date;
      this.$emit('date_update', {'start_date': this.start_date, 'end_date': this.end_date});
    }
  },
};
</script>

<style scoped>
.main-page-title {
  /* line-height: 5em; */
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.5em;
}
.report-subtitle {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 1.5em;
}

.legend-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1em;
  text-decoration: underline;
}
.legend-entry {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 0.9em;
}

.graph-title {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 0.9em;
}

.report-version {
  font-family: "Poppins";
  font-weight: 600;
  align-items: center;
  /* font-size: 0.5em; */
  /* width: 50px; */
}

.performanceChart {
  height: 100%;
  width: 100%;
}

.chartHeader {
  .chartHeaderText {
    font-family: "Poppins" !important;
    font-weight: 600;
    font-size: 1.5em;
  }

  display: flex;
}

.toolBar {
  float: right;
}

.toolButton {
  padding: 0 32px;
  width: 50px;
  color: black;
}

::v-deep .v-overlay__content {
  position: absolute;
  right: 0;
  height: 100%;
  width: 30%;
}

.overlayField {
  padding: 0 16px;
}

.overlayButton {
  float: right;
}

.chartWithOverlay {
  /* position: relative; */
  width: 100%;
  /* height: 500px; */
}
.miniChartWithOverlay {
  /* position: relative; */
  width: 100%;
  height: 75px;
}

.metricToggleOverlay {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 12%;
  left: 90px;
}

.metricToggleRow {
  margin-left: 0;
  margin-right: auto;
}
.metric_toolbar_title {
  font-weight: 800 !important;
}
.metric_toolbar_subtitle {
  font-weight: 600 !important;
}
.metric_toolbar_metric_title {
  line-height: 1em;
  font-weight: 200 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: calc(80%);
  text-align: left;
  overflow: hidden;
}

.tooltip_description {
  font-family: "Poppins" !important;
  font-weight: 200;
}
.long_description {
  font-family: "Poppins" !important;
}

.expansionPanel {
  overflow: none;
}

.reportCommentContent {
  max-height: 350px;
  overflow-y: auto;
}

.reportCommentInputField {
  float: right;
  width: 90%;
}
</style>
