import warm_colors from '@/configs/ChartColors/warm_tones.json'
import cool_colors from '@/configs/ChartColors/cool_tones.json'
import vibrant_colors from '@/configs/ChartColors/vibrant.json'
import vibrant2_colors from '@/configs/ChartColors/vibrant2.json'
import vibrant3_colors from '@/configs/ChartColors/vibrant3.json'
import matte_colors from '@/configs/ChartColors/matte.json'
import matte2_colors from '@/configs/ChartColors/matte2.json'
import matte3_colors from '@/configs/ChartColors/matte3.json'
import pastel_colors from '@/configs/ChartColors/pastel.json'
import nature_colors from '@/configs/ChartColors/nature.json'
import retro from '@/configs/ChartColors/retro.json'
function get_color_palette(palette){
    switch (true){
        case palette === 'vibrant':
            return vibrant_colors;
        case palette === 'warm':
            return warm_colors;
        case palette === 'cool':
            return cool_colors;
        case palette === 'pastel':
            return pastel_colors;
        case palette === 'nature':
            return nature_colors;
        case palette === 'vibrant2':
            return vibrant2_colors;
        case palette === 'vibrant3':
            return vibrant3_colors;
        case palette === 'matte':
            return matte_colors;
        case palette === 'matte2':
            return matte2_colors;
        case palette === 'matte3':
            return matte3_colors;
        case palette === 'retro':
            return retro
        default:
            return matte2_colors
    }
}



export default {get_color_palette}