<template>
    <v-container class="ma-0 pa-0" style="height: 100%; width: 100%">
        <v-card style="height: 100%; width: 100%" flat>
            <v-row justify="space-between">
                <v-col cols="auto" class="ma-0 pa-1">
                    <AudienceHighlightCard HighlightTitle='Deduplicated Populaton' 
                                HighlightDescription="The sum of unique audience members in all target segments combined, removing duplicated instances between different segments." 
                                :HighlightValue=population 
                                :HighlightInfo="{type: 'number'}"></AudienceHighlightCard>
                </v-col>
                <v-col cols="auto" class="ma-0 pa-1">
                    <AudienceHighlightCard HighlightTitle='WSI Avg' 
                                HighlightDescription="The average word-of-mouth scores from all individual target segments (weighted by population size)." 
                                :HighlightValue=wsi_avg 
                                :HighlightInfo="{min: 80, max: 100, type: 'number'}"></AudienceHighlightCard>
                </v-col>
                <v-col cols="auto" class="ma-0 pa-1">
                    <AudienceHighlightCard HighlightTitle='WAvg Unaware %' 
                                HighlightDescription="The average percentage of the deduplicated total population that is unaware of the brand's product/service (weighted by population size)." 
                                :HighlightValue=unaware 
                                :HighlightInfo="{min: 10, max: 60, type: 'percentage'}"></AudienceHighlightCard>
                </v-col>
                <v-col cols="auto" class="ma-0 pa-1">
                    <AudienceHighlightCard HighlightTitle='WAvg Aware %' 
                                HighlightDescription="The average percentage of the deduplicated total population that is aware and/or considering the purchase of the brand's product/service (weighted by population size)." 
                                :HighlightValue=aware 
                                :HighlightInfo="{min: 20, max: 50, type: 'percentage'}"></AudienceHighlightCard>
                </v-col>
                <v-col cols="auto" class="ma-0 pa-1">
                    <AudienceHighlightCard HighlightTitle='WAvg Aware Converted %' 
                                HighlightDescription="The average percentage of the deduplicated total population that has purchased the brand's product/service (weighted by population size)." 
                                :HighlightValue=converted 
                                :HighlightInfo="{min: 10, max: 30, type: 'percentage'}"></AudienceHighlightCard>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
    import AudienceHighlightCard from "./AudienceHighlightCard.vue"

    export default {
        name: 'AudienceHighlights',
        props: [
            'audience_data',
            'graph_index'
        ],
        components: {
            AudienceHighlightCard
        },
        mounted(){
            this.aud_highlights_list = this.audience_data['audience_highlights'];
            this.set_highlights(0);
        },
        watch: {
            audience_data: {
                handler: function(updated_audience) {
                    this.aud_highlights_list = updated_audience['audience_highlights'];
                    this.set_highlights(0); 
                },
                deep: true
            },
            graph_index: {
                handler: function(index){
                    this.set_highlights(index);
                }
            }
        },
        data: () => ({
            aud_highlights_list: {},
            population: 0,
            wsi_avg: 0,
            unaware: 0,
            aware: 0,
            converted: 0
        }),
        methods: {
            set_highlights: function(index){
                let highlight = this.aud_highlights_list[index];
                this.population = highlight['population'];
                this.wsi_avg = highlight['wsi'];
                this.unaware = highlight['unaware'];
                this.aware = highlight['aware'];
                this.converted = highlight['converted'];
            }
        }

    }
</script>