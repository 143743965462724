<template>
  <v-container class="px-6 pt-0 pb-3" style="max-width: 100%">
    <v-row class="ma-0 pa-0" style="width: 100%; height: 100%" v-if="project_id">
      <v-col
        :cols="toolbar_visible ? 9 : 12"
        style="width: 100%; height: 100%"
        v-if="initial_data_copied"
      >
        <v-row class="ma-0 pa-0">
          <v-col cols="auto" class="ma-0 pa-0">
            <v-chip
              label
              class="data_breakdown_chip"
              :outlined="data_breakdown_type != 'spend'"
              @click="switch_data_breakdown('spend')"
              color="black"
            >
              Media Spend
            </v-chip>
            <v-chip
              label
              class="data_breakdown_chip"
              :outlined="data_breakdown_type != 'impressions'"
              @click="switch_data_breakdown('impressions')"
              color="black"
            >
              Impressions
            </v-chip>
            <v-chip
              label
              class="data_breakdown_chip"
              :outlined="data_breakdown_type != 'cpm'"
              @click="switch_data_breakdown('cpm')"
              color="black"
            >
              CPM
            </v-chip>
            <!-- <v-chip
              label
              class="data_breakdown_chip"
              :outlined="data_breakdown_type != 'grps'"
              @click="switch_data_breakdown('grps')"
              color="black"
            >
              GRPs
            </v-chip> -->
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="ma-0 px-3">
            <v-select
              style="width: 250px;"
              class="ma-0 pa-0"
              :items="data_breakdown_lock_options"
              v-model="data_breakdown_lock"
              label="Breakdown Lock"
              hide-details
              @change="switch_data_breakdown(data_breakdown_type.toLowerCase())"
            ></v-select>
          </v-col>
          <v-col cols="auto" class="ma-0 px-3">
            <v-select
              style="width: 250px;"
              class="ma-0 pa-0"
              :items="available_data_breakdown_timing"
              v-model="data_breakdown_timing"
              hide-details
              label="Breakdown Timing"
              @change="reset_table()"
            ></v-select>
          </v-col>
          <v-col cols="12" class="ma-0 pa-0" v-if="tbl_avaialable && campaign_plan_id">
            <DataLevelTable
              :tableHeader="headers"
              :tableData="processedData"
              :rowEditable="rowEditable"
              :selectedLevels="selected_levels"
              :tableRowClickFunction="empty_row_click"
              :numericColumns="numericColumns"
              :currencyColumns="currencyColumns"
              :percentageColumns="[]"
              v-on:deleteItem="delete_campaign_plan_data"
              v-on:editItem="edit_campaign_plan_data"
              :short="false"
              :actions_locked="lock_changes"
            />
          </v-col>

          <v-col
            cols="12"
            class="ma-0 pa-0"
            style="height: 500px; width: 100%; align-items: center"
            v-else
          >
            <v-row
              class="ma-0 pa-0"
              justify="center"
              :align="'center'"
              style="height: 100%"
            >
              <v-col cols="auto" class="ma-0 pa-0">
                <v-progress-circular
                  size="100"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="3" v-if="!initial_data_copied"></v-col> -->
      <v-spacer></v-spacer>
      <v-col
        cols="6"
        style="height: 100%"
        v-if="
          !show_create_plan
        "
      >
        <v-card style="height: 550px; max-height: 550px; overflow-y: scroll">
          <v-card-title>
            <span class="toolbar_title">Saved Plans</span>
          </v-card-title>
          <v-row style="height: 85%; width: 100%" class="ma-0 pa-0">
            <v-col cols="12" style="height:85%">
              <v-tabs v-model="campaign_plan_tab" align-tabs="start">
                <v-tab class="prediction_range_tab" key="saved">Saved</v-tab>
                <v-tab class="prediction_range_tab" key="temporary">Temporary</v-tab>
              </v-tabs>
              <v-tabs-items v-model="campaign_plan_tab">
                <v-tab-item>
                  <v-row class="ma-0 pa-0" align="center" justify="space-around">
                    <v-col cols="12">
                      <v-select
                        class="toolbar_selector"
                        item-text="plan_id"
                        item-value="plan_id"
                        label="Saved Plans"
                        v-model="selected_saved_plan"
                        :items="saved_campaign_plans"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-row class="ma-0 pa-0" align="center" justify="space-around">
                    <v-col cols="12">
                      <v-select
                        class="toolbar_selector"
                        item-text="plan_id"
                        item-value="plan_id"
                        label="Temporary Plans"
                        v-model="selected_temporary_plan"
                        :items="temporary_campaign_plans"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
            <v-col cols="12" style="display: flex">
              <v-col cols="6">
                <v-btn class="copy_button" @click="retrive_plan_on_click()">
                  <span>Retrive plan</span>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn @click="show_create_plan = true" class="copy_button">
                  <span>Create new plan</span>
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="6" style="height: 100%" v-else>
        <v-card style="height: 550px; max-height: 550px; overflow-y: scroll">
          <v-card-title>
            <v-icon class="ml-0 mr-3" color="black">mdi-filter-settings-outline</v-icon>
            <span class="toolbar_title">Toolbar</span>
          </v-card-title>
          <v-row style="height: 85%; width: 100%" class="ma-0 pa-0">
            <v-col col="12" style="height: 85%;">
              <v-col cols="12" >
                <v-select
                  class="toolbar_selector"
                  disabled
                  :value="data_version"
                  label="Data Version"
                  hint="The data version of the blocking chart"
                  persistent-hint
                  :items="[data_version]"
                  @change="reset_table()"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <span>Blocking Chart Date Range</span>
                <v-tabs
                  v-model="prediction_range_tab"
                  align-tabs="start"
                  @change="on_prediction_tab_change($event)"
                >
                  <v-tab class="prediction_range_tab" key="predefined">Predefined</v-tab>
                  <v-tab class="prediction_range_tab" key="customized">Customized</v-tab>
                </v-tabs>
                <v-tabs-items v-model="prediction_range_tab">
                  <v-tab-item>
                    <v-row class="ma-0 pa-0" align="center" justify="space-around">
                      <v-col cols="12">
                        <v-radio-group
                          v-model="predefined_radio_val"
                          @change="on_predefined_input_change($event)"
                          row
                        >
                          <v-radio label="30 days" value="val_1"></v-radio>
                          <v-radio label="60 days" value="val_2"></v-radio>
                          <v-radio label="90 days" value="val_3"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <DateRange
                      :start_date="predict_start_date"
                      :end_date="predict_end_date"
                      :min_start_date="predict_min_start_date"
                      :max_end_date="max_end_date"
                      v-on:start_date_update="update_predict_start_date"
                      v-on:end_date_update="update_predict_end_date"
                    ></DateRange>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
              <v-col cols="12">
                <span>Data to Copy</span>
                <DateRangeStart 
                  :start_date="copy_start_date"
                  :end_date="copy_end_date"
                  :min_start_date="project_date_range['min_start_date']"
                  :max_end_date="project_date_range['max_end_date']"
                  v-on:start_date_update="update_copy_start_date"
                  v-on:end_date_update="update_copy_end_date"></DateRangeStart>
                <!-- <DateRange
                  :start_date="copy_start_date"
                  :end_date="copy_end_date"
                  :min_start_date="project_date_range['min_start_date']"
                  :max_end_date="project_date_range['max_end_date']"
                  v-on:start_date_update="update_copy_start_date"
                  v-on:end_date_update="update_copy_end_date"
                ></DateRange> -->
              </v-col>
            </v-col>
            <v-col cols="12">
              <v-row cols="12">
                <v-col cols="6">
                  <v-btn @click="show_create_plan = false" class="copy_button">
                    <span>View Saved Plans</span>
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn @click="copy_range_on_click" class="copy_button">
                    <span>Copy</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="12">
              <v-select
                class="toolbar_selector"
                v-model="selected_levels"
                :items="available_data_levels"
                label="Levels"
                multiple
                hint="Pick the level of granularity you wish to view the data."
                persistent-hint
                chips
                :sort-by="['Channel', 'Format']"
              >
              </v-select>
            </v-col>
            <v-col
              class="mr-0 ml-auto my-0 py-0 mx-3"
              :cols="12 - idx * 2"
              v-for="(val, idx) in selected_levels"
              :key="idx"
            >
              <v-icon class="mb-3" color="black">mdi-arrow-right-bottom-bold</v-icon>
              <span class="toolbar_selector">{{ val }}</span>
            </v-col> -->
          </v-row>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import DataLevelTable from "@/components/Utility/DataLevelTable.vue";
import DateRange from "@/components/Utility/DateRange.vue";
import DateRangeStart from "@/components/Utility/DateRangeStartDateOnly.vue";
// import TooltipDescription from "@/components/Utility/TooltipDescription.vue";
import performance_data_header from "@/input_data/performance_table_header.json";

// import api_caller from "@/javascript/data_retrieval.js";
import planning_api_caller from "@/javascript/planning.js";
import { ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE } from "@/constants/constants";

const MILISECOND_IN_A_DAY = 1000 * 3600 * 24;

const MONTH_DATE_MAPPING = {
  JAN: { start_date: "01/01" },
  FEB: { start_date: "02/01" },
  MAR: { start_date: "03/01" },
  APR: { start_date: "04/01" },
  MAY: { start_date: "05/01" },
  JUN: { start_date: "06/01" },
  JUL: { start_date: "07/01" },
  AUG: { start_date: "08/01" },
  SEP: { start_date: "09/01" },
  OCT: { start_date: "10/01" },
  NOV: { start_date: "11/01" },
  DEC: { start_date: "12/01" },
};

export default {
  name: "PlanningDataSetup",
  props: ["project_id", "campaign_plan_id", "data_version", "data_levels", "project_date_range", "reset",'lock_changes'],
  components: {
    DateRange,
    DateRangeStart,
    DataLevelTable,
    // TooltipDescription
  },
  data: () => ({
    // campaign_plan_id: null,
    performanceData: null,
    processedData: null,
    rowEditable: false,
    selected_levels: ["channel"],
    selected_sort_keys: [],
    tbl_avaialable: false,
    initial_data_copied: false,
    data_breakdown_timing: "monthly",
    available_data_breakdown_timing: ["weekly", "bi-weekly", "monthly"],
    data_breakdown_lock_options: ["Spend", "CPM", "Impressions"],
    data_breakdown_lock: "CPM",
    data_breakdown_type: "spend",

    available_campaign_plans: null,
    saved_campaign_plans: null,
    temporary_campaign_plans: null,
    show_create_plan: false,
    selected_temporary_plan: null,
    selected_saved_plan: null,

    prediction_range_tab: 0,
    campaign_plan_tab: 0,
    predefined_radio_val: "val_1",
    predict_length: 30,

    start_date: null,
    end_date: null,

    predict_start_date: null,
    predict_end_date: null,

    copy_start_date: null,
    copy_end_date: null,

    predict_min_start_date: null,
    max_end_date: null,
    toolbar_visible: false,
    numericColumns: [],
    currencyColumns: [],
  }),
  mounted() {
    this.reset_table(true);
    // this.selected_levels = this.data_levels["selected_levels"];
    // if(this.selected_levels.length == 0){
    //   this.selected_levels = this.data_level['data_levels'][0];
    // }
    // let predict_start_date = new Date();
    // predict_start_date.setDate(predict_start_date.getDate() + 1);
    // let formatted_start_date = predict_start_date.toISOString().slice(0, 10);

    // this.predict_start_date = formatted_start_date;
    // this.predict_end_date = formatted_start_date;

    // this.predict_min_start_date = formatted_start_date;
    // this.copy_start_date = this.project_date_range["start_date"];
    // this.update_copy_range(true);
  },
  watch: {
    project_id: {
      handler: function () {
        this.get_available_campaign_plans();
      },
    },
    campaign_plan_id: {
      handler: function () {
        this.reset_table(true);
      }
    },
    reset: {
      handler: function () {
        if (this.reset == true) {
          this.initial_data_copied = false;
          this.predict_length = 30;
          if(this.selected_levels.length == 0){
            this.selected_levels = this.data_level['data_levels'][0];
          }
          let predict_start_date = new Date();
          predict_start_date.setDate(predict_start_date.getDate() + 1);
          let formatted_start_date = predict_start_date.toISOString().slice(0, 10);

          this.predict_start_date = formatted_start_date;
          this.predict_end_date = formatted_start_date;

          this.predict_min_start_date = formatted_start_date;
          this.copy_start_date = this.project_date_range["start_date"];
          this.update_copy_range(true);

          this.data_breakdown_timing = "monthly";
          this.data_breakdown_lock = "CPM";
          this.data_breakdown_type = "spend";
        }
      },
      immediate: false,
    },
    project_date_range: {
      handler: function () {
        if (
          this.start_date != this.project_date_range["start_date"] ||
          this.end_date != this.project_date_range["end_date"]
        ) {
          this.start_date = this.project_date_range["start_date"];
          this.end_date = this.project_date_range["end_date"];
          // this.reset_table();
        }
      },
      deep: true,
    },
    data_levels: {
      handler: function () {
        if (
          this.data_levels["selected_levels"].toString() !=
          this.selected_levels.toString()
        ) {
          this.selected_levels = this.data_levels["selected_levels"];
        }
      },
      deep: true,
    },
    // selected_levels: {
    //   handler: function(){
    //     this.reset_table();
    //   },
    //   deep: true
    // }
  },
  computed: {
    columnList: function () {
      let tmp = [];
      for (let row of this.headers) {
        if (row["text"] !== 'Ad Unit' && row["text"] !== "Actions") {
          tmp.push(row["text"]);
        }
      }
      console.log("numerics", tmp);
      return tmp;
    },
    available_data_levels: function () {
      return this.data_levels["data_levels"];
    },
    data_fields: function () {
      let fields = [];
      for (let header_row of performance_data_header["table_header_statistics"]) {
        fields.push(header_row["text"]);
      }
      return fields;
    },
    sort_keys: function () {
      let sort_keys = [];
      for (let header_row of this.headers) {
        sort_keys.push(header_row["value"]);
      }
      return sort_keys;
    },
    saved_plan_options: function () {
      let saved_plans = [];
      for (let plan of this.saved_campaign_plans) {
        saved_plans.push(plan["plan_id"]);
      }
      return saved_plans;
    },
    temporary_plan_options: function () {
      let temporary_plans = [];
      for (let plan of this.temporary_campaign_plans) {
        temporary_plans.push(plan["plan_id"]);
      }
      return temporary_plans;
    },
    headers: function () {
      let data_tbl_header = [];
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var end = new Date(this.end_date);
      let daySkip = 7;
      console.log(this.start_date)
      let s = new Date(
        this.start_date.split("-")[0],
        parseInt(this.start_date.split("-")[1]) - 1,
        parseInt(this.start_date.split("-")[2])
      );
      let d = s;

      if (["weekly", "bi-weekly"].includes(this.data_breakdown_timing)) {
        if (this.data_breakdown_timing == "weekly") {
          daySkip = 7;
        } else if (this.data_breakdown_timing == "bi-weekly") {
          daySkip = 14;
        }
        for (d = s; d <= end; d.setDate(d.getDate() + daySkip)) {
          data_tbl_header.push({
            text: d.toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            value: d.toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            sortable: true,
            desc: "",
          });
        }
      } else if (["monthly"].includes(this.data_breakdown_timing)) {
        let m = 0;
        for (d = s; d <= end; d.setMonth(d.getMonth() + 1)) {
          m = months[d.getMonth()] + " " + d.getFullYear();
          data_tbl_header.push({ text: m, value: m, sortable: true, desc: "" });
        }
      } else if (["quarterly"].includes(this.data_breakdown_timing)) {
        let q = 0;
        for (d = s; d <= end; d.setMonth(d.getMonth() + 3)) {
          q = Math.floor((d.getMonth() + 3) / 3);
          data_tbl_header.push({
            text: "Q" + q + " " + d.getFullYear(),
            value: "Q" + q + " " + d.getFullYear(),
            sortable: false,
            desc: "",
          });
        }
      } else {
        for (d = s; d <= end; d.setFullYear(d.getFullYear() + 1)) {
          data_tbl_header.push({
            text: d.getFullYear(),
            value: d.getFullYear().toString(),
            sortable: true,
            desc: "",
          });
        }
      }

      this.selected_levels
        .slice()
        .reverse()
        .forEach((lvl) =>
          data_tbl_header.unshift(performance_data_header["table_header_levels"][lvl])
        );
      data_tbl_header.push({ text: "Actions", value: "actions", sortable: false });
      return data_tbl_header;
    },
  },
  methods: {
    empty_row_click: function () {},
    update_color: function (item) {
      // let grey_notches = ["#FFFFFF","#F0F0F0","#cccccc","#808080","#696969"];
      let grey_notches = [
        "#FFFFFF",
        "#F0F0F0",
        "#DCDCDC",
        "#D0D0D0",
        "#BEBEBE",
        "#A9A9A9",
      ];
      let null_count = 0;
      for (let level of this.selected_levels) {
        if (item[level] == null) {
          null_count++;
        }
      }
      return grey_notches[null_count];
    },
    level_val: function (item, col) {
      let idx = this.selected_levels.indexOf(col);
      let hide = false;
      for (let i = this.selected_levels.length - 1; i > idx; i--) {
        if (item[this.selected_levels[i]] != null) {
          hide = true;
        }
      }
      if (hide) {
        return "";
      } else {
        return item[col];
      }
    },
    reset_table: async function (isRetrivePlan) {
      this.tbl_avaialable = false;
      this.initial_data_copied = true;

      let date_range = {
        start_date: this.start_date,
        end_date: this.end_date,
      };

      let payload = {
        project_id: this.project_id,
        plan_id: this.campaign_plan_id,
        time_interval: this.data_breakdown_timing,
        date_range: date_range,
        selected_level: this.selected_levels,
      };

      console.log(payload)

      let token = await this.$auth.getTokenSilently();
      let result = await planning_api_caller
        .get_campaign_plan_data(token, payload)
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          }
          return false;
        })
        .catch((e) => {
          console.log(e);
          return "False";
        });
      this.performanceData = result["plan_data"];
      let level_values = [];
      let level_name = "";
      let valid = false;
      for (let row of this.performanceData) {
        level_name = "";
        valid = true;
        for (let l of this.selected_levels) {
          if (row[l]) {
            level_name += row[l];
          } else {
            valid = false;
          }
          if (row[l] != null) {
            level_name += "__";
          }
        }
        if (valid) {
          level_values.push(level_name.slice(0, -2));
        }
      }
      if(isRetrivePlan){
        this.start_date = result["campaign_detail"]['start_date'];
        this.end_date = result["campaign_detail"]['end_date'];
      }
      this.switch_data_breakdown(this.data_breakdown_type);
      this.$emit("level_update", {
        data_levels: this.available_data_levels,
        selected_levels: this.selected_levels,
        level_values: level_values,
      });
        // this.start_date = result["campaign_detail"]['start_date'];
        // this.end_date = result["campaign_detail"]['start_date'];
      setTimeout(() => (this.tbl_avaialable = true), 100);
    },
    update_custom_predict_length() {
      let start_date_obj = new Date(this.predict_start_date);
      let end_date_obj = new Date(this.predict_end_date);
      if (start_date_obj > end_date_obj) {
        this.predict_end_date = this.predict_start_date;
      }
      this.predict_length = Math.round(
        (end_date_obj - start_date_obj) / MILISECOND_IN_A_DAY
      );
    },

    // update the date range to be used to copy the breakdown data
    // forward_update: whether to update end start base on start date or update start date based on end date
    update_copy_range(forward_update) {
      let tmpDate = null;
      if (forward_update) {
        tmpDate = new Date(this.copy_start_date);
        tmpDate.setDate(tmpDate.getDate() + this.predict_length);
        let maxEndDate = new Date(this.project_date_range["max_end_date"]);
        if (tmpDate > maxEndDate) {
          tmpDate = maxEndDate;
        }
        this.copy_end_date = tmpDate.toISOString().slice(0, 10);
      } else {
        tmpDate = new Date(this.copy_end_date);
        tmpDate.setDate(tmpDate.getDate() - this.predict_length);
        let minStartDate = new Date(this.project_date_range["min_start_date"]);
        if (tmpDate < minStartDate) {
          tmpDate = minStartDate;
        }
        this.copy_start_date = tmpDate.toISOString().slice(0, 10);
      }
    },
    update_copy_start_date(date) {
      if (date != this.copy_start_date) {
        this.copy_start_date = date;
        this.update_copy_range(true);
      }
    },
    update_copy_end_date(date) {
      if (date != this.copy_end_date) {
        this.copy_end_date = date;
        this.update_copy_range(false);
      }
    },
    update_predict_start_date(date) {
      this.predict_start_date = date;
      this.update_custom_predict_length();
      this.update_copy_range(true);
    },
    update_predict_end_date(date) {
      this.predict_end_date = date;
      this.update_custom_predict_length();
      this.update_copy_range(true);
    },
    on_prediction_tab_change(tab_idx) {
      if (tab_idx == 0) {
        this.predict_length = 30;
      } else {
        this.update_predict_length();
      }
    },
    on_predefined_input_change(val) {
      switch (val) {
        case "val_1":
          // 30 day range
          this.predict_length = 30;
          break;
        case "val_2":
          // 2 month range
          this.predict_length = 60;
          break;
        case "val_3":
          // 3 month range
          this.predict_length = 90;
          break;
      }
      this.update_copy_range(true);
    },
    copy_range_on_click: async function () {
      this.start_date = this.copy_start_date;
      this.end_date = this.copy_end_date;

      let campaign_plan_payload = {
        project_id: this.project_id,
        data_version: this.data_version,
        start_date: this.start_date,
        end_date: this.end_date,
        ad_unit: this.selected_levels
      };

      let token = await this.$auth.getTokenSilently();
      let result = await planning_api_caller
        .create_campaign_plan(token, campaign_plan_payload)
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          }
          return false;
        })
        .catch((e) => {
          console.log("error", e);
          return false;
        });
      if (result && result["campaign_plan_id"]) {
        // this.campaign_plan_id = result["campaign_plan_id"];
        this.$emit("update_campaign_plan_id", result["campaign_plan_id"]);

        await this.reset_table();
      } else {
        this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
      }

      this.$emit("date_update", { start_date: this.start_date, end_date: this.end_date });
    },

    retrive_plan_on_click: async function () {
 
      // if (this.campaign_plan_tab == 0) {
      //   this.campaign_plan_id = this.selected_saved_plan;
      // } else {
      //   this.campaign_plan_id = this.selected_temporary_plan;
      // }

      let campaign_details = await this.reset_table(true);
      this.start_date = campaign_details["start_date"];
      this.end_date = campaign_details["end_date"];
      this.$emit("date_update", { start_date: this.start_date, end_date: this.end_date });
      this.$emit("update_campaign_plan_id", campaign_details["plan_id"]);
      this.$emit("campaign_plan_retrived");
    },
    switch_data_breakdown(type) {
      this.data_breakdown_type = type;

      if (type == "spend" || type == "cpm") {
        this.numericColumns = [];
        this.currencyColumns = this.columnList;
      } else {
        this.numericColumns = this.columnList;
        this.currencyColumns = [];
      }
      this.process_breakdown_data();
    },
    process_breakdown_data() {
      let tempData = JSON.parse(JSON.stringify(this.performanceData));
      for (let row of tempData) {
        for (const obj in row) {
          if (!this.selected_levels.includes(obj)) {
            row[obj] = row[obj][this.data_breakdown_type];
          }
        }
      }

      if (
        this.data_breakdown_type.toLowerCase() != this.data_breakdown_lock.toLowerCase()
      ) {
        this.rowEditable = true;
      } else {
        this.rowEditable = false;
      }
      this.processedData = tempData;
    },

    delete_campaign_plan_data: async function (item) {
      let payload = {
        campaign_plan_id: this.campaign_plan_id,
        project_id: this.project_id,
        conditions: {
          channel: item["channel"],
        },
      };

      // remove the plan data from database
      let token = await this.$auth.getTokenSilently();
      let result = await planning_api_caller
        .delete_campaign_plan_data(token, payload)
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          }
          return false;
        })
        .catch((e) => {
          console.log("error", e);
          return false;
        });

      if (result && result["rowsDeleted"]) {
        // remove the row from the UI table
        let tempData = JSON.parse(JSON.stringify(this.performanceData));
        let newData = tempData.filter(function (entry) {
          return entry["channel"] !== item["channel"];
        });
        this.performanceData = newData;
        this.process_breakdown_data();
      } else {
        this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
      }
    },

    get_available_campaign_plans: async function () {
      let payload = {
        project_id: this.project_id,
      };

      let token = await this.$auth.getTokenSilently();
      let result = await planning_api_caller
        .get_campaign_plan(token, payload)
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          }
          return false;
        })
        .catch((e) => {
          console.log("error", e);
          return false;
        });
      if (result && result["available_campaigns"]) {
        this.available_campaign_plans = result["available_campaigns"];

        this.saved_campaign_plans = this.available_campaign_plans.filter((plan) => {
          return plan.status === "SAVED";
        });

        this.temporary_campaign_plans = this.available_campaign_plans.filter((plan) => {
          return plan.status === "TEMPORARY";
        });
      } else {
        this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
      }
    },

    edit_campaign_plan_data: async function (change_payload) {
      let update_entries = [];
      this.tbl_avaialable = false;
      if (change_payload && JSON.stringify(change_payload["update_entries"]) !== "{}") {
        if (this.data_breakdown_timing == "monthly") {
          for (const entry in change_payload["update_entries"]) {
            let date_split = entry.split(" ");
            let month_dates = MONTH_DATE_MAPPING[date_split[0].toUpperCase()];
            let edit_entry = {
              delta_value: change_payload["update_entries"][entry],
              start_date: month_dates["start_date"] + "/" + date_split[1],
            };
            update_entries.push(edit_entry);
          }
        } else {
          for (const entry in change_payload["update_entries"]) {
            let start_date = entry;

            let edit_entry = {
              delta_value: change_payload["update_entries"][entry],
              start_date: start_date,
            };
            update_entries.push(edit_entry);
          }
        }

        let payload = {
          channel: change_payload["channel"],
          update_entries: update_entries,
          campaign_plan_id: this.campaign_plan_id,
          breakdown_type: this.data_breakdown_type,
          breakdown_lock: this.data_breakdown_lock,
          breakdown_timing: this.data_breakdown_timing,
          project_id: this.project_id,
        };

        console.log(payload)

        let token = await this.$auth.getTokenSilently();
        let result = await planning_api_caller
          .edit_campaign_plan_data(token, payload)
          .then((resp) => {
            if (resp["success"]) {
              return resp["data"];
            }
            return false;
          })
          .catch((e) => {
            console.log("error", e);
            return false;
          });

        console.log("edit campaign plan result", result);
        if (result && result["rowsUpdated"]) {
          // update the data in the UI table
          let tempData = JSON.parse(JSON.stringify(this.performanceData));
          tempData.forEach((entry) => {
            if (entry["channel"] == change_payload["channel"]) {
              for (const key in change_payload["update_entries"]) {
                if (key !== "channel") {
                  entry[key][this.data_breakdown_type] +=
                    change_payload["update_entries"][key];

                  // update the spend/impression based on the cpm formula
                  if (this.data_breakdown_lock.toLowerCase() == "cpm") {
                    if (this.data_breakdown_type == "spend") {
                      entry[key]["impressions"] =
                        (entry[key]["spend"] / entry[key]["cpm"]) * 1000;
                    } else {
                      entry[key]["spend"] =
                        (entry[key]["cpm"] * entry[key]["impressions"]) / 1000;
                    }
                  } else if (this.data_breakdown_lock.toLowerCase() == "spend") {
                    if (this.data_breakdown_type == "impressions") {
                      entry[key]["cpm"] =
                        (entry[key]["spend"] / entry[key]["impressions"]) * 1000;
                    } else {
                      entry[key]["impressions"] =
                        (entry[key]["spend"] / entry[key]["cpm"]) * 1000;
                    }
                  } else if (this.data_breakdown_lock.toLowerCase() == "impressions") {
                    if (this.data_breakdown_type == "spend") {
                      entry[key]["cpm"] =
                        (entry[key]["spend"] / entry[key]["impressions"]) * 1000;
                    } else {
                      entry[key]["spend"] =
                        (entry[key]["cpm"] * entry[key]["impressions"]) / 1000;
                    }
                  }
                }
              }
            }
          });
          this.performanceData = tempData;
          this.process_breakdown_data();
        } else {
          this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
        }

      }
      this.tbl_avaialable = true;
    },
  },
};
</script>

<style>
.table-title {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 1.5em;
}

.text-start {
  font-weight: 600;
  font-family: "Poppins";
}

.data_breakdown_chip {
  font-family: Poppins;
  color: white !important;
  font-weight: 600;
  width: 125px;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
}

.toolbar_title {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 1.1em;
}
.toolbar_selector {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 0.9 em;
}
.v-list-item .v-list-item__title {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 0.85em;
}

.prediction_range_tab {
  font-size: x-small;
}

.copy_button {
  width: 100%;
}
</style>
