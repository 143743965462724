<template>
    <v-card class="ma-0 pa-0" flat>
        <v-card-title class="highlightStatTitle">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <span v-on="on" class="titleContent">{{HighlightTitle}}</span>
                </template>
                <TooltipDescription :title="HighlightTitle" :desc_2="desc"></TooltipDescription>
            </v-tooltip>
        </v-card-title>
        <v-card-text class="highlightStatText" :style="text_style">
            <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0">
                    <span>{{value}}</span>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import TooltipDescription from '@/components/Utility/TooltipDescription.vue';
    export default{
        name: "AudienceHighlightCard",
        props: [
            "HighlightTitle",
            "HighlightDescription",
            "HighlightInfo",
            "HighlightValue"
        ],
        components: {
            TooltipDescription
        },
        mounted(){
            // Check HighlightValue Prop
            if(this.HighlightValue == null){
                this.value = '-';
            }
            else{
                this.value = this.HighlightValue;
            }
            // Check HighlightInfo Prop
            if(this.HighlightInfo == null || this.HighlightValue == null){
                this.color = 'black'
            }
            else{
                //Value Range for green or red
                if((typeof(this.HighlightInfo) == 'object') & (Object.keys(this.HighlightInfo).includes('min')) & (Object.keys(this.HighlightInfo).includes('max')) ){
                    if(this.HighlightValue < parseFloat(this.HighlightInfo['min'])){
                        this.color = 'red';
                    }
                    else if(this.HighlightValue > parseFloat(this.HighlightInfo['max'])){
                        this.color = 'green';
                    }
                    else{
                        this.color = 'black';
                    }
                }
                //Value Type for string formatting
                if((typeof(this.HighlightInfo) == 'object') & (Object.keys(this.HighlightInfo).includes('type'))){
                    if (this.HighlightInfo['type'] == 'percentage'){
                        this.value = parseFloat(this.value).toLocaleString('en-US', {maximumFractionDigits:2, minimumFractionDigits:0}) + '%';
                    }
                    else if(this.HighlightInfo['type'] == 'number'){
                        this.value = parseFloat(this.value).toLocaleString('en-US', {maximumFractionDigits:0});
                    }
                }
            }

            //Update highlight description
            this.desc = this.HighlightDescription;
        },
        watch: {
            HighlightValue: {
                handler: function(updated_value) {
                    if(updated_value == null){
                        this.value = '-';
                    }
                    else{
                        this.value = updated_value;
                    }
                    // Check HighlightInfo Prop
                    if(this.HighlightInfo == null || this.HighlightValue == null){
                        this.color = 'black'
                    }
                    else{
                        //Value Range for green or red
                        if((typeof(this.HighlightInfo) == 'object') & (Object.keys(this.HighlightInfo).includes('min')) & (Object.keys(this.HighlightInfo).includes('max')) ){
                            if(this.HighlightValue < parseFloat(this.HighlightInfo['min'])){
                                this.color = 'red';
                            }
                            else if(this.HighlightValue > parseFloat(this.HighlightInfo['max'])){
                                this.color = 'green';
                            }
                            else{
                                this.color = 'black';
                            }
                        }
                        //Value Type for string formatting
                        if((typeof(this.HighlightInfo) == 'object') & (Object.keys(this.HighlightInfo).includes('type'))){
                            if (this.HighlightInfo['type'] == 'percentage'){
                                this.value = parseFloat(this.value).toLocaleString('en-US', {maximumFractionDigits:2, minimumFractionDigits:0}) + '%';
                            }
                            else if(this.HighlightInfo['type'] == 'number'){
                                this.value = parseFloat(this.value).toLocaleString('en-US', {maximumFractionDigits:0});
                            }
                        }
                    }
                }
            }
        },
        data: () => ({
            color: 'black',
            value: '-',
            desc: null
        }),
        computed: {
            text_style(){
                return {
                    "color": this.color
                }
            }
        }
    }
</script>

<style scoped>
    .highlightStatTitle{
        font-family: Poppins !important;
        font-weight:200 !important;
        font-size: 0.6em !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        
        .titleContent {
            cursor: pointer;
        }
    }
    .highlightStatText{
        font-family: Amiko !important;
        font-weight: 700 !important;
        font-size: 1.5em !important;
        align-self: center;
    }
</style>