import axios from 'axios'

// const api_endpoint = 'https://enterprise.api.docmasweetspot.ca'
// const api_endpoint = 'https://staging.enterprise.api.docmasweetspot.ca'
const api_endpoint = 'http://127.0.0.1:5001'


function build_audience(token, project_id){
    let api_url = api_endpoint+'/audience/build_audience';
    let data = {"project_id": project_id};
    return axios_post_call(api_url,data,token);
}

function axios_post_call(api_url,data,token){
    return new Promise(function(resolve,reject){
        axios.post(api_url, data, {headers:{Authorization: `Bearer ${token}`}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        }).catch((e)=>{
            reject(api_url+e);
        })
    })
}

export default {build_audience};